import RenderConditional from 'main/components/RenderConditional';
import Table, { Skeleton } from 'main/components/Table';
import TableFilter from 'main/components/Table/TableFilter';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { FILTRO_DPS } from 'dps/features/pendentesDps/constants/constants';
import { ITabelaPainelDPS } from 'dps/features/pendentesDps/types/ITabelaPainelDPS';
import { GridWrapper } from '../styles/style';

export const TabelaPainelDPS = ({
  dados,
  colunas,
  loading,
  agencia,
}: ITabelaPainelDPS) => {
  return (
    <>
      <RenderConditional condition={loading}>
        <Skeleton colunas={colunas} data-testid="tableSkeleton" />
      </RenderConditional>
      <RenderConditional condition={!loading}>
        <RenderConditional condition={agencia}>
          <TableFilter
            dataToFilter={dados}
            filterOptions={FILTRO_DPS}
            filterTextPartial
          >
            {dataFiltered => (
              <GridWrapper>
                <Table
                  data={tryGetValueOrDefault([dataFiltered], [])}
                  columns={colunas}
                  noHeader
                  responsive
                  striped
                  highlightOnHover
                  noDataComponent="Não há dados para exibir."
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: 'Items por página',
                    rangeSeparatorText: 'de',
                  }}
                />
              </GridWrapper>
            )}
          </TableFilter>
        </RenderConditional>
        <RenderConditional condition={!agencia}>
          <Table
            data={tryGetValueOrDefault([dados], [])}
            columns={colunas}
            noHeader
            responsive
            striped
            highlightOnHover
            noDataComponent="Não há dados para exibir."
            pagination
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: 'Items por página',
              rangeSeparatorText: 'de',
            }}
          />
        </RenderConditional>
      </RenderConditional>
    </>
  );
};
