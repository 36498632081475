// @ts-nocheck
export const AVISO_PARCELA =
  'A inclusão do benefício de proteção irá aumentar o valor da parcela no plano, se acordo com a indenização escolhida.';

export const TEXTO_COBERTURA =
  'Com base no valor da renda bruta mensal indicada, apresentamos a cobertura mais adequada ao perfil do cliente.';

export const COBERTURA_REATIVAR =
  'A cobertura selecionada já esteve ativa nesse certificado.';

export const COBERTURA_VALOR_ULTIMA_PARCELA =
  'Sendo assim, o valor sugerido é o da última parcela gerada com o benefício de proteção e a atualização prevista em regulamento.';

export const COBERTURA_ALTERAR_VALOR =
  'Para alterar o valor da cobertura, será necessário aguardar o prazo de 30 dias a contar da data de reativação.';

export const INFORME_COBERTURA =
  'Informe ao cliente o valor da cobertura selecionada e o novo valor da parcela.';

export const INFORME_ASSINATURA =
  'O fluxo de contratação será finalizado por assinatura eletrônica. Por isso, é importante que o cliente esteja com o número de celular atualizado.';

export const TEXTO_PECULIO =
  'Em caso de falecimento do titular, os beneficiários receberão a indenização contratada de uma única vez';

export const TEXTO_PENSAO =
  'Caso o titular venha a falecer, os seus benefíciarios receberão uma renda mensal pelo prazo de 1 a 10 anos';

export const TEXTO_BENEFICIO = 'Ter uma renda mensal de: ';
export const TEXTO_CONTRIBUICAO = 'Contribuir com: ';

export const MENSAGEM_NAO_SE_APLICA =
  'Esta operação não se aplica a este certificado. Em caso de dúvida, fale agora com um dos nossos Especialistas Online pelo';

export const TEXTOS_CARENCIA = {
  INICIO:
    'Cliente ciente de que a cobertura solicitada ocasionará aumento da parcela e possui carência de ',
  FIM: ' para eventos naturais. O valor da nova cobertura será reajustado anualmente, de acordo com o indexador do plano, conforme regulamento do produto. Além disso, o valor pago para o Beneficio de Proteção, não compõe a reserva acumulada.',
};

export const datasInvalidas = [
  '2024-12-31T00:00:00-03:00',
  '2025-01-01T00:00:00-03:00',
];
