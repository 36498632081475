import { Card, Display, Modal } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { DEFAULT_NO_DATA_TABLE } from 'main/constants/messages';
import { TableInadimplencia } from 'painelInadimplencia/pages/TableInadimplencia';
import { IModalDetalhamentoParcelasProps } from 'painelInadimplencia/types/PainelInadimplencia';

const ModalDetalhamentoParcelas = ({
  open,
  onClose,
  listaPagamentosDetalhado,
  colunasDetalhesPagamentos,
  isLoading,
}: IModalDetalhamentoParcelasProps) => {
  return (
    <Modal show={open} onClose={onClose} style={{ maxWidth: 'none' }}>
      <RenderConditional condition={!!isLoading}>
        <SkeletonLoading blocks={1} lines={10} />
      </RenderConditional>

      <RenderConditional condition={!isLoading}>
        <Display type="display-block">
          <Card>
            <Card.Content>
              <Display>
                <TableInadimplencia
                  noHeader
                  responsive
                  striped
                  highlightOnHover
                  data={tryGetValueOrDefault([listaPagamentosDetalhado], [])}
                  columns={colunasDetalhesPagamentos}
                  noDataComponent={DEFAULT_NO_DATA_TABLE}
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: 'Items por página',
                    rangeSeparatorText: 'de',
                  }}
                />
              </Display>
            </Card.Content>
          </Card>
        </Display>
      </RenderConditional>
    </Modal>
  );
};

export default ModalDetalhamentoParcelas;
