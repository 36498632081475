import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { basePath } from 'config/httpRequest';
import { reactQueryCacheDuration } from 'config/storage_keys';
import { api } from 'main/services';
import { useHandleReponse } from 'main/hooks/useHandleResponse';
import { useToast } from 'main/hooks/useToast';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { IPecoFetchDataParams, IPecoParams } from 'main/types/IPecoParams';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';

/**
 * * * @link https://azuredevops.caixavidaeprevidencia.intranet/CVP/CVP.Frontends/_wiki/wikis/CVP.FrontEnds.wiki/253/React-Hook-usePeco
 * * @description 2023-01-30 - Hook responsável por consumir APIs de forma flexível, suportando cache, invalidação de cache e carregamento automático.
 * * @description Antes de alterar, comunicar o autor ou os arquitetos
 *
 * @template T - Tipo do payload enviado na requisição.
 * @template U - Tipo esperado da resposta.
 *
 * @param {IPecoParams<T>} params - Parâmetros de configuração para o hook.
 * @param {Object} params.api - Configurações da API.
 * @param {string} params.api.operationPath - Caminho da operação da API.
 * @param {string} [params.api.pecoBasePath] - Base URL para a API. Se não fornecida, usa a configuração padrão.
 * @param {T} [params.payload] - Payload padrão enviado na requisição.
 * @param {boolean} [params.cache] - Indica se o cache deve ser habilitado.
 * @param {string} [params.cacheKey] - Chave única para o cache.
 * @param {number} [params.cacheTime] - Tempo de validade do cache em milissegundos.
 * @param {Object} [params.requestConfig] - Configurações adicionais para a requisição, como headers.
 * @param {boolean} [params.autoFetch] - Indica se a requisição deve ser disparada automaticamente ao inicializar o hook.
 * @param {Function} [params.handleResponse] - Função para manipular a resposta da API.
 *
 * @returns {Object} - Objeto contendo os estados e funções relacionadas à requisição:
 * @returns {boolean} loading - Indica se a requisição está em andamento.
 * @returns {IHandleReponseResult<U> | undefined} response - Resposta da requisição em formato tratado.
 * @returns {unknown} responseBinary - Resposta em formato bruto (binário), usada para arquivos.
 * @returns {Function} fetchData - Função para realizar requisições com suporte a payload dinâmico e cache.
 * @returns {Function} fetchDataBinary - Função para realizar requisições e obter resposta binária.
 * @returns {unknown} error - Objeto de erro, se houver falhas na requisição.
 * @returns {Function} setResponse - Função para atualizar manualmente a resposta.
 * @returns {Function} invalidateCache - Função para invalidar o cache de uma consulta específica.
 *
 * @example
 * const { fetchData, loading, response, error } = usePeco({
 *   api: { operationPath: '/endpoint' },
 *   payload: { id: 123 },
 *   cache: true,
 *   cacheKey: 'unique-key',
 * });
 *
 * useEffect(() => {
 *   fetchData({ dynamicParam: 'value' });
 * }, []);
 */
export function usePeco<T, U>(params: IPecoParams<T>) {
  const queryClient = useQueryClient();
  const { toastError } = useToast();
  const { handleResponse } = useHandleReponse({ ...params?.handleResponse });
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<IHandleReponseResult<U>>();
  const [responseBinary, setResponseBinary] = useState<unknown>();

  const [error, setError] = useState<unknown>(null);
  const pecoBasePath = params.api.pecoBasePath || basePath;
  const payload = params.payload || {};
  const withCache = params.cache || false;
  const autoFetch = params.autoFetch || false;
  const cacheTime = params.cacheTime || reactQueryCacheDuration();
  const requestConfig = params.requestConfig || {};

  const isFileResponseType = () => {
    const nonFileResponseTypes = ['json'];
    return (
      requestConfig?.responseType &&
      !nonFileResponseTypes.includes(requestConfig.responseType.toLowerCase())
    );
  };

  const executeRequest = async (
    dynamicPayload = {},
  ): Promise<IHandleReponseResult<U> | undefined> => {
    const finalUrl = `${pecoBasePath}${params.api.operationPath}`;
    const isDynamicPayloadFormData = dynamicPayload instanceof FormData;
    const isPayloadFormData = payload instanceof FormData;
    if (
      checkIfSomeItemsAreTrue([isDynamicPayloadFormData, isPayloadFormData])
    ) {
      const payloadData = isDynamicPayloadFormData ? dynamicPayload : payload;
      const { data } = await api.post(finalUrl, payloadData, {
        ...requestConfig,
      });
      if (isFileResponseType()) {
        return data;
      }

      return handleResponse(data);
    }

    const responseApi = async () => {
      const { data } = await api.post(finalUrl, {
        ...payload,
        ...dynamicPayload,
      });
      return data;
    };

    if (isFileResponseType()) {
      return responseApi();
    }

    return handleResponse(await responseApi());
  };

  const getReactQueryKey = (fetchDataParamsCacheKey?: string) => {
    if (params?.cacheKey) return [params.api.operationPath, params.cacheKey];

    if (fetchDataParamsCacheKey)
      return [params.api.operationPath, fetchDataParamsCacheKey];

    return [params.api.operationPath];
  };

  const fetchByReactQueryClient = async (
    dynamicPayload = {},
    fetchDataParams = {} as IPecoFetchDataParams,
  ) => {
    return queryClient.fetchQuery(getReactQueryKey(fetchDataParams.cacheKey), {
      queryFn: ({ signal, queryKey }) =>
        executeRequest({ ...dynamicPayload, signal, queryKey }),
      staleTime: cacheTime || fetchDataParams.cacheTime,
    });
  };

  const invalidateCache = async (queryKey?: string) =>
    queryClient.invalidateQueries(queryKey ?? getReactQueryKey());

  const fetchData = async (
    dynamicPayload = {},
    fetchDataParams = {} as IPecoFetchDataParams,
  ): Promise<IHandleReponseResult<U> | undefined> => {
    try {
      setLoading(true);
      let result;

      if (withCache || fetchDataParams.cache) {
        result = await fetchByReactQueryClient(dynamicPayload, fetchDataParams);
      } else {
        result = await executeRequest(dynamicPayload);
      }
      setResponse(result);
      return result;
    } catch (requestError: unknown) {
      const erro = requestError as Error;
      toastError(erro?.message);
      setError(requestError);
    } finally {
      setLoading(false);
    }
    return undefined;
  };

  const fetchDataBinary = async (
    dynamicPayload = {},
  ): Promise<unknown | undefined> => {
    try {
      setLoading(true);
      const result = await executeRequest(dynamicPayload);
      setResponseBinary(result as unknown);
      return result;
    } catch (requestError: unknown) {
      const erro = requestError as Error;
      toastError(erro?.message);
      setError(requestError);
    } finally {
      setLoading(false);
    }
    return undefined;
  };

  useEffect(() => {
    if (autoFetch && !isFileResponseType()) {
      fetchData();
    }
    if (autoFetch && isFileResponseType()) {
      fetchDataBinary();
    }
  }, []);
  return {
    loading,
    response,
    responseBinary,
    fetchData,
    fetchDataBinary,
    error,
    setResponse,
    invalidateCache,
  };
}
