// @ts-nocheck
import React from 'react';
import { Text, Grid, Divider } from '@cvp/design-system/react';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';

import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';
import { formatarData } from 'main/utils';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import DadosSolicitacao from '../DadosSolicitacao';
import masks from 'main/utils/masks';

const ComprovanteNovaConta: React.FC<
  ALTERACAO_CONTA_TYPES.IComprovanteNovaContaProps
> = ({ dadoscontaAntiga, dadosNovaConta, cpfcnpj, voltar }) => {
  const dadosContaAdicionada = (
    <>
      <Text variant="body01-md" color="text" margin>
        Banco: {dadosNovaConta?.numeroBanco} - Agência:{' '}
        {masks.agency.mask(dadosNovaConta?.numeroAgencia)} - Conta:{' '}
        {dadosNovaConta?.numeroConta}-{dadosNovaConta?.digitoConta}
      </Text>
    </>
  );

  const dadosContaAntiga = (
    <>
      <Text variant="body01-md" color="text" margin>
        Banco: {dadoscontaAntiga?.numeroBanco} - Agência:{' '}
        {masks.agency.mask(dadoscontaAntiga?.numeroAgencia)} - Conta:{' '}
        {dadoscontaAntiga?.numeroConta}-{dadoscontaAntiga?.digitoConta}
      </Text>
    </>
  );

  const sexoUsuario =
    dadoscontaAntiga?.sexoPessoa === 'M' ? 'Masculino' : 'Feminino';

  return (
    <>
      <Grid.Item xs={1}>
        <Text variant="body01-md" color="text">
          Sua alteração foi realizada com sucesso.
        </Text>
        <Text variant="body01-md" color="text" margin>
          {CONSTS.COBRANCA_GERADA}
        </Text>
      </Grid.Item>
      <Grid.Item xs={1}>
        <Text variant="headline-08" color="primary" margin>
          Dados do participante
        </Text>

        <Text variant="body01-md" color="text">
          Cliente: {dadoscontaAntiga?.nomePessoa}
        </Text>

        <Text variant="body01-md" color="text">
          Data de nascimento:{' '}
          {formatarData(dadoscontaAntiga?.dataNascimentoPessoa)}
        </Text>

        <Text variant="body01-md" color="text">
          CPF: {formatarCpf(cpfcnpj)}
        </Text>

        <Text variant="body01-md" color="text">
          Sexo: {sexoUsuario}
        </Text>
      </Grid.Item>
      <Divider />
      <Grid.Item xs={1}>
        <Text variant="headline-08" color="primary" margin>
          Dados da alteração da forma de pagamento
        </Text>

        <Text variant="body01-md" color="text">
          Dados Conta Antiga:
        </Text>

        <Text variant="body01-md" color="text">
          Forma de pagamento anterior: Débito em conta
        </Text>

        <Text variant="body01-md" color="text" margin>
          {dadosContaAntiga}
        </Text>

        <Text variant="body01-md" color="text">
          Dados Conta Adicionada:
        </Text>

        <Text variant="body01-md" color="text">
          Forma de pagamento atual: Débito em conta
        </Text>

        <Text variant="body01-md" color="text" margin>
          {dadosContaAdicionada}
        </Text>
        <DadosSolicitacao voltar={voltar} />
      </Grid.Item>
    </>
  );
};
export default ComprovanteNovaConta;
