// @ts-nocheck
import React from 'react';
import ReactDataTable, { IDataTableProps } from 'react-data-table-component';

import * as S from 'main/components/Table/styles';
import { DadosCliente } from 'painelInadimplencia/types/ConsultarInadimplenciaResponse';

export const TableInadimplencia: React.FC<IDataTableProps> = props => {
  return (
    <S.ReactDataTable>
      <ReactDataTable<DadosCliente> {...props} />
    </S.ReactDataTable>
  );
};
