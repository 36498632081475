// @ts-nocheck
import { Button, Display } from '@cvp/design-system/react';

import { InputFileFormik } from 'main/components/form/InputFile/InputFileFormik';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { getBlobUrl } from 'main/utils/blob';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import useAssinaturaManual from 'previdencia/features/TransferenciaEntreCertificados/hooks/useAssinaturaManual';
import TransferenciaWrapper from 'previdencia/features/TransferenciaEntreCertificados/components/TransferenciaWrapper/TransferenciaWrapper';
import CardCertificados from 'previdencia/features/TransferenciaEntreCertificados/components/CardCertificados/CardCertificados';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const AssinaturaManual473 = () => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const {
    isDisabled,
    loadingUpload,
    handleDefinirArquivoUpload,
    enviarArquivo,
  } = useAssinaturaManual();
  return (
    <TransferenciaWrapper>
      <CardCertificados />

      <Display
        justify="center"
        alignItems="center"
        style={{ margin: '50px 0 0 0' }}
      >
        <div style={{ margin: '0 50px 0 0' }}>
          <Button
            variant="outlined"
            onClick={() =>
              getBlobUrl(featureData?.arquivoAssinaturaManual?.arquivoBase64)
            }
          >
            Download
          </Button>
        </div>
        <Display>
          <InputFileFormik
            onChange={event => {
              if (event?.currentTarget?.files) {
                handleDefinirArquivoUpload(event?.currentTarget?.files[0]);
              }
            }}
          />
          <div>
            <Button
              variant="primary"
              onClick={enviarArquivo}
              disabled={checkIfSomeItemsAreTrue([loadingUpload, isDisabled])}
              loading={loadingUpload}
            >
              Enviar FOP
            </Button>
          </div>
        </Display>
      </Display>
    </TransferenciaWrapper>
  );
};

export default AssinaturaManual473;
