import Icon from 'main/components/Icon';
import { IBuscarClientesDPS } from 'dps/features/pendentesDps/types/IBuscarClientesDPSResponse';
import { Button, Tooltip } from '@cvp/design-system/react';
import { IDataTableColumn } from 'react-data-table-component';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { IColunasDPSFilhosFactory } from 'dps/features/pendentesDps/types/IColunasDPSFilhosFactory';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const colunasDPSItemsFactory = ({
  visaoCliente,
  handleChangeRotaUnidade,
}: IColunasDPSFilhosFactory): IDataTableColumn<IBuscarClientesDPS>[] => {
  return [
    {
      selector: 'codigo',
      name: `Unidade ${tryGetValueOrDefault([visaoCliente], '-')}`,
      width: '150px',
      sortable: true,
      cell: (row: IBuscarClientesDPS) => {
        if (!row.codigo) return '-';
        return (
          <Tooltip text={row.codigo} position="right" variant="secondary">
            <Button
              variant="text"
              style={{ minWidth: '50px', padding: '0px' }}
              onClick={() => handleChangeRotaUnidade(String(row.codigo))}
            >
              {row.codigo} <Icon name="arrowRight" />
            </Button>
          </Tooltip>
        );
      },
    },

    {
      selector: 'quantidadeContratos',
      name: 'Quantidade de propostas',
      minWidth: '150px',
      center: true,
      sortable: true,
      cell: (row: IBuscarClientesDPS) => {
        return row.quantidadeContratos;
      },
    },
    {
      selector: 'valorContratos',
      name: 'Valor Total',
      minWidth: '150px',
      center: true,
      sortable: true,
      cell: (row: IBuscarClientesDPS) => {
        return tryGetMonetaryValueOrDefault(row.valorContratos, 0);
      },
    },
  ];
};
