// @ts-nocheck
import React, { useMemo } from 'react';
import { Accordion } from '@cvp/design-system/react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  COLUNAS_TABELA_BENEFICIARIOS,
  SEM_DADOS_BENEFICIARIOS,
} from 'prestamista/features/dadosSeguro/constants/Beneficiarios';
import { IBeneficiariosProps } from 'prestamista/features/dadosSeguro/types/IBeneficiarios';
import Table from 'main/components/Table';
import { BeneficiariosSkeleton } from 'prestamista/features/dadosSeguro/components/Beneficiarios/BeneficiariosSkeleton';

export const Beneficiarios: React.FC<IBeneficiariosProps> = ({
  data,
  loading,
}) => {
  const columns = useMemo(() => COLUNAS_TABELA_BENEFICIARIOS, []);

  if (loading) return <BeneficiariosSkeleton />;
  return (
    <Accordion open>
      <Accordion.Item
        title="Beneficiário"
        key="Beneficiário"
        data-testid="tabela-Beneficiarios"
      >
        <Table
          noHeader
          responsive
          striped
          columns={columns}
          data={tryGetValueOrDefault([data], [])}
          noDataComponent={SEM_DADOS_BENEFICIARIOS}
          pagination
          paginationPerPage={10}
          paginationComponentOptions={{
            rowsPerPageText: 'Itens por página',
            rangeSeparatorText: 'de',
          }}
        />
      </Accordion.Item>
    </Accordion>
  );
};
