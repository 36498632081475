import { IFilterOption } from 'main/components/Table/TableFilter/IFilterOption';
import masks from 'main/utils/masks';

export const ROTA_INICIAL = '/dps/pendentes';

export const SUPORTE_COMERCIAL =
  'Para dar continuidade ao envio da DPS, por favor, entre em contato com a Mesa de Apoio a Rede (MARE) pelo telefone (11) 2110-3450 ou procure o Consultor Comercial da sua região.';

export const FILTRO_DPS: IFilterOption[] = [
  {
    key: 'cpfCnpj',
    value: 'CPF / CNPJ',
    type: 'text',
    unmask: masks.cpf.unmask,
  },
  {
    key: 'proponente',
    value: 'Proponente',
    type: 'text',
  },
  {
    key: 'prazoLimite',
    value: 'Período',
    type: 'date',
  },
];

export const DADOS_INICIAL_AGENCIADPS = {
  proponente: '',
  valorPremio: 0,
  status: '',
  prazoLimite: '',
  dataVenda: '',
  numeroProposta: '',
  cpfCnpj: '',
  nomeCliente: '',
  dataNascimento: '',
  numeroTelefoneCliente: '',
  codigoSexo: '',
  emailCliente: '',
};

export const MESSANGEM_SUCESSO_ENVIO_DPS = 'Link reenviado com sucesso.';
