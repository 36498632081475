// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { PECOS_PAINELGESTAO } from 'main/features/HierarquiaAgencia/config/endpoints';
import { IRecuperarHierarquiaAgenciaPayload } from 'main/features/HierarquiaAgencia/types/IRecuperarHierarquiaAgenciaPayload';
import { IRecuperarHierarquiaAgenciaResponse } from 'main/features/HierarquiaAgencia/types/IRecuperarHierarquiaAgenciaResponse';

export const usePecoRecuperarHierarquiaAgencia = (codigo: string) => {
  return usePeco<
    IRecuperarHierarquiaAgenciaPayload,
    IRecuperarHierarquiaAgenciaResponse
  >({
    api: { operationPath: PECOS_PAINELGESTAO.RecuperarHierarquiaAgencia },
    handleResponse: { throwToastErrorBFF: false },
    cache: true,
    cacheKey: `${codigo}`,
    autoFetch: true,
    payload: {
      codigo,
    },
  });
};
