// @ts-nocheck
import { Display, StepBar, Card } from '@cvp/design-system/react';

import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import useTransferenciaEntreCertificados from '../../hooks/useTransferenciaEntreCertificados';
import React, { ReactNode } from 'react';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';

type TransferenciaWrapperProps = {
  children: ReactNode;
};
const TransferenciaWrapper: React.FC<TransferenciaWrapperProps> = ({
  children,
}: TransferenciaWrapperProps) => {
  const { step, steps } = useTransferenciaEntreCertificados();
  return (
    <FeatureAuthorizer
      requiredRoles={[
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_CONSULTA,
      ]}
      requiredPermissions={[PREV_PERMISSIONS.TRANSFERENCIA_CERTIFICADO]}
    >
      <Display type="display-block">
        <PrevidenciaResumo />
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Display style={{ margin: '0px 0px 30px 0px' }}>
              <StepBar step={step} steps={steps} disableClickEvent />
            </Display>
            {children}
          </Card.Content>
        </Card>
      </Display>
    </FeatureAuthorizer>
  );
};

export default TransferenciaWrapper;
