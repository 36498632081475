// @ts-nocheck
import { Checkbox } from '@cvp/design-system/react';

import {
  checkIfAllItemsAreTrue,
  getTernaryResult,
} from 'main/utils/conditional';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const ColunaSelecionarFundoDistribuicaoEntreFundos = ({
  row,
  dadosResumoFundosSelecionados,
  handleFundoSelecionado,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunaSelecionarFundoDistribuicaoEntreFundosProps) => {
  return (
    <Checkbox
      id={row.codFundo}
      disabled={checkIfAllItemsAreTrue([
        !row.selecionado,
        getTernaryResult(
          !!row.fundoNovo,
          dadosResumoFundosSelecionados.isDisabledNovos,
          dadosResumoFundosSelecionados.isDisabledExistentes,
        ),
      ])}
      checked={row.selecionado}
      onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
        handleFundoSelecionado(e.target.id);
      }}
    />
  );
};

export default ColunaSelecionarFundoDistribuicaoEntreFundos;
