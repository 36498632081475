// @ts-nocheck
import React from 'react';
import { Card, Display, Grid, Text } from '@cvp/design-system/react';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import ConsultaBeneficiariosPorAno from '../components/ConsultaBeneficiariosPorAno';

const InformesBeneficiarios: React.FC = () => {
  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredPermissions={[
          PREV_PERMISSIONS.INFORMACOES_DOS_BENEFICIARIOS_CONSULTA,
        ]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
          USER_PROFILES.ANALISTA_PJ,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary">
                  Informe de Rendimento(s) de Beneficiário(s)
                </Text>
              </Grid.Item>
              <ConsultaBeneficiariosPorAno />
            </Grid>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default InformesBeneficiarios;
