// @ts-nocheck
import { Accordion } from '@cvp/design-system/react';
import { Skeleton } from 'main/components/Table';
import React from 'react';
import { COLUNAS_TABELA_BENEFICIARIOS } from 'prestamista/features/dadosSeguro/constants/Beneficiarios';

export const BeneficiariosSkeleton: React.FC = () => {
  return (
    <Accordion open>
      <Accordion.Item
        title="Beneficiário"
        key="Beneficiário"
        data-testid="skeleton-beneficiário"
      >
        <Skeleton colunas={COLUNAS_TABELA_BENEFICIARIOS} />
      </Accordion.Item>
    </Accordion>
  );
};
