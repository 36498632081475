// @ts-nocheck
import { Modal as ModalBase, Text as TextBase } from '@cvp/design-system/react';
import styled from 'styled-components/macro';

export const Modal = styled(ModalBase)({
  maxWidth: '700px',
  width: '100%',
});

export const Text = styled(TextBase)({
  fontSize: '24px',
});
