// @ts-nocheck
export const colunas = [
  {
    name: 'Resumo',
    selector: (row: DataType) => row.resumo,
    sortable: false,
  },
  {
    name: 'Valor',
    selector: (row: DataType) => row.valor,
    sortable: false,
  },
];
