// @ts-nocheck
import { useContext, useEffect, useState } from 'react';
import {
  Card,
  Display,
  Divider,
  Radio,
  Skeleton,
  Text,
} from '@cvp/design-system/react';
import useObterCertificadosPrevidencia from 'consultaCliente/features/listaCardProduto/hooks/useObterCertificadosPrevidencia';
import { IDadosBasicosCertificadoPrevidencia } from 'main/types/CardPrevidencia/IDadosBasicosCertificadoPrevidencia';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import { AppContext } from 'main/contexts/AppContext';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import useFieldLink from 'main/hooks/useFieldLink';
import PeriodoExtrato from 'previdencia/components/PeriodoExtrato/PeriodoExtrato';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import RangeDate from 'previdencia/types/RangeDate';
import EmailSenderModal from 'main/components/EmailSenderModal';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as S from './styles';
import { calcularPeriodo, getOptions } from '../../utils/extrato';
import { formatarProdutos } from '../../factories/extratoFactory';
import useEmitirExtrato from '../../hooks/useEmitirExtrato';
import { useEnviarExtratoEmail } from '../../hooks/useEnviarExtratoEmail';
import { EnumTipoPeriodo } from '../../types/EnumEmitirExtrato';

const EmitirExtrato = (): React.ReactElement => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const [periodoLink, validatePeriodo] = useFieldLink('');
  const [rangeDate, setRangeDate] = useState<RangeDate>({} as RangeDate);
  const [extratoDateLink, validateExtratoDateLink] = useFieldLink({
    finalDate: null,
    initialDate: null,
  } as CalendarData);

  const [produtos, setProdutos] =
    useState<IDadosBasicosCertificadoPrevidencia[]>();

  const [certificadoSelecionado, setCertificadoSelecionado] =
    useState<string>(numCertificado);

  const [emailDefaultCliente, setEmailDefaultCliente] = useState<string>('');
  const [recuperacaoEmailExecutada, setRecuperacaoEmailExecutada] =
    useState<boolean>(false);

  const periodo = periodoLink.get().value;
  const periodoCustomizado = extratoDateLink.get().value;

  const payloadEmitirExtrato = {
    numeroCertificado: certificadoSelecionado,
    dataInicio: rangeDate.dataInicial,
    dataFim: rangeDate.dataFinal,
  };

  const hookEmitirExtrato = useEmitirExtrato(payloadEmitirExtrato);
  const hookEnviarExtrato = useEnviarExtratoEmail();

  const { fetchData: emitirExtrato, loading } = hookEmitirExtrato;
  const { montarObjetoEmail, obterEmailDefault, atualizarParametrosExtrato } =
    hookEnviarExtrato;

  const objetoSendEmail = montarObjetoEmail(
    certificadoSelecionado,
    rangeDate.dataInicial,
    rangeDate.dataFinal,
  );

  const { data: produtosPrev, isLoading: loadingPrev } =
    useObterCertificadosPrevidencia(cpfCnpj);

  const recuperarEmailCliente = async (numeroCertificado: string) => {
    try {
      setEmailDefaultCliente('');
      setRecuperacaoEmailExecutada(false);
      const emailRecuperado = await obterEmailDefault(numeroCertificado);
      setEmailDefaultCliente(emailRecuperado);
    } catch {
      setEmailDefaultCliente('');
    } finally {
      setRecuperacaoEmailExecutada(true);
    }
  };

  useEffect(() => {
    recuperarEmailCliente(certificadoSelecionado);
  }, [certificadoSelecionado]);

  useEffect(() => {
    setProdutos(formatarProdutos(produtosPrev));
  }, [produtosPrev]);

  useEffect(() => {
    if (periodo === EnumTipoPeriodo.customizado) {
      setRangeDate({ dataInicial: undefined, dataFinal: undefined });
      return;
    }
    const periodoCalculado = calcularPeriodo(periodo);
    setRangeDate(periodoCalculado);
    atualizarParametrosExtrato({
      cpfCnpj,
      numeroCertificado: certificadoSelecionado,
      dataInicial: tryGetValueOrDefault([periodoCalculado.dataInicial], null),
      dataFinal: tryGetValueOrDefault([periodoCalculado.dataFinal], null),
    });
  }, [periodo]);

  useEffect(() => {
    setRangeDate({
      dataInicial: periodoCustomizado.initialDate,
      dataFinal: periodoCustomizado.finalDate,
    } as RangeDate);
  }, [periodoCustomizado]);

  if (loadingPrev) {
    return <Skeleton lines={4} />;
  }
  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredPermissions={[PREV_PERMISSIONS.EXTRATO_DETALHADO]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
          USER_PROFILES.ANALISTA_PJ,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Text variant="headline-05" color="primary" margin>
              Extrato
            </Text>
            <Text variant="body01-md" margin>
              Escolha o certificado que deseja emitir e logo em seguida
              selecione o período:
            </Text>
            <Divider />
            {produtos?.map((item: IDadosBasicosCertificadoPrevidencia) => (
              <Display alignItems="center">
                <Radio
                  name="certificado"
                  checked={certificadoSelecionado === item.certificadoNumero}
                  onChange={() =>
                    setCertificadoSelecionado(item.certificadoNumero)
                  }
                />
                {`${item.certificadoNumero} - ${item.produto.descricaoProduto} - ${item.produto.modalidade} - ${item.situacao}`}
              </Display>
            ))}
            <S.SelectRange>
              <PeriodoExtrato
                options={getOptions()}
                periodoLink={periodoLink}
                inputDateLink={extratoDateLink}
                validatePeriodo={validatePeriodo}
                validateInputDate={validateExtratoDateLink}
                refetch={emitirExtrato}
                loading={loading}
                emitirDisabled={
                  !checkIfAllItemsAreTrue([
                    !!certificadoSelecionado,
                    !!rangeDate.dataInicial,
                    !!rangeDate.dataFinal,
                  ])
                }
              >
                <EmailSenderModal
                  defaultEmail={emailDefaultCliente}
                  disabled={
                    !checkIfAllItemsAreTrue([
                      !!certificadoSelecionado,
                      !!rangeDate.dataInicial,
                      !!rangeDate.dataFinal,
                      recuperacaoEmailExecutada,
                    ])
                  }
                  objetoEmail={objetoSendEmail}
                />
              </PeriodoExtrato>
            </S.SelectRange>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default EmitirExtrato;
