// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS_PRESTAMISTA } from 'prestamista/config/endpoints';
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import {
  IRequestCoberturaAssitencia,
  IResponseCoberturaAssistencia,
} from 'prestamista/types/ICoberturaAssistencias';

const usePecoCoberturaAssistencias = () => {
  const { clientePrestamista } = useContext(AppContext);

  const {
    response,
    loading: loadingCoberturaAssitencia,
    fetchData: fetchCoberturaAssitencia,
  } = usePeco<IRequestCoberturaAssitencia, IResponseCoberturaAssistencia>({
    api: {
      operationPath:
        PECOS_PRESTAMISTA.ObterAssistenciasCoberturasPorContratoPrestamista,
    },
    autoFetch: true,
    handleResponse: { throwToastErrorBFF: false },
    payload: {
      numeroContrato: tryGetValueOrDefault(
        [clientePrestamista?.numeroContrato],
        '',
      ),
    },
  });

  return {
    responseAssitencias: response?.entidade?.assistenciaPorContratos,
    responseCoberturas: response?.entidade?.coberturasPorContratos,
    loadingCoberturaAssitencia,
    fetchCoberturaAssitencia,
  };
};

export default usePecoCoberturaAssistencias;
