// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import {
  ISolicitacaoDpsPayload,
  ISolicitacaoDpsResponse,
} from 'main/types/Dps/SolicitarDps';
import { PECOS_PROPOSTA_VIDA } from 'propostasVida/config/endpoints';

export const useSolicitaDps = () => {
  const { response, loading, fetchData } = usePeco<
    ISolicitacaoDpsPayload,
    ISolicitacaoDpsResponse
  >({
    api: {
      operationPath: PECOS_PROPOSTA_VIDA.SolicitarReenvioLinkPorEmailDps,
    },
    cache: false,
  });

  return { response, loading, fetchData };
};
