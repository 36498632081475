// @ts-nocheck
import {
  COLUNAS_TABELA_COBERTURAS,
  SEM_DADOS_COBERTURAS,
} from 'prestamista/features/dadosSeguro/constants/Coberturas';
import React, { useMemo } from 'react';
import { ICoberturaProps } from 'prestamista/features/dadosSeguro/types/ICoberturas';
import { TableSemHeader } from 'prestamista/components/TabelaSemHeader';
import { tryGetValueOrDefault } from '../../../../../main/utils/conditional';

export const Coberturas: React.FC<ICoberturaProps> = ({ data }) => {
  const columns = useMemo(() => COLUNAS_TABELA_COBERTURAS, []);

  return (
    <TableSemHeader
      noHeader
      responsive
      striped
      highlightOnHover
      columns={columns}
      data={tryGetValueOrDefault([data], [])}
      noDataComponent={SEM_DADOS_COBERTURAS}
      paginationPerPage={10}
      paginationComponentOptions={{
        rowsPerPageText: 'Itens por página',
        rangeSeparatorText: 'de',
      }}
    />
  );
};
