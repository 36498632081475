import React from 'react';
import {
  Display,
  Grid,
  TextField,
  Button,
  Text,
  Card,
} from '@cvp/design-system/react';
import masks from 'main/utils/masks';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import { showErrorInput } from 'main/utils/formik';
import { useSolicitar } from '../hooks/useSolicitar';

const Identificacao: React.FC = () => {
  const service = useSolicitar();

  return (
    <Display type="display-block">
      <Card>
        <Card.Content>
          <>
            <br />
            <Text variant="body-medium3" color="text-light">
              *Atenção: Todos os campos são obrigatórios!
            </Text>
            <br />
            <form onSubmit={service.formik.handleSubmit}>
              <Grid>
                <Grid.Item xs={1 / 3}>
                  <TextField
                    label="*CPF/CNPJ"
                    name="cpfCnpj"
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      service.formik.setFieldValue(
                        'cpfCnpj',
                        masks.numberOnly.unmask(value),
                      );
                    }}
                    value={maskCpfCnpj(service.formik.values.cpfCnpj)}
                    onBlur={async (e: React.FocusEvent<any>) => {
                      service.formik.handleBlur(e);
                      await service.handleConsultarImportanciaSegurada(
                        service.formik.values.cpfCnpj,
                      );
                    }}
                    placeholder=""
                    {...showErrorInput(service.formik, 'cpfCnpj')}
                  />
                </Grid.Item>
                <Grid.Item xs={1 / 3}>
                  <TextField
                    label="*Total de acúmulo de risco"
                    name="totalAcumuloRisco"
                    disabled
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      service.formik.setFieldValue(
                        'totalAcumuloRisco',
                        masks.currencyInput.unmask(value),
                      );
                    }}
                    value={masks.currencyInput.mask(
                      service.formik.values.totalAcumuloRisco,
                    )}
                    onBlur={service.formik.handleBlur}
                    placeholder=""
                    {...showErrorInput(service.formik, 'totalAcumuloRisco')}
                  />
                </Grid.Item>
              </Grid>

              <Grid>
                <Grid.Item xs={1 / 3}>
                  <TextField
                    label="Importância segurada"
                    name="importanciaSegurada"
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      service.formik.setFieldValue(
                        'importanciaSegurada',
                        masks.currencyInput.unmask(value),
                      );
                    }}
                    value={masks.currencyInput.mask(
                      service.formik.values.importanciaSegurada,
                    )}
                    onBlur={service.formik.handleBlur}
                    placeholder=""
                    {...showErrorInput(service.formik, 'importanciaSegurada')}
                  />
                </Grid.Item>
              </Grid>
              <br />
              <Display>
                <Button
                  type="submit"
                  variant="primary"
                  loading={service.loadingObterIs}
                  disabled={Object.keys(service.formik.errors).length > 0}
                >
                  Avançar
                </Button>
              </Display>
            </form>
          </>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default Identificacao;
