import { useFormik } from 'formik';
import { useObterValorImportanciaSegurada } from 'main/hooks/useObterValorImportanciaSegurada';
import masks from 'main/utils/masks';
import { useHistory } from 'react-router';
import solicitarValidationSchema from '../constant/solicitarValidationSchema';
import { IFormSolicitarDps } from '../types/IFormSolicitarDps';

export const useSolicitar = () => {
  const history = useHistory();
  const formik = useFormik<IFormSolicitarDps>({
    initialValues: {
      cpfCnpj: '',
      totalAcumuloRisco: 0,
      importanciaSegurada: 0,
    },
    validationSchema: solicitarValidationSchema,
    onSubmit: (values: IFormSolicitarDps) => {
      history.push('/dps/atualizar', {
        ...values,
        importanciaSegurada:
          Number(values.importanciaSegurada) + Number(values.totalAcumuloRisco),
      });
    },
  });
  const { loadingObterIs, fetchDataImportanciaSegurada } =
    useObterValorImportanciaSegurada();

  const handleConsultarImportanciaSegurada = async (cpf: string) => {
    if (cpf.length > 0) {
      const result = await fetchDataImportanciaSegurada({
        cpfCnpjCliente: masks.numberOnly.unmask(cpf),
      });

      if (result) {
        const dados = result.entidade;
        formik.setFieldValue('totalAcumuloRisco', dados?.valorTotalContratos);
      }
    }
  };

  return {
    history,
    formik,
    loadingObterIs,
    handleConsultarImportanciaSegurada,
  };
};
