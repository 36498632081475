// @ts-nocheck
export const DPS_CANCELADA = 'DCA';
export const NAO_NECESSITA_DPS = 'NDN';
export const NECESSITA_DPS = 'NDS';
export const DPS_APROVADA = 'DAP';

export const STATUS_DPS: Record<string, string> = {
  DAP: 'Aceite',
  DRE: 'Recusa',
  DCA: 'Cancelada',
  NDN: 'Não Necessita DPS',
  NDS: 'Necessita DPS',
  PMP: 'Em análise',
  DAG: 'Inconclusivo',
  DIN: 'Inconclusivo',
};

export const STATUS_UPLOAD: Record<string, string> = {
  LFE: 'Liberado fluxo de exceção',
  NNU: 'Não necessita Upload',
  NRU: 'Upload não realizado',
  PAR: 'Proposta anterior a regra de upload',
  RUP: 'Realizou upload de proposta',
  PAD: 'Proposta assinada digitalmente',
  PUE: 'Pendente upload de proposta de exceção',
};

export const STATUS_REEENVIAR_DPS = ['DRE'];

export const MAPEAMENTO_REENVIAR_LINK = ['7', '21', '1'];

export const TIPO_PESSOA_DPS = {
  FISICA: 'F',
  JURIDICA: 'J',
};

export const STATUS_NAO_EXIBIR_ACAO_DPS = [
  'DAP',
  'DRE',
  'DIN',
  'DAG',
  'NDN',
  'DCA',
  'PMP',
];
