// @ts-nocheck
import { Text } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/constants';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';

const ColunaDescricaoFundoDistribuicaoEntreFundos = ({
  row,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunaDescricaoFundoDistribuicaoEntreFundosProps) => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text variant="body01-sm">
        {row.codFundo} - {row.desFundo}
      </Text>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !!row.selecionado,
          featureData?.tipoTransferencia === CONSTS.TIPOS_TRANSFERENCIA.PARCIAL,
        ])}
      >
        <Text variant="caption-02">
          Caso a transferência seja parcial, um valor mínimo de{' '}
          {tryGetMonetaryValueOrDefault(row?.vlrMinDiversificacao)} deverá
          constar na entrada do fundo.
        </Text>
      </RenderConditional>
    </div>
  );
};

export default ColunaDescricaoFundoDistribuicaoEntreFundos;
