import React from 'react';
import { Text, Display, Grid, Modal } from '@cvp/design-system/react';

import Icon from 'main/components/Icon';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import masks from 'main/utils/masks';
import { formatarDataAmigavel } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import useSegmento from 'painelInadimplencia/hooks/useSegmento';
import * as UTILS from 'painelInadimplencia/utils/PainelInadimplencia';
import * as CONSTS from 'painelInadimplencia/constants/constants';
import { ModalProps } from 'painelInadimplencia/types/ModalDetalhesInadimplencia';

const ModalDetalhesInadimplencia: React.FC<ModalProps> = ({
  open,
  dados,
  pagamentoDetalhado,
  isLoading,
  onClose,
}) => {
  const { validarSegmentoRota } = useSegmento();

  const periodicidadePagamento = UTILS.getPeriodicidadePagamentoLabel(
    tryGetValueOrDefault([dados?.opcaoPagamento], ''),
  );

  return (
    <Modal show={open} onClose={onClose} style={{ maxWidth: 'none' }}>
      <RenderConditional condition={!!isLoading}>
        <SkeletonLoading blocks={1} lines={10} />
      </RenderConditional>

      <RenderConditional condition={!isLoading}>
        <Grid>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="user" />
              <span>
                <Text variant="caption-02" align="left">
                  Nome
                </Text>
                <Text variant="body02-xs" align="left">
                  {tryGetValueOrDefault([dados?.nomeCliente], '-')}
                </Text>
              </span>
            </Display>
          </Grid.Item>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="documentFront" />
              <span>
                <Text variant="caption-02" align="left">
                  CPF
                </Text>
                <Text variant="body02-xs" align="left">
                  {masks.cpf.mask(dados?.cpf?.toString())}
                </Text>
              </span>
            </Display>
          </Grid.Item>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="documentPaper" />
              <span>
                <Text variant="caption-02" align="left">
                  Nome do Produto
                </Text>
                <Text variant="body02-xs" align="left">
                  {tryGetValueOrDefault([dados?.produto], '-')}
                </Text>
              </span>
            </Display>
          </Grid.Item>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="waiting" />
              <span>
                <Text variant="caption-02" align="left">
                  Periodicidade do produto
                </Text>
                <Text variant="body02-xs" align="left">
                  {tryGetValueOrDefault([periodicidadePagamento], '-')}
                </Text>
              </span>
            </Display>
          </Grid.Item>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="financialWallet" />
              <span>
                <Text variant="caption-02" align="left">
                  Opção de Pagamento
                </Text>
                <Text variant="body02-xs" align="left">
                  {tryGetValueOrDefault([dados?.formaPagamento], '-')}
                </Text>
              </span>
            </Display>
          </Grid.Item>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="financialMoneyBag" />
              <span>
                <Text variant="caption-02" align="left">
                  <RenderConditional
                    condition={validarSegmentoRota(CONSTS.SEGMENTO.VIDA)}
                  >
                    Valor de prêmio
                  </RenderConditional>
                  <RenderConditional
                    condition={validarSegmentoRota(CONSTS.SEGMENTO.PREV)}
                  >
                    Valor de Contribuição
                  </RenderConditional>
                </Text>
                <Text variant="body02-xs" align="left">
                  {tryGetValueOrDefault(
                    [tryGetMonetaryValueOrDefault(dados?.valorProposta)],
                    '-',
                  )}
                </Text>
              </span>
            </Display>
          </Grid.Item>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="calendar" />
              <span>
                <Text variant="caption-02" align="left">
                  Último mês referência da parcela inadimplente
                </Text>
                <Text variant="body02-xs" align="left">
                  {tryGetValueOrDefault(
                    [formatarDataAmigavel(dados?.ultimaCobranca)],
                    '-',
                  )}
                </Text>
              </span>
            </Display>
          </Grid.Item>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="waiting" />
              <span>
                <Text variant="caption-02" align="left">
                  Tempo de vida do contrato (em mês);
                </Text>
                <Text variant="body02-xs" align="left">
                  {tryGetValueOrDefault([dados?.tempoContrato], '-')}
                </Text>
              </span>
            </Display>
          </Grid.Item>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="filterEqualize" />
              <span>
                <Text variant="caption-02" align="left">
                  Beneficiários Indicados?
                </Text>
                <Text variant="body02-xs" align="left">
                  {tryGetValueOrDefault([dados?.beneficiariosExistentes], '-')}
                </Text>
              </span>
            </Display>
          </Grid.Item>

          <RenderConditional condition={dados?.segmento === CONSTS.PREV}>
            <Grid.Item xs={1 / 2}>
              <Display alignItems="center">
                <Icon name="filterEqualize" />
                <span>
                  <Text variant="caption-02" align="left">
                    Comunicabilidade (sim ou não).
                  </Text>
                  <Text variant="body02-xs" align="left">
                    {tryGetValueOrDefault([dados?.comunicabilidade], '-')}
                  </Text>
                </span>
              </Display>
            </Grid.Item>
          </RenderConditional>
        </Grid>

        <RenderConditional
          condition={
            dados?.formaPagamento === CONSTS.TIPOS_FORMA_PAGAMENTO.DEBITO
          }
        >
          <Display alignItems="center">
            <Icon name="bank" />
            <span>
              <Text variant="caption-02" align="left">
                Agência
              </Text>
              <Text variant="body02-xs" align="left">
                {tryGetValueOrDefault([pagamentoDetalhado?.codigoAgencia], 0)}
              </Text>
            </span>
            <span>
              <Text variant="caption-02" align="left">
                Operação
              </Text>
              <Text variant="body02-xs" align="left">
                {tryGetValueOrDefault(
                  [pagamentoDetalhado?.codigoOperacaoCobranca],
                  0,
                )}
              </Text>
            </span>
            <span>
              <Text variant="caption-02" align="left">
                Conta
              </Text>
              <Text variant="body02-xs" align="left">
                {tryGetValueOrDefault(
                  [pagamentoDetalhado?.numeroContaCobranca],
                  '0',
                )}
              </Text>
            </span>
          </Display>
        </RenderConditional>

        <RenderConditional
          condition={
            dados?.formaPagamento === CONSTS.TIPOS_FORMA_PAGAMENTO.CREDITO
          }
        >
          <Display alignItems="center">
            <Icon name="creditCard" />
            <span>
              <Text variant="caption-02" align="left">
                Cartão de Crédito
              </Text>
              <Text variant="body02-xs" align="left">
                xxxx.xxxx.xxxx.
                {tryGetValueOrDefault(
                  [pagamentoDetalhado?.numeroCartaoCredito.toString()],
                  'xxxx',
                )}
              </Text>
            </span>
          </Display>
        </RenderConditional>

        <RenderConditional
          condition={
            dados?.formaPagamento === CONSTS.TIPOS_FORMA_PAGAMENTO.BOLETO
          }
        >
          <Display alignItems="center">
            <Icon name="home" />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <span style={{ minWidth: '150px', margin: '0 20px 10px 0' }}>
                  <Text variant="caption-02" align="left">
                    Endereço
                  </Text>
                  <Text variant="body02-xs" align="left">
                    {tryGetValueOrDefault(
                      [pagamentoDetalhado?.logradouro],
                      '-',
                    )}
                  </Text>
                </span>
                <span style={{ minWidth: '150px', margin: '0 20px 10px 0' }}>
                  <Text variant="caption-02" align="left">
                    Bairro
                  </Text>
                  <Text variant="body02-xs" align="left">
                    {tryGetValueOrDefault([pagamentoDetalhado?.bairro], '-')}
                  </Text>
                </span>
                <span style={{ minWidth: '150px', margin: '0 20px 10px 0' }}>
                  <Text variant="caption-02" align="left">
                    Cidade
                  </Text>
                  <Text variant="body02-xs" align="left">
                    {tryGetValueOrDefault([pagamentoDetalhado?.cidade], '-')}
                  </Text>
                </span>
              </div>
              <div style={{ display: 'flex' }}>
                <span style={{ minWidth: '150px', margin: '0 20px 10px 0' }}>
                  <Text variant="caption-02" align="left">
                    UF
                  </Text>
                  <Text variant="body02-xs" align="left">
                    {tryGetValueOrDefault([pagamentoDetalhado?.estado], '-')}
                  </Text>
                </span>
              </div>
            </div>
          </Display>
        </RenderConditional>
      </RenderConditional>
    </Modal>
  );
};

export default ModalDetalhesInadimplencia;
