// @ts-nocheck
import React, { useEffect } from 'react';
import { Container, Text, Skeleton, Checkbox } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import useAlterarValorContribuicaoBeneficioProtecao from 'previdencia/features/AlterarValorContribuicao/hooks/useAlterarValorContribuicaoBeneficioProtecao';
import useObterMesesCarenciaBeneficioProtecao from 'previdencia/hooks/useObterMesesCarenciaBeneficioProtecao';
import { encontrarCobertura } from 'previdencia/features/AlterarValorContribuicao/factories/AlterarValorContribuicaoFactory';
import { ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecaoProps } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoComponentProps';
import { periodicidadePorExtenso } from 'previdencia/features/AlterarValorContribuicao/utils/periodicidadePorExtenso';
import { TEXTO_CONFIRMACAO_CLIENTE_CIENTE } from 'previdencia/features/AlterarValorContribuicao/constants/constants';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';
import {
  Botao,
  ContainerInputChild,
} from 'previdencia/features/AlterarValorContribuicao/components/styles';
import * as S from 'previdencia/features/AlterarValorContribuicao/components/ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecao/styles';

export const ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecao: React.FunctionComponent<
  ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecaoProps
> = ({
  loadingCalculoBeneficioProtecao,
  open,
  consentimento,
  setConfirmarClienteCienteValue,
  setOpenModalDocusign,
  dadosBeneficioProtecao,
  dataModalConfirmacao,
  exibirNovosValoresBeneficioProtecao,
  setResultAlterarValorContribuicaoBeneficioProtecao,
}) => {
  const [consentimentoValue, setConsentimentoValue] = consentimento;
  const [
    exibirNovosValoresBeneficioProtecaoValue,
    setExibirNovosValoresBeneficioProtecaoValue,
  ] = exibirNovosValoresBeneficioProtecao;

  const handleBotaoCancelar = () => {
    setExibirNovosValoresBeneficioProtecaoValue(false);
    setConsentimentoValue(false);
  };

  const {
    isLoadingAlterarValorContribuicaoBeneficioProtecao,
    resultAlterarValorContribuicaoBeneficioProtecao,
    alterarValorContribuicaoBeneficioProtecao,
  } = useAlterarValorContribuicaoBeneficioProtecao();

  const handleBotaoConfirmar = () => {
    const planoSelecionado = encontrarCobertura(
      dadosBeneficioProtecao?.coberturas ?? [],
      dataModalConfirmacao?.descricaoTipoBeneficio,
    );

    alterarValorContribuicaoBeneficioProtecao(
      planoSelecionado,
      dataModalConfirmacao.novoValor,
    );
  };

  const {
    loading: loadingMesesCarenciaBeneficioProtecao,
    response: mesesCarenciaBeneficioProtecaoValue,
    obterCarenciaBeneficioProtecao,
  } = useObterMesesCarenciaBeneficioProtecao();

  useEffect(() => {
    if (resultAlterarValorContribuicaoBeneficioProtecao) {
      setConfirmarClienteCienteValue(true);
      setOpenModalDocusign(true);
      setExibirNovosValoresBeneficioProtecaoValue(true);
      setResultAlterarValorContribuicaoBeneficioProtecao(
        resultAlterarValorContribuicaoBeneficioProtecao,
      );
    }
  }, [resultAlterarValorContribuicaoBeneficioProtecao]);

  useEffect(() => {
    const { codBeneficio, codPlan } = encontrarCobertura(
      dadosBeneficioProtecao?.coberturas ?? [],
      dataModalConfirmacao?.descricaoTipoBeneficio,
    );

    obterCarenciaBeneficioProtecao(codBeneficio, codPlan);
  }, [dataModalConfirmacao]);

  return (
    <S.ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecao
      show={open}
      showCloseButton={false}
      onClose={handleBotaoCancelar}
    >
      <RenderConditional
        condition={
          loadingMesesCarenciaBeneficioProtecao ||
          loadingCalculoBeneficioProtecao
        }
      >
        <Skeleton lines={8} />
      </RenderConditional>

      <RenderConditional
        condition={
          !(
            loadingMesesCarenciaBeneficioProtecao ||
            loadingCalculoBeneficioProtecao
          )
        }
      >
        <>
          <Text variant="headline-06" color="primary" margin>
            Confirmar Alteração de Valor de Contribuição de Benefício Proteção
          </Text>

          <RenderConditional
            condition={exibirNovosValoresBeneficioProtecaoValue}
          >
            <Container textAlign="left" marginBottom="1rem" padding={0}>
              <Text variant="body02-sm">
                Novo valor de contribuição para{' '}
                {dataModalConfirmacao.descricaoTipoBeneficio} é:{' '}
                <strong>
                  {formatarValorPadraoBrasileiro(
                    dataModalConfirmacao.novoValor,
                  )}
                </strong>
              </Text>
              <Text variant="body02-sm">
                Periodicidade de pagamento:{' '}
                <strong>
                  {periodicidadePorExtenso(dataModalConfirmacao.periodicidade)}
                </strong>
              </Text>
              <Text variant="body02-sm">
                O valor da indenização será de:{' '}
                <strong>
                  {formatarValorPadraoBrasileiro(
                    dataModalConfirmacao.valorCustomizacao ?? 0,
                  )}
                </strong>
              </Text>
              <RenderConditional
                condition={
                  dataModalConfirmacao.tipoBeneficio ===
                  DefaultValue.TIPO_BENEFICIO_PENSAO
                }
              >
                <Text variant="body02-sm">
                  Prazo da renda mensal:{' '}
                  <strong>{dataModalConfirmacao.prazo} anos</strong>
                </Text>
              </RenderConditional>
            </Container>
          </RenderConditional>

          <S.ContentModalValorContribuicao>
            <Checkbox
              checked={consentimentoValue}
              onChange={() => setConsentimentoValue(!consentimentoValue)}
            />
            <Text variant="body02-sm" align="justify">
              {TEXTO_CONFIRMACAO_CLIENTE_CIENTE(
                mesesCarenciaBeneficioProtecaoValue,
              )}
            </Text>
          </S.ContentModalValorContribuicao>

          <ContainerInputChild>
            <Botao
              disabled={isLoadingAlterarValorContribuicaoBeneficioProtecao}
              onClick={handleBotaoCancelar}
              variant="primary"
            >
              Cancelar
            </Botao>
            <Botao
              disabled={
                !consentimentoValue ||
                isLoadingAlterarValorContribuicaoBeneficioProtecao
              }
              onClick={handleBotaoConfirmar}
              variant="secondary"
            >
              Confirmar
            </Botao>
          </ContainerInputChild>
        </>
      </RenderConditional>
    </S.ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecao>
  );
};

export default ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecao;
