// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Card, Display, Text, Button } from '@cvp/design-system/react';
import Modal from 'main/components/Modal';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import RenderConditional from 'main/components/RenderConditional';
import { useHistory, useLocation } from 'react-router';
import { PREV_REDIRECTS_SCREENS } from 'previdencia/config/redirects';
import { IRedirecionamentoHistoricoSolicitacaoLocation } from 'previdencia/types/IRedirecionamentoHistoricoSolicitacaoLocation';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import ComprovanteTransferenciaFundos from '../components/ComprovanteTransferenciaFundos';
import DadosResgate from '../components/DadosResgate';
import DadosSolicitacao from '../components/DadosSolicitacao';
import RedistribuirContribuicaoRegular from '../components/RedistribuirContribuicaoRegular';
import RedistribuirValor from '../components/RedistribuirValor';
import TransferenciaFundosProvider, {
  useTransferenciaFundosContext,
} from '../contexts/ContextTransferenciaFundos';
import useValidarAssinaturaTransferencia from '../hooks/useValidarAssinaturaTransferencia';
import ConclusaoTransferenciaFundos from '../components/ConclusaoTransferenciaFundos';

const TransferenciaFundos = (): React.ReactElement => {
  const { state } =
    useLocation<IRedirecionamentoHistoricoSolicitacaoLocation>();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [mensagemModal, setMensagemModal] = useState<string[]>([]);
  const [openModalAlerta, setOpenModalAlerta] = useState<boolean>(false);
  const {
    etapa,
    mensagemErro,
    definirNumeroTransferencia,
    direcionarNovaTela,
    definirPermissaoAssinatura,
  } = useTransferenciaFundosContext();
  const {
    data: responseObterContatosDefault,
    isFetched: recuperacaoEmailExecutada,
    isLoading: loadingObterContatos,
  } = useObterContatosDefaultPrevidencia();
  const { emailDefault, numerosTelefone } = responseObterContatosDefault ?? {};

  const { fetchingValidarAssinatura, permissaoAssinatura, validarAssinatura } =
    useValidarAssinaturaTransferencia();

  useEffect(() => {
    if (mensagemErro) {
      setOpenModal(true);
      setMensagemModal(mensagemErro);
    }
  }, [mensagemErro]);

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        !loadingObterContatos,
        !fetchingValidarAssinatura,
        permissaoAssinatura === true,
        recuperacaoEmailExecutada,
        !state?.from,
      ])
    ) {
      setOpenModalAlerta(true);
    }
    definirPermissaoAssinatura(permissaoAssinatura);
  }, [
    fetchingValidarAssinatura,
    permissaoAssinatura,
    loadingObterContatos,
    recuperacaoEmailExecutada,
    state?.from,
  ]);

  useEffect(() => {
    validarAssinatura();
  }, []);

  useEffect(() => {
    if (
      state?.from === PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES &&
      state?.data.idRequisicao
    ) {
      definirNumeroTransferencia(state.data.idRequisicao);
      direcionarNovaTela(
        'comprovanteTransferencia',
        PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES,
      );
    }
  }, [state?.from, state?.data?.idRequisicao]);

  return (
    <>
      <Display type="display-block">
        <PrevidenciaResumo />
        <FeatureAuthorizer
          requiredRoles={[
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_PJ,
          ]}
          requiredPermissions={[PREV_PERMISSIONS.TRANSFERENCIA_DE_FUNDOS]}
        >
          <Card>
            <Card.Content padding={[4, 4, 4]}>
              <RenderConditional condition={loadingObterContatos}>
                <Display justify="center">
                  <Button variant="text" loading />
                </Display>
              </RenderConditional>

              <RenderConditional condition={!loadingObterContatos}>
                <div>
                  <Text variant="headline-05" color="primary" margin>
                    Transferência entre fundos de Investimentos
                  </Text>
                  {etapa === 'definirFundosOrigem' && (
                    <>
                      <DadosResgate />
                    </>
                  )}
                  {etapa === 'definirFundosDestino' && (
                    <>
                      <RedistribuirValor />
                    </>
                  )}
                  {etapa === 'definirReservaDestino' && (
                    <>
                      <DadosSolicitacao />
                    </>
                  )}
                  {etapa === 'redistribuirContribuicaoRegular' && (
                    <>
                      <RedistribuirContribuicaoRegular />
                    </>
                  )}
                  {etapa === 'comprovanteTransferencia' && (
                    <>
                      <ComprovanteTransferenciaFundos />
                    </>
                  )}
                  {etapa === 'conclusaoTransferencia' && (
                    <>
                      <ConclusaoTransferenciaFundos />
                    </>
                  )}
                </div>
              </RenderConditional>
            </Card.Content>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              {(mensagemModal.length > 1 &&
                mensagemModal.map(e => (
                  <>
                    <li>{e}</li>
                    <br />
                  </>
                ))) || <>{mensagemModal}</>}
            </Modal>
          </Card>
        </FeatureAuthorizer>
      </Display>
      <ModalAlertaContatos
        open={openModalAlerta}
        onClose={() => setOpenModalAlerta(false)}
        redirect={() =>
          history.push('/cliente/produtos/previdencia/dados-participante')
        }
        dados={{
          email: emailDefault,
          telefone: numerosTelefone,
        }}
      />
    </>
  );
};

const TransferenciaFundoInvestimento: React.FC = () => {
  return (
    <TransferenciaFundosProvider>
      <TransferenciaFundos />
    </TransferenciaFundosProvider>
  );
};

export default TransferenciaFundoInvestimento;
