// @ts-nocheck
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { ISubscricaoSocio } from '../types/ConsultarSubcricao';
import { formatarData, formatarDataAdicionandoSeparador } from 'main/utils';

export const obterVariacaoBotaoValidaDados = (validado?: boolean) =>
  validado ? 'secondary' : 'primary';

export const obterTextoBotaoValidarDados = (validado?: boolean) =>
  validado ? 'Validado' : 'Atualizar dados';

export const obterMensagemNecessidadeDps = (necessitaDps?: boolean) =>
  necessitaDps
    ? 'Envio da declaração pessoal de saúde pendente'
    : 'Envio da DPS não necessário';

export const calcularValorCapitalSeguradoIndividual = (
  valorCapitalSegurado?: number | string,
  quantidadeSocios?: number,
) => {
  if (quantidadeSocios && quantidadeSocios > 1) {
    return (
      tryGetValueOrDefault([Number(valorCapitalSegurado)], 0) / quantidadeSocios
    );
  }
  return Number(valorCapitalSegurado);
};

export const calcularValorAcumulo = (
  valorContratos: number,
  valorCapitalSegurado?: number | string,
) => {
  const rateioValorCredito =
    tryGetValueOrDefault([Number(valorCapitalSegurado)], 0) + valorContratos;

  return +rateioValorCredito.toFixed(2);
};

export const mapearEnderecoSocio = (socio: ISubscricaoSocio) => {
  const dataNascimento = formatarData(
    new Date(
      formatarDataAdicionandoSeparador(socio.dataNascimento),
    ).toISOString(),
  );
  if (socio.endereco) {
    const [endereco, bairro, cidade, uf] = socio.enderecoResidencial.split('|');
    return {
      ...socio,
      endereco,
      bairro,
      cidade,
      uf,
      dataNascimento,
    };
  }
  return {
    ...socio,
    dataNascimento,
  };
};

export const converterValorContrato = (
  valorTotalContratos?: number | string | null,
) => {
  return Number(valorTotalContratos ?? 0);
};

export const obterRetornoAdicionarSocio = (
  socio: unknown,
  dadosSocios: ISubscricaoSocio[],
  cnpj?: string,
): ISubscricaoSocio[] => {
  const socioCast = socio as ISubscricaoSocio;
  if (socioCast?.nome) {
    return [
      ...dadosSocios.map(item => ({ ...item, dpsVerificada: false })),
      {
        ...socioCast,
        validado: true,
        novoSocio: true,
        cnpj: tryGetValueOrDefault([cnpj], ''),
      },
    ];
  }

  return [] as ISubscricaoSocio[];
};

export const obterListaSociosValidado = (
  socio: unknown,
  dadosSocios: ISubscricaoSocio[],
) => {
  const socioCast = socio as ISubscricaoSocio;
  const indexToUpdate = dadosSocios.findIndex(x => x.cpf === socioCast.cpf);
  if (indexToUpdate >= 0) {
    const dadosSociosUpdated = [...dadosSocios];
    dadosSociosUpdated[indexToUpdate] = {
      ...socioCast,
      validado: true,
    };
    return dadosSociosUpdated;
  }
  return dadosSocios;
};
