// @ts-nocheck
/* eslint-disable react/no-unstable-nested-components */
import { Breadcrumb, Display } from '@cvp/design-system/react';
import React from 'react';
import Breadcrumbs from './BreadcrumbList';
import { TMappedRoutes } from './Breadcrumb.types';

/**
 * Componente cria breadcrumbs automático baseado no roteamento.
 * Caso seja necessário personalizar os nomes dos breadcrumbs, passe o parametro routes
 * @param routes
 * JSON com chave (path) e valor (nome da rota)
 * @returns React.FunctionComponent
 */
const AppBreadcrumb: React.FunctionComponent<{ routes?: unknown }> = ({
  routes = {},
}) => {
  return (
    <Display data-testid="breadcrumb-container" className="hide-print">
      <Breadcrumbs
        mappedRoutes={routes as TMappedRoutes}
        WrapperComponent={Breadcrumb}
      />
    </Display>
  );
};

export default AppBreadcrumb;
