// @ts-nocheck
import { FormikProps } from 'formik';
import { Radio, Text } from '@cvp/design-system/react';

import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { formatarData } from 'main/utils';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { levelRisk } from 'previdencia/utils/riskStatus';
import { IListarFundosParaResgateFundosDisponiveis } from 'previdencia/types/ConsultaListaFundosParaResgate';
import { TAlicotasAgrupadasFundosAliquotaFactory } from 'previdencia/types/AlicotasAgrupadas';
import ColunaDescricaoFundo from 'previdencia/features/SolicitacaoResgate/components/TabelaSimulacaoResgate/ColunaDescricaoFundo';
import ColunaSaldoTotal from 'previdencia/features/SolicitacaoResgate/components/TabelaSimulacaoResgate/ColunaSaldoTotal';
import ColunaTipoResgate from 'previdencia/features/SolicitacaoResgate/components/TabelaSimulacaoResgate/ColunaTipoResgate';
import ColunaValorRetirar from 'previdencia/features/SolicitacaoResgate/components/TabelaSimulacaoResgate/ColunaValorRetirar';
import ColunaObservacaoFundo from 'previdencia/features/SolicitacaoResgate/components/TabelaSolicitacaoResgate/ColunaObservacaoFundo';
import ColunaNomeBanco from 'previdencia/features/SolicitacaoResgate/components/TabelaInformacoesBancarias/ColunaNomeBanco';
import ColunaDescricaoFundoContribuicao from 'previdencia/features/SolicitacaoResgate/components/TabelaDefinirContribuicao/ColunaDescricaoFundoContribuicao';
import ColunaContribuicao from 'previdencia/features/SolicitacaoResgate/components/TabelaDefinirContribuicao/ColunaContribuicao';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as RESPONSE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateResponse';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from 'previdencia/features/SolicitacaoResgate/pages/styles';
import { IDataTableColumn } from 'react-data-table-component';

export const TIPOS_RESGATE = {
  PARCIAL: {
    id: 'P',
    value: 'Parcial',
  },
  TOTAL: {
    id: 'T',
    value: 'Total',
  },
};

export const TIPO_RESGATE_FUNDO = Object.values(TIPOS_RESGATE);

export const TIPO_RESGATE = [
  {
    id: 'parcial',
    value: 'Resgate Parcial',
  },
  {
    id: 'total',
    value: 'Resgate Total',
  },
];

export const ID_PARCIAL = TIPO_RESGATE[0].id;

export const ID_TOTAL = TIPO_RESGATE[1].id;

export const ID_SIGLA_PARCIAL = TIPO_RESGATE_FUNDO[0].id;

export const ID_SIGLA_TOTAL = TIPO_RESGATE_FUNDO[1].id;

export const VALOR_TOTAL = TIPO_RESGATE_FUNDO[1].value;

export const COLUNAS_SOLICITACAO_RESGATE = (omitirColunaMensagens: boolean) =>
  [
    {
      name: 'Fundos',
      selector: row => row.descricaoFundo,
      minWidth: '40%',
      wrap: true,
    },
    {
      name: 'Saldo Disponível',
      selector: row => row.saldoDisponivel,
      center: true,
      cell: (row: IListarFundosParaResgateFundosDisponiveis) =>
        tryGetMonetaryValueOrDefault(row.saldoDisponivel),
    },
    {
      name: 'Saldo Indisponível',
      selector: row => row.saldoBloqueado,
      center: true,
      cell: (row: IListarFundosParaResgateFundosDisponiveis) =>
        tryGetMonetaryValueOrDefault(row.saldoBloqueado),
    },
    {
      name: 'Observação Fundo',
      selector: row => row.mensagensFundo,
      minWidth: '30%',
      omit: omitirColunaMensagens,
      cell: (row: IListarFundosParaResgateFundosDisponiveis) => (
        <ColunaObservacaoFundo row={row} />
      ),
    },
  ] as IDataTableColumn<IListarFundosParaResgateFundosDisponiveis>[];

export const COLUNAS_ALIQUOTA = [
  {
    name: 'Fundos',
    selector: 'descricaoFundo',
    center: true,
    wrap: true,
    minWidth: '200px',
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) =>
      getTernaryResult<React.JSX.Element | string>(
        row.descricaoFundo !== VALOR_TOTAL,
        <Text variant="body02-md" align="center">
          {tryGetValueOrDefault([row.descricaoFundo], '-')}
        </Text>,
        tryGetValueOrDefault([row.descricaoFundo], '-'),
      ),
  },
  {
    name: 'Saldo Total',
    selector: 'saldoTotal',
    center: true,
    minWidth: '180px',
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) =>
      tryGetMonetaryValueOrDefault(row.saldoTotal),
  },
  {
    name: 'Alíquota IRPF (%)',
    selector: 'aliquota',
    center: true,
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) => {
      return getTernaryResult(
        row.descricaoFundo !== VALOR_TOTAL,
        tryGetValueOrDefault([row.aliquota], '-'),
        '',
      );
    },
  },
  {
    name: 'Saldo Disponível',
    selector: 'valorDisponivel',
    center: true,
    minWidth: '180px',
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) => {
      return getTernaryResult(
        row.descricaoFundo !== VALOR_TOTAL,
        tryGetMonetaryValueOrDefault(row.valorDisponivel),
        '',
      );
    },
  },
  {
    name: 'Saldo Indisponível',
    selector: 'valorIndisponivel',
    center: true,
    minWidth: '180px',
    cell: (row: TAlicotasAgrupadasFundosAliquotaFactory) => {
      return getTernaryResult(
        row.descricaoFundo !== VALOR_TOTAL,
        tryGetMonetaryValueOrDefault(row.valorIndisponivel),
        '',
      );
    },
  },
];

export const COLUNAS_FUNDOS_RESGATE = ({
  handleFundoSelecionado,
  handleTipoResgate,
  handleValorRetirar,
  setSelectedTableInputFocus,
  formik,
  selectedTableInputFocus,
  isTipoResgateTotal,
  isTipoResgateParcial,
  valorMinimoResgate,
}: SOLICITACAO_RESGATE_TYPES.IColunasFundoResgateProps) => [
  {
    name: 'Fundo',
    selector: 'descricaoFundo',
    wrap: true,
    minWidth: '375px',
    cell: (row: IListarFundosParaResgateFundosDisponiveis) => (
      <ColunaDescricaoFundo
        row={row}
        handleFundoSelecionado={handleFundoSelecionado}
        isTipoResgateTotal={isTipoResgateTotal}
      />
    ),
  },
  {
    name: 'Saldo Total',
    selector: 'saldoTotal',
    width: '275px',
    cell: (row: IListarFundosParaResgateFundosDisponiveis) => (
      <ColunaSaldoTotal row={row} isTipoResgateParcial={isTipoResgateParcial} />
    ),
  },
  {
    name: 'Tipo de Resgate',
    selector: 'tipoResgate',
    width: '180px',
    cell: (row: IListarFundosParaResgateFundosDisponiveis) => (
      <ColunaTipoResgate
        row={row}
        formik={formik}
        handleTipoResgate={handleTipoResgate}
        isTipoResgateTotal={isTipoResgateTotal}
      />
    ),
  },
  {
    name: 'Valor a Retirar',
    selector: 'valorRetirar',
    width: '250px',
    cell: (row: IListarFundosParaResgateFundosDisponiveis) => (
      <ColunaValorRetirar
        row={row}
        setSelectedTableInputFocus={setSelectedTableInputFocus}
        selectedTableInputFocus={selectedTableInputFocus}
        isTipoResgateParcial={isTipoResgateParcial}
        isTipoResgateTotal={isTipoResgateTotal}
        valorMinimoResgate={valorMinimoResgate}
        handleValorRetirar={handleValorRetirar}
      />
    ),
  },
];

export const COLUNAS_VALORES_DETALHADOS_ALIQUOTA = [
  {
    name: 'Data de contribuição',
    selector: 'data',
    minWidth: '140px',
    center: true,
    cell: (row: RESPONSE_TYPES.IConsultarDetalheCalculoLista) => {
      return getTernaryResult(
        row.data === VALOR_TOTAL,
        row.data,
        formatarData(row.data),
      );
    },
  },
  {
    name: 'Valor nominal',
    selector: 'saldoPrincipal',
    minWidth: '160px',
    cell: (row: RESPONSE_TYPES.IConsultarDetalheCalculoLista) =>
      tryGetMonetaryValueOrDefault(row.saldoPrincipal),
    center: true,
  },
  {
    name: 'Rendimento',
    selector: 'rendimento',
    minWidth: '160px',
    cell: (row: RESPONSE_TYPES.IConsultarDetalheCalculoLista) =>
      tryGetMonetaryValueOrDefault(row.rendimento),
    center: true,
  },
  {
    name: 'Valor de saída bruto',
    selector: 'valorSolicitado',
    minWidth: '160px',
    cell: (row: RESPONSE_TYPES.IConsultarDetalheCalculoLista) =>
      tryGetMonetaryValueOrDefault(row.valorSolicitado),
    center: true,
  },
  {
    name: 'Carregam. saída',
    selector: 'carregamentoSaida',
    cell: (row: RESPONSE_TYPES.IConsultarDetalheCalculoLista) =>
      tryGetMonetaryValueOrDefault(row.carregamentoSaida),
    center: true,
  },
  {
    name: 'Valor base IRRF',
    selector: 'baseIrrf',
    minWidth: '160px',
    cell: (row: RESPONSE_TYPES.IConsultarDetalheCalculoLista) =>
      tryGetMonetaryValueOrDefault(row.baseIrrf),
    center: true,
  },
  {
    name: 'Alíquota IRRF (%)',
    selector: 'aliquotaIrrf',
    center: true,
  },
  {
    name: 'Valor do IRRF',
    selector: 'valorIrrf',
    minWidth: '160px',
    cell: (row: RESPONSE_TYPES.IConsultarDetalheCalculoLista) =>
      tryGetMonetaryValueOrDefault(row.valorIrrf),
    center: true,
  },
  {
    name: 'Taxa de saída',
    selector: 'taxaSaida',
    cell: (row: RESPONSE_TYPES.IConsultarDetalheCalculoLista) =>
      tryGetMonetaryValueOrDefault(row.taxaSaida),
    center: true,
  },
  {
    name: 'Valor saída liquida',
    selector: 'valorLiquido',
    minWidth: '160px',
    cell: (row: RESPONSE_TYPES.IConsultarDetalheCalculoLista) =>
      tryGetMonetaryValueOrDefault(row.valorLiquido),
    center: true,
  },
];

export const MENSAGENS_ATENCAO_RESGATE = [
  '- A realização do resgate negativa sua meta e pode trazer prejuízo financeiro ao cliente em virtude do desconto de IR.',
  '- Informe ao seu cliente que diminuir os recursos acumulados prejudica sua aposentadoria.',
  '- Sugira, se possível, uma operação de crédito para preservar o seu resultado.',
  ' - Em caso de resgate total, havendo parcela em aberto em nome do titular do plano e ainda em procedimento de cobrança, o valor será devolvido ao cliente.',
  '- Os valores acima estão sujeitos a alteração. O valor líquido será creditado somente em conta de pessoa física que seja do titular do plano.',
  '- Haverá incidência de imposto de renda conforme modalidade do plano e regime tributário escolhido.',
  '- Antes de efetuar qualquer resgate favor validar o e-mail e telefone de contato, por meio de documento que garanta a veracidade dos dados informados.',
  '- Esse pedido será avaliado posteriormente e, sendo necessário, um contato será feito exclusivamente pela Caixa Previdência com o cliente. Na sua ausência será contatado o gerente da agência. É essencial para a continuidade desse procedimento atender ao contato da Caixa Previdência, bem como atualizar seus dados cadastrais antes do pedido.',
  '- Esse resgate poderá ser cancelado por motivos de segurança e divergências nas informações cadastrais e/ou bancárias. Nesse caso, um novo resgate deverá ser solicitado.',
  '- A opção pelo regime de tributação de alíquotas selecionada no certificado indicado nesta solicitação de resgate, previsto na IN RFB Nº 2.209 de 06 de agosto de 2024, torna-se irretratável.',
];

export const COLUNAS_INFO_BANCARIAS = (
  formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesEfetuarResgate>,
) =>
  [
    {
      name: 'Banco',
      selector: row => row.nomeBanco,
      minWidth: '300px',
      wrap: true,
      cell: (row: RESPONSE_TYPES.IConsultarTiposPagamentoTipo) => (
        <ColunaNomeBanco row={row} formik={formik} />
      ),
    },
    {
      name: 'Agência',
      selector: row => row.numeroAgencia,
      maxWidth: '100px',
      center: true,
      cell: (row: RESPONSE_TYPES.IConsultarTiposPagamentoTipo) => (
        <Text variant="body02-md" style={{ marginLeft: 12 }}>
          {tryGetValueOrDefault([row.numeroAgencia], '-')}
        </Text>
      ),
    },
    {
      name: 'Tipo da Conta',
      selector: row => row.tipoContaBancaria,
      minWidth: '400px',
      wrap: true,
      cell: (row: RESPONSE_TYPES.IConsultarTiposPagamentoTipo) => (
        <Text variant="body02-md" style={{ marginLeft: 12 }}>
          {UTILS.configurarTipoConta(row.codigoBanco, row.tipoContaBancaria)}
        </Text>
      ),
    },
    {
      name: 'Conta',
      selector: 'codigoContaBancaria',
      selector: row => row.codigoContaBancaria,
      minWidth: '280px',
      cell: (row: RESPONSE_TYPES.IConsultarTiposPagamentoTipo) => (
        <Text variant="body02-md" style={{ marginLeft: 12 }}>
          {UTILS.configurarCodigoContaBancaria(
            row.codigoContaBancaria,
            row.digitoConta,
          )}
        </Text>
      ),
    },
  ] as IDataTableColumn<RESPONSE_TYPES.IConsultarTiposPagamentoTipo>[];

export const COLUNAS_MOTIVO_RESGATE = ({
  handleMotivoSelecionado,
  formik,
}: SOLICITACAO_RESGATE_TYPES.IColunasMotivoResgateProps) => [
  {
    selector: row => row.codigo,
    name: 'Selecionar',
    center: true,
    maxWidth: '200px',
    cell: (row: RESPONSE_TYPES.IListarMotivosResgateResponse) => (
      <Radio
        name="selecionar-motivo-resgate"
        id={row.codigo}
        checked={row.codigo === formik.values.motivoResgate}
        onChange={() => {
          handleMotivoSelecionado(row.codigo);
        }}
      />
    ),
  },
  {
    name: 'Motivo',
    center: false,
    selector: (row: RESPONSE_TYPES.IListarMotivosResgateResponse) =>
      row.descricao,
  },
];

export const MENSAGEM_CERTIFICADO_PRAZO_DIFERIMENTO_CUSTOM = (
  redirect: () => void,
) => ({
  tipo: 'BLOQUEIO_CUSTOM',
  codigo: 'PRAZO_DIFERIMENTO',
  descricao: (
    <>
      Prazo de diferimento expirado. Para alterar selecione no menu a opção:
      Alterações &gt; Prazo Diferimento ou{' '}
      <S.ButtonObsCertificadoRedirect variant="text" onClick={redirect}>
        clique aqui.
      </S.ButtonObsCertificadoRedirect>
    </>
  ),
});

export const NO_DATA_TABLE = 'Não existem dados a serem exibidos.';

export const INITIAL_STATE_CONFIRMACAO_SOLICITACAO_RESGATE = {
  isSimulacaoPreenchida: true,
  isBtnConfirmacaoSimulacaoClicada: false,
};

export const MSG_VALIDACAO = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório',
  VALOR_MINIMO_NOME: 'Validar Valor mínimo solicitado',
  VALOR_MAXIMO_NOME: 'Validar Valor máximo solicitado',
};

export const DADOS_BANCO_CONTA = {
  NUM_BANCO_CAIXA: '104',
  QTD_CHAR_INPUT: { MAXIMA: '99', NSGD: '12', SIDEC: '8' },
  QTD_CHAR_TIPO_CONTA_OPERACAO: {
    NSGD: 4,
    SIDEC: 3,
  },
};

export const INITIAL_STATE_EFETUAR_RESGATE = {
  contaExistente: {
    nomeCanal: '',
    codigoBanco: '',
    codigoContaBancaria: '',
    digitoAgencia: '',
    digitoConta: '',
    tipoContaBancaria: '',
    descricaoTipoContaBancaria: '',
    nomeBanco: '',
    numeroAgencia: '',
    tipoCanal: '',
  },
  isNovaConta: false,
  novaConta: {
    banco: { value: '', label: '' },
    tipoConta: { codigo: '', descricao: '' },
    agencia: '',
    conta: '',
    digitoConta: '',
  },
  motivoResgate: '',
};

export const STATUS_RESGATE = {
  CANCELADO: 'CAN',
  EM_PREENCHIMENTO: 'PRE',
  VALIDADO: 'VAL',
  SOLICITADO: 'SOL',
  AGUARDANDO_ASSINATURA: 'ASE',
  PENDENTE: 'PEN',
};

export const INFORMATIVO_INICIO = (dataSolicitacao: string) => ({
  TEMPO_RESGATE: (
    <>
      Operação realizada com sucesso em {dataSolicitacao}. O resgate será pago
      em até 5 dias úteis.
    </>
  ),
  IMPRESSAO:
    'Imprima o comprovante em 3 vias. Uma das vias deverá ser enviada, obrigatoriamente, à CAIXA Vida e Previdência pelo malote laranja.',
  CONCLUSAO: 'Não foi possível concluir a solicitação de resgate.',
  SUCESSO: 'Solicitação de resgate recebida com sucesso.',
  EFETIVACAO:
    'A efetivação da transação ocorrerá somente após a conclusão do processo de assinatura eletrônica.',
});

export const INFORMATIVO_FINALIZAR = [
  ' Os valores acima estão sujeitos à alteração. O valor será creditado somente em conta pessoa física que seja titular do plano.',
  'Haverá incidência de imposto de renda conforme modalidade do plano e regime tributário escolhido.',
  'Em caso de resgate total, havendo parcela em aberto em nome do titular do plano e ainda em procedimento de cobrança, o valor será devolvido ao cliente.',
  'Por motivo de segurança, esta solicitação de resgate será avaliada. A  Caixa Vida e Previdência poderá entrar em contato com o cliente em até 48 horas para validação do pedido de resgate.',
  'A solicitação poderá ser cancelada em caso de ausência ou divergência na validação. Nesse caso será necessário fazer um novo pedido e aguardar o novo prazo para pagamento.',
  ' Caso existam parcelas em aberto e ainda em procedimento de cobrança, o valor será devolvido para a conta de origem.',
  <b>Atenção: o período de carência é de 60 dias.</b>,
];

export const TIPO_OPERACAO = {
  RESGATE: 'RESGATE',
};

export const CONTA_BANCARIA = {
  SIGLA_CONTA_CORRENTE: 'CC',
  SIGLA_CONTA_POUPANCA: 'CP',
  CODIGO_CONTA_CORRENTE: '001',
  CODIGO_CONTA_POUPANCA: '013',
  DIGITO_AGENCIA_PADRAO: '0',
};

export const MODAL_DETALHES_ALIQUOTA = {
  LABEL_TABELA: 'Entenda os detalhes de como são feitos os descontos:',
  AVISO_TITULO: 'IMPORTANTE',
  AVISO_1: 'Os valores acima são estimativas e estão sujeitos a alteração.',
  AVISO_2:
    'Em caso de dúvidas, entre em contato com nosso chat online ou pela Central de Serviços e Relacionamento pelo número: 0800 702 4000.',
};

export const COLUNAS_DEFINIR_CONTRIBUICAO = ({
  handleFundoSelecionado,
  selectedTableInputFocus,
  setSelectedTableInputFocus,
  handleValorContribuicao,
}: SOLICITACAO_RESGATE_TYPES.IColunasDefinirContribuicaoProps) => [
  {
    name: 'Fundo',
    selector: 'descricaoFundo',
    wrap: true,
    minWidth: '450px',
    cell: (row: RESPONSE_TYPES.IConsultarContribuicaoRegularFundos) => (
      <ColunaDescricaoFundoContribuicao
        row={row}
        handleFundoSelecionado={handleFundoSelecionado}
      />
    ),
  },
  {
    name: 'Perfil de risco',
    selector: 'perfilFundo',
    center: true,
    width: '150px',
    cell: (row: RESPONSE_TYPES.IConsultarContribuicaoRegularFundos) => (
      <S.CustomRiskBarStyle risk={levelRisk(row.perfilFundo)} />
    ),
  },
  {
    name: 'Rentabilidade (últimos 12 meses)',
    selector: 'rentabilidade',
    center: true,
    cell: (row: RESPONSE_TYPES.IConsultarContribuicaoRegularFundos) => (
      <Text variant="body02-md">{row.rentabilidade}%</Text>
    ),
  },
  {
    name: 'Contribuição mensal',
    selector: 'contribuicao',
    center: true,
    cell: (row: RESPONSE_TYPES.IConsultarContribuicaoRegularFundos) => (
      <ColunaContribuicao
        row={row}
        selectedTableInputFocus={selectedTableInputFocus}
        setSelectedTableInputFocus={setSelectedTableInputFocus}
        handleValorContribuicao={handleValorContribuicao}
      />
    ),
  },
];

export const VALIDACAO_CONTA_CAIXA = {
  CODIGO_VALIDO: '00',
  MSG_TEMPORARIA_ERRO:
    'Não foi possível validar a conta bancária, verifique se o cliente é o titular da conta ou se a conta existe.',
};

export const VALIDACAO_DEFINICAO_CONTRIBUICAO_REGULAR = {
  MSG_TEMPORARIA_ERRO:
    'Não foi possível definir a contribuição regular para este fundo.',
};

export const TIPOS_CANAL_PAGAMENTO = {
  RESGATE_DOC: '23',
  RESGATE_TED: '24',
  GUIA_PAGAMENTO_BANCARIO: '31',
  RESGATE_TRANSFERENCIA: '9',
};

export const DESCRICAO_SELECAO_ALIQUOTA = {
  SEM_OPCAO_SELECAO: 'Confira os valores da simulação.',
  COM_OPCAO_SELECAO:
    'Confira os valores da simulação e selecione a alíquota que o cliente deseja.',
};

export const RETORNO_SUCESSO_RESGATE =
  'Solicitação de resgate recebida com sucesso.';

export const conditionalRowStyles = [
  {
    when: (row: { data: string; descricaoFundo: string }) =>
      tryGetValueOrDefault([row.data, row.descricaoFundo], '') === VALOR_TOTAL,
    style: {
      backgroundColor: '#005FC8',
      color: 'white',
    },
  },
];

export const TEXTOS_FORM_NOVA_CONTA_BANCARIA = {
  INDICAR_NOVA_CONTA: 'Clique aqui para indicar outra conta',
  AVISO_PARAGRAFO_1: 'Indicar conta de titularidade do cliente (1° titular).',
  AVISO_PARAGRAFO_2:
    '- Para clientes com conta na CAIXA informe número de conta corrente ou poupança, no caso de pessoa física.',
  AVISO_PARAGRAFO_3:
    '- Para clientes de outros bancos informar conta corrente ou poupança. Não está disponível resgate com crédito em conta de pessoas jurídicas.',
};

export const TEXTOS_MODAL_PENDENCIA_RESGATE = {
  MSG_PENDENCIA: 'Não foi possível concluir a solicitação de resgate.',
};

export const TEXTOS_OBSERVACAO_CERTIFICADO = {
  TITULO_OBS: 'Observações Certificado:',
};

export const TEXTOS_SALDO_TOTAL_CERTIFICADO = {
  LABEL: (
    <>
      <strong>Saldo Total</strong> em {formatarData(new Date())}:
    </>
  ),
};

export const TEXTOS_COLUNA_SALDO_TOTAL = {
  SALDO_MINIMO: 'Saldo mínimo a ser mantido no fundo',
};

export const TEXTOS_INFO_BANCARIAS = {
  TITULO: 'Informações bancárias',
  DESCRICAO: 'Selecione uma das contas a seguir para que o crédito seja feito:',
};

export const TEXTOS_DETALHES_ALIQUOTA = {
  TITULO: 'Detalhes por Alíquota',
};
