import { usePeco } from 'main/hooks/usePeco';
import { openBase64FileBrowser } from 'main/utils/converterBase64';
import { PECOS_ADIMPLENCIA } from 'painelInadimplencia/config/endpoints';
import { IArquivoBoletoSegurosPayload } from 'painelInadimplencia/types/PainelInadimplencia';

const usePecoObterArquivoBoletoSeguros = () => {
  const {
    loading: isLoadingArquivoBoletoSeguros,
    response: dadosArquivoBoletoSeguros,
    fetchData: obterArquivoBoletoSeguros,
  } = usePeco<Partial<IArquivoBoletoSegurosPayload>, Blob>({
    api: {
      operationPath: PECOS_ADIMPLENCIA.ObterArquivoBoletoPagamentoSeguros,
    },
  });

  const obterSegundaViaBoletoVida = async (
    numeroCertificado: string,
    parcela: string,
  ): Promise<void> => {
    const arquivoBoleto = await obterArquivoBoletoSeguros({
      numeroCertificado,
      parcela,
    });

    if (arquivoBoleto?.entidade) {
      return openBase64FileBrowser({ blob: arquivoBoleto.entidade });
    }

    return undefined;
  };

  return {
    dadosArquivoBoletoSeguros: dadosArquivoBoletoSeguros?.entidade,
    isLoadingArquivoBoletoSeguros,
    obterSegundaViaBoletoVida,
  };
};

export default usePecoObterArquivoBoletoSeguros;
