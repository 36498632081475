import { Text } from '@cvp/design-system/react';

import { Button } from 'main/components/Header/styles';
import Icon from 'main/components/Icon';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { ordenaValorMonetario, ordenaValor } from 'main/utils/ordenar';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { DadosCliente } from 'painelInadimplencia/types/ConsultarInadimplenciaResponse';
import {
  IColunasDetalhamentoParcelasReturn,
  IPagamentosAdimplenciaResponse,
} from 'painelInadimplencia/types/PainelInadimplencia';
import {
  formatarValorDecimal,
  formatarValorInadimplencia,
} from 'painelInadimplencia/utils/PainelInadimplencia';

export const buildColumnsAgencia = (
  handleDetalhamentoParcelas: (value: DadosCliente) => void,
  isLoadingListaPagamentosAdimplencia: boolean,
  handleToggleModalPagamento: () => void,
  handleToggleModalDetalhesAdimplencia: () => void,
) => [
  {
    name: 'Certificado',
    selector: 'contrato',
    minWidth: '200px',
  },
  {
    name: 'Qtd. Clientes',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeClientes'),
    width: '120px',
    sortable: true,
    center: true,
    sortFunction: ordenaValor('quantidadeClientes'),
  },
  {
    name: 'Qtd. Certificados',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeInadimplencia'),
    width: '120px',
    sortable: true,
    center: true,
    sortFunction: ordenaValor('quantidadeInadimplencia'),
  },
  {
    name: 'Qtd. Parcelas',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'parcelasInadimples'),
    width: '120px',
    sortable: true,
    center: true,
    sortFunction: ordenaValor('parcelasInadimples'),
  },
  {
    name: 'Total',
    selector: formatarValorInadimplencia(),
    minWidth: '150px',
    sortable: true,
    center: true,
    sortFunction: ordenaValorMonetario('totalInadimplencia'),
  },
  {
    minWidth: '150px',
    cell: (row: DadosCliente) => {
      return (
        <Button
          onClick={() => {
            handleDetalhamentoParcelas(row);
            handleToggleModalDetalhesAdimplencia();
          }}
          loading={isLoadingListaPagamentosAdimplencia}
          disabled={isLoadingListaPagamentosAdimplencia}
          variant="primary"
          small
        >
          Detalhes
        </Button>
      );
    },
  },
  {
    minWidth: '150px',
    cell: (row: DadosCliente) => {
      return (
        <Button
          onClick={() => {
            handleDetalhamentoParcelas(row);
            handleToggleModalPagamento();
          }}
          loading={isLoadingListaPagamentosAdimplencia}
          disabled={isLoadingListaPagamentosAdimplencia}
          variant="secondary"
          small
        >
          Pagar
        </Button>
      );
    },
  },
];

export const buildColumnsHierarquiaSEV = (
  onclick: (unidade: string) => void,
  tipoFilhos?: string,
) => [
  {
    name: `Unidade ${tryGetValueOrDefault([tipoFilhos], '')}`,
    selector: 'codigoAgenciaHierarquia',
    cell: (row: DadosCliente) => {
      return (
        <Button
          onClick={() => {
            if (row.codigoAgenciaHierarquia) {
              onclick(row.codigoAgenciaHierarquia);
            }
          }}
          variant="text"
          style={{ minWidth: '50px', padding: '0px' }}
        >
          <span
            style={{
              display: 'flex',
            }}
          >
            {row.codigoAgenciaHierarquia}
            <Icon name="arrowRight" />
            <Text variant="caption-01" color="primary">
              {row.nomeAgenciaHierarquia}
            </Text>
          </span>
        </Button>
      );
    },
    sortable: true,
    sortFunction: ordenaValor('codigoAgenciaHierarquia'),
    minWidth: '350px',
  },
  {
    sortFunction: ordenaValor('quantidadeClientes'),
    minWidth: '180px',
    sortable: true,
    name: 'Qtd. Clientes',
    center: true,
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeClientes'),
  },
  {
    minWidth: '180px',
    sortFunction: ordenaValor('quantidadeInadimplencia'),
    sortable: true,
    center: true,
    name: 'Qtd. Certificados',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeInadimplencia'),
  },
  {
    minWidth: '100px',
    sortable: true,
    center: true,
    sortFunction: ordenaValor('parcelasInadimples'),
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'parcelasInadimples'),
    name: 'Qtd. Parcelas',
  },
  {
    selector: formatarValorInadimplencia(),
    minWidth: '150px',
    sortable: true,
    sortFunction: ordenaValorMonetario('valorInadimplencia'),
    name: 'Total',
    center: true,
  },
];

export const buildColumnsHierarquiaSN = (
  onclick: (unidade: string) => void,
  tipoFilhos?: string,
) => [
  {
    name: `Unidade ${tryGetValueOrDefault([tipoFilhos], '')}`,
    sortable: true,
    selector: 'codigoSrHierarquia',
    cell: (row: DadosCliente) => {
      return (
        <Button
          onClick={() => {
            if (row.codigoSrHierarquia) {
              onclick(row.codigoSrHierarquia);
            }
          }}
          variant="text"
          style={{ minWidth: '50px', padding: '0px' }}
        >
          <span
            style={{
              display: 'flex',
            }}
          >
            {row.codigoSrHierarquia}
            <Icon name="arrowRight" />
            <Text variant="caption-01" color="primary">
              {row.nomeSrHierarquia}
            </Text>
          </span>
        </Button>
      );
    },
    sortFunction: ordenaValor('codigoSrHierarquia'),
    minWidth: '350px',
  },
  {
    name: 'Qtd. Clientes',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeClientes'),
    minWidth: '180px',
    sortFunction: ordenaValor('quantidadeClientes'),
    sortable: true,
    center: true,
  },
  {
    name: 'Qtd. Certificados',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeInadimplencia'),
    minWidth: '180px',
    sortFunction: ordenaValor('quantidadeInadimplencia'),
    sortable: true,
    center: true,
  },
  {
    name: 'Qtd. Parcelas',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'parcelasInadimples'),
    minWidth: '100px',
    sortFunction: ordenaValor('parcelasInadimples'),
    sortable: true,
    center: true,
  },
  {
    name: 'Total',
    selector: formatarValorInadimplencia(),
    minWidth: '150px',
    sortFunction: ordenaValorMonetario('valorInadimplencia'),
    sortable: true,
    center: true,
  },
];

export const buildColumnsHierarquiaSR = (
  onclick: (unidade: string) => void,
  tipoFilhos?: string,
) => [
  {
    name: `Unidade ${tryGetValueOrDefault([tipoFilhos], '')}`,
    sortFunction: ordenaValor('codigoSev'),
    selector: 'codigoSev',
    sortable: true,
    minWidth: '350px',
    cell: (row: DadosCliente) => {
      return (
        <Button
          onClick={() => {
            if (row.codigoSev) {
              onclick(row.codigoSev);
            }
          }}
          variant="text"
          style={{ minWidth: '50px', padding: '0px' }}
        >
          <span
            style={{
              display: 'flex',
            }}
          >
            {row.codigoSev}
            <Icon name="arrowRight" />
            <Text variant="caption-01" color="primary">
              {row.nomeSev}
            </Text>
          </span>
        </Button>
      );
    },
  },
  {
    name: 'Qtd. Clientes',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeClientes'),
    sortable: true,
    center: true,
    minWidth: '180px',
    sortFunction: ordenaValor('quantidadeClientes'),
  },
  {
    name: 'Qtd. Certificados',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeInadimplencia'),
    sortable: true,
    center: true,
    minWidth: '180px',
    sortFunction: ordenaValor('quantidadeInadimplencia'),
  },
  {
    name: 'Qtd. Parcelas',
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'parcelasInadimples'),
    sortable: true,
    center: true,
    minWidth: '100px',
    sortFunction: ordenaValor('parcelasInadimples'),
  },
  {
    name: 'Total',
    selector: formatarValorInadimplencia(),
    sortable: true,
    center: true,
    minWidth: '150px',
    sortFunction: ordenaValorMonetario('valorInadimplencia'),
  },
];

export const buildColumnsHierarquiaVP = (
  onclick: (unidade: string) => void,
  tipoFilhos?: string,
) => [
  {
    selector: 'codigoSnHierarquia',
    name: `Unidade ${tryGetValueOrDefault([tipoFilhos], '')}`,
    sortable: true,
    minWidth: '350px',
    sortFunction: ordenaValor('codigoSnHierarquia'),
    cell: (row: DadosCliente) => {
      return (
        <Button
          onClick={() => {
            if (row.codigoSnHierarquia) {
              onclick(row.codigoSnHierarquia);
            }
          }}
          variant="text"
          style={{
            minWidth: '50px',
            padding: '0px',
          }}
        >
          <span
            style={{
              display: 'flex',
            }}
          >
            {row.codigoSnHierarquia}
            <Icon name="arrowRight" />
            <Text variant="caption-01" color="primary">
              {row.nomeSnHierarquia}
            </Text>
          </span>
        </Button>
      );
    },
  },
  {
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeClientes'),
    name: 'Qtd. Clientes',
    minWidth: '180px',
    center: true,
    sortable: true,
    sortFunction: ordenaValor('quantidadeClientes'),
  },
  {
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'quantidadeInadimplencia'),
    name: 'Qtd. Certificados',
    minWidth: '180px',
    center: true,
    sortable: true,
    sortFunction: ordenaValor('quantidadeInadimplencia'),
  },
  {
    selector: (row: DadosCliente) =>
      formatarValorDecimal(row, 'parcelasInadimples'),
    name: 'Qtd. Parcelas',
    minWidth: '180px',
    center: true,
    sortable: true,
    sortFunction: ordenaValor('parcelasInadimples'),
  },
  {
    name: 'Total',
    selector: formatarValorInadimplencia(),
    minWidth: '220px',
    sortFunction: ordenaValorMonetario('valorInadimplencia'),
    sortable: true,
  },
];

export const colunasDetalhamentoParcelas = (
  gerarBoletoPagamento: (row: IPagamentosAdimplenciaResponse) => Promise<void>,
  isLoadingGeracaoBoleto: boolean,
): IColunasDetalhamentoParcelasReturn[] => [
  {
    name: 'Certificado',
    selector: 'numeroContrato',
    minWidth: '150px',
    center: true,
  },
  {
    name: 'Parcela Individual',
    selector: 'numeroParcela',
    minWidth: '150px',
    center: true,
  },
  {
    name: 'Valor Unitário',
    selector: 'valorProposta',
    minWidth: '150px',
    center: true,
    cell: (row: IPagamentosAdimplenciaResponse) =>
      tryGetMonetaryValueOrDefault(row.valorProposta),
  },
  {
    name: 'Gerar Boleto',
    selector: 'gerarBoleto',
    minWidth: '150px',
    center: true,
    cell: (row: IPagamentosAdimplenciaResponse) => (
      <Button
        onClick={() => gerarBoletoPagamento(row)}
        loading={isLoadingGeracaoBoleto}
        disabled={isLoadingGeracaoBoleto}
        variant="primary"
        small
      >
        Boleto
      </Button>
    ),
  },
];

export const TiposVisao = {
  VP: 'VP',
  SN: 'SN',
  SR: 'SR',
  SEV: 'SEV',
  AG: 'AG',
};

export const PREV = 'PREVIDENCIA';
export const VIDA = 'VIDA';

export const FormaPagamentoFilterPREV = [
  {
    value: 'Debito em Conta',
    key: 'FormaDePagamento',
  },
  {
    value: 'Boleto',
    key: 'FormaDePagamento',
  },
];

export const FormaPagamentoFilterVIDA = [
  {
    value: 'Debito em Conta',
    key: 'FormaDePagamento',
  },
  {
    value: 'Cartão de Crédito',
    key: 'FormaDePagamento',
  },
  {
    value: 'Boleto',
    key: 'FormaDePagamento',
  },
];

export const OPCAO_PAGAMENTO = {
  PAGAMENTO_ANUAL: {
    label: 'Pagamento Anual',
    value: 'PA',
    key: 'PU',
  },
  PAGAMENTO_MENSAL: {
    label: 'Pagamento Mensal',
    value: 'PM',
    key: 'PM',
  },
  PAGAMENTO_UNICO: {
    label: 'Pagamento Unico',
    value: 'PU',
    key: 'PU',
  },
  PAGAMENTO_TRIMESTRAL: {
    label: 'Pagamento Trimestral',
    value: 'PT',
    key: 'PT',
  },
  PAGAMENTO_SEMESTRAL: {
    label: 'Pagamento Semestral',
    value: 'PS',
    key: 'PS',
  },
};

export const PeriodicidadePagamentoFilter = Object.values(OPCAO_PAGAMENTO);

export const SEGMENTO = {
  VIDA: 'vida',
  PREV: 'previdencia',
};

export const TIPOS_FORMA_PAGAMENTO = {
  BOLETO: 'Boleto',
  CREDITO: 'Cartão de Crédito',
  DEBITO: 'Debito em Conta',
};

export const SISTEMA_CHAMADOR = 'PECO';
