// @ts-nocheck
import { IDataTableColumn } from 'react-data-table-component';

export const COLUNAS_TABELA_COBERTURAS: IDataTableColumn[] = [
  {
    name: 'Código da Sigla da Cobertura',
    selector: row => row.codigoSiglaCobertura,
    sortable: false,
  },
];

export const SEM_DADOS_COBERTURAS =
  'Não há dados de cobertura a serem exibidos.';
