import { usePeco } from 'main/hooks/usePeco';
import { IBuscarDPSPayload } from 'dps/features/pendentesDps/types/IBuscarDPSPayload';
import { IBuscarClientesDPSResponse } from 'dps/features/pendentesDps/types/IBuscarClientesDPSResponse';
import { PECOS_PAINELGESTAO } from 'main/features/HierarquiaAgencia/config/endpoints';
import { getTimer15Minutos } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const useBuscarClientesDPS = (codigo: string) => {
  const { fetchData, response, loading } = usePeco<
    IBuscarDPSPayload,
    IBuscarClientesDPSResponse
  >({
    api: { operationPath: PECOS_PAINELGESTAO.BuscarCliente },
    payload: { codigo },
    autoFetch: true,
    cache: true,
    cacheKey: `${tryGetValueOrDefault([codigo], '')}`,
    cacheTime: getTimer15Minutos(),
    handleResponse: { throwToastErrorBFF: false },
  });
  return {
    fetchData,
    response: response?.entidade,
    loading,
  };
};
