import { useEffect } from 'react';
import useObterComprovante from 'previdencia/hooks/Transferencia/useObterComprovante';
import { TIPO_ASSINATURA } from 'previdencia/constants/constants';
import { getBlobUrl } from 'main/utils/blob';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

const useComprovante = () => {
  const { loading, response, fetchData, ...rest } = useObterComprovante(
    TIPO_ASSINATURA.TRANSFERENCIA_ADESAO,
  );

  useEffect(() => {
    if (checkIfAllItemsAreTrue([!!response, !!response?.comprovante])) {
      getBlobUrl(response?.comprovante);
    }
  }, [response]);

  return {
    fetchData,
    loading,
    response,
    ...rest,
  };
};

export default useComprovante;
