// @ts-nocheck
import { Display, Card, Text, Button } from '@cvp/design-system/react';

import Icon from 'main/components/Icon';
import { DEFAULT_NO_DATA_TABLE } from 'main/constants/messages';
import {
  tryGetValueOrDefault,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import useDistribuicaoEntreFundos from 'previdencia/features/TransferenciaEntreCertificados/hooks/useDistribuicaoEntreFundos';
import CardResumoDistribuicao from 'previdencia/features/TransferenciaEntreCertificados/components/CardResumoDistribuicao';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import * as S from './styles';
import TransferenciaWrapper from '../components/TransferenciaWrapper/TransferenciaWrapper';
import RenderConditional from 'main/components/RenderConditional';
import { Skeleton as TableSkeleton } from 'main/components/Table';
import ModalAvisoRevalidar from '../components/ModalAvisoRevalidar';
import ModalPendencias from '../components/ModalPendencias/ModalPendencias';

const DistribuicaoEntreFundos = () => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  const {
    isLoading,
    textosDistribuicao,
    modalErro,
    handleCloseModalErro,
    modalAlerta,
    handleCloseModalAlerta,
    pendenciasRevalidar,
    erroDefinirReservaDestino,
    formik,
    colunasFundos,
    dadosResumoFundosSelecionados,
    isNotAvailableNextStepDistribuicaoFundos,
    avancarEtapa,
  } = useDistribuicaoEntreFundos();

  return (
    <TransferenciaWrapper>
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Text variant="body01-sm" margin>
            {textosDistribuicao.DESCRICAO}
          </Text>

          <Text color="primary" margin>
            {textosDistribuicao.LABEL_TOTAL_TRANSFERENCIA}{' '}
            {tryGetMonetaryValueOrDefault(
              featureData?.fundosDestinoDisponiveis?.valorRetirada,
            )}
            .
          </Text>

          <Text variant="body01-sm" margin>
            Você selecionou{' '}
            <b>{dadosResumoFundosSelecionados.qtdFundosSelecionados}</b> de{' '}
            <b>{dadosResumoFundosSelecionados.qtdFundosPossiveis}</b> fundos
            possíveis.
          </Text>

          <Text variant="body01-sm" color="error">
            <Icon name="warning" /> {textosDistribuicao.MSG_AVISO}
          </Text>
        </Card.Content>
      </Card>

      <RenderConditional condition={!isLoading}>
        <Display style={{ margin: '30px 0px' }}>
          <S.TabelaDistribuicaoFundos
            noHeader
            responsive
            highlightOnHover
            striped
            data={tryGetValueOrDefault(
              [formik.values.fundosDestino?.fundosDestinoConsolidado],
              [],
            )}
            columns={colunasFundos}
            noDataComponent={DEFAULT_NO_DATA_TABLE}
          />
        </Display>
        <CardResumoDistribuicao formik={formik} />
      </RenderConditional>
      <RenderConditional condition={isLoading}>
        <div style={{ margin: '30px 10px' }}>
          <TableSkeleton colunas={colunasFundos} data-testid="tableSkeleton" />
        </div>
      </RenderConditional>

      <Display>
        <Button
          disabled={checkIfSomeItemsAreTrue([
            isNotAvailableNextStepDistribuicaoFundos,
            isLoading,
          ])}
          loading={isLoading}
          onClick={avancarEtapa}
        >
          Avançar
        </Button>
      </Display>
      <ModalPendencias
        openModal={modalErro}
        handleCloseModal={handleCloseModalErro}
        pendenciasRevalidar={pendenciasRevalidar}
        erroDefinirReservaDestino={erroDefinirReservaDestino}
      />
      <ModalAvisoRevalidar
        openModal={modalAlerta}
        handleCloseModal={handleCloseModalAlerta}
        pendenciasRevalidar={pendenciasRevalidar}
      />
    </TransferenciaWrapper>
  );
};

export default DistribuicaoEntreFundos;
