import For from 'main/components/For';
import React from 'react';
import { useLocation } from 'react-router';
import { IBreadcrumbsProps, TMapRouteFn } from './Breadcrumb.types';
import { getPaths, isDefined } from './Breadcrumb.utils';
import BreadcrumbsItem from './BreadcrumbItem';
import { BreadcrumbActiveLink, BreadcrumbBaseLink } from './BreadcrumbLink';

const BreadcrumbList: React.FC<IBreadcrumbsProps> = ({
  mappedRoutes: routesMapped,
  WrapperComponent,
  rootName,
}) => {
  const location = useLocation();
  const path = location.pathname;
  const mappedRoutes = routesMapped;

  const mapRoute = (url: string, match: unknown) =>
    typeof rootName === 'function' ? rootName(path, match) : rootName;

  if (isDefined(rootName)) {
    mappedRoutes['/'] = mapRoute as TMapRouteFn;
  }

  const { paths } = getPaths(path, rootName as string);

  return (
    <WrapperComponent>
      <For each={paths}>
        {(itemPath, index) => (
          <BreadcrumbsItem
            key={itemPath.name}
            parentProps={{
              LinkComponent: BreadcrumbBaseLink,
              ActiveLinkComponent: BreadcrumbActiveLink,
            }}
            matcher={{
              isExact: index === paths.length - 1,
              url: itemPath.path,
            }}
            mappedRoutes={mappedRoutes}
            name={itemPath.name}
          />
        )}
      </For>
    </WrapperComponent>
  );
};

export default BreadcrumbList;
