import { usePeco } from 'main/hooks/usePeco';
import { PECOS_ADIMPLENCIA } from 'painelInadimplencia/config/endpoints';
import {
  IPagamentosAdimplenciaPayload,
  IPagamentosAdimplenciaResponse,
} from 'painelInadimplencia/types/PainelInadimplencia';

const usePecoPagamentosAdimplencia = () => {
  const {
    loading: isLoadingListaPagamentosAdimplencia,
    response: ListaPagamentosAdimplencia,
    fetchData: obterListaPagamentosAdimplencia,
  } = usePeco<
    Partial<IPagamentosAdimplenciaPayload>,
    IPagamentosAdimplenciaResponse[] | undefined
  >({
    api: { operationPath: PECOS_ADIMPLENCIA.ListarPagamentosAdimplencia },
  });

  return {
    ListaPagamentosAdimplencia: ListaPagamentosAdimplencia?.entidade,
    isLoadingListaPagamentosAdimplencia,
    obterListaPagamentosAdimplencia,
  };
};

export default usePecoPagamentosAdimplencia;
