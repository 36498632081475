import { getTernaryResult } from 'main/utils/conditional';
import styled from 'styled-components';

type TDropdownMenuProps = {
  $hidden?: boolean;
  $fullWidth?: boolean;
};

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownContainerMenu = styled.div<TDropdownMenuProps>`
  position: absolute;
  top: 100%;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #2d2f31;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  display: ${props => getTernaryResult(!props.$hidden, 'block', 'none')};
  left: 0;
  min-width: 100%;
`;

export const DropdownItem = styled.div`
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #2d2f31;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover {
    color: rgb(33, 37, 41);
    text-decoration: none;
    background-color: rgb(240, 240, 240);
  }
`;
