// @ts-nocheck
import { Card, Display } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import useRelatorioInadimplencia from 'painelInadimplencia/hooks/useRelatorioInadimplencia';
import RelatorioInadimplenciaAgencia from 'painelInadimplencia/pages/RelatorioAgencia';
import RelatorioInadimplenciaHR from 'painelInadimplencia/pages/RelatorioHierarquia';
import HeaderPainel from 'painelInadimplencia/components/HeaderPainel';
import Filtros from 'painelInadimplencia/components/Filtros';
import * as CONSTS from 'painelInadimplencia/constants/constants';

const RelatorioInadimplencia = () => {
  const {
    tipoVisao,
    unidade,
    setPayloadConsulta,
    consultarInadimplencia,
    payloadConsulta,
    filterFormaPagamento,
    loading,
    handleConsulta,
    dadosInadimplencia,
    handleBackStep,
    clearFilters,
    isDisabledBotaoVoltar,
    dadosHierarquiaAgencia,
  } = useRelatorioInadimplencia();

  return (
    <>
      <HeaderPainel
        tipoVisao={tipoVisao}
        unidade={unidade}
        dadosHierarquiaAgencia={dadosHierarquiaAgencia}
      />

      <Display type="display-block">
        <Card>
          <Card.Content>
            <Filtros
              tipoVisao={tipoVisao}
              payloadConsulta={payloadConsulta}
              setPayloadConsulta={setPayloadConsulta}
              consultarInadimplencia={consultarInadimplencia}
              filterFormaPagamento={filterFormaPagamento}
              handleBackStep={handleBackStep}
              clearFilters={clearFilters}
              isDisabledBotaoVoltar={isDisabledBotaoVoltar}
            />

            <RenderConditional condition={loading}>
              <SkeletonLoading lines={8} />
            </RenderConditional>

            <RenderConditional condition={!loading}>
              <RenderConditional condition={tipoVisao !== CONSTS.TiposVisao.AG}>
                <RelatorioInadimplenciaHR
                  setpayloadUnidade={setPayloadConsulta}
                  handleConsultar={handleConsulta}
                  loading={loading}
                  data={dadosInadimplencia}
                />
              </RenderConditional>

              <RenderConditional condition={tipoVisao === CONSTS.TiposVisao.AG}>
                <RelatorioInadimplenciaAgencia
                  handleConsultar={handleConsulta}
                  loading={loading}
                  data={dadosInadimplencia}
                />
              </RenderConditional>
            </RenderConditional>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};

export default RelatorioInadimplencia;
