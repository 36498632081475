// @ts-nocheck

import { useState } from 'react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IPendencias } from 'previdencia/types/IRevalidarTransferencia';
import { useRevalidarTransferencia } from 'previdencia/hooks/Transferencia/useRevalidarTransferencia';
import * as UTILS from 'previdencia/features/TransferenciaEntreCertificados/utils/TransferenciaEntreCertificados';

const useRevalidarPendenciasTransferencia = () => {
  const [pendenciasRevalidar, setPendenciasRevalidar] =
    useState<IPendencias[]>();
  const [modalErro, setModalErro] = useState(false);
  const [modalAlerta, setModalAlerta] = useState(false);
  const {
    response: dataRevalidarTransferencia,
    loading: loadingRevalidarTransferencia,
    fetchData: revalidarTransferencia,
  } = useRevalidarTransferencia();

  const handleCloseModalAlerta = () => {
    setModalAlerta(false);
  };

  const handleCloseModalErro = () => {
    setModalErro(false);
  };

  const pendenciasRevalidarTransferencia = tryGetValueOrDefault(
    [dataRevalidarTransferencia?.pendenciasRevalidarTransferencia],
    [],
  );
  const alertasRevalidar = UTILS.obterAlertasRevalidar(
    pendenciasRevalidarTransferencia,
  );

  const errosRevalidar = UTILS.obterErros(pendenciasRevalidarTransferencia);

  return {
    dataRevalidarTransferencia,
    pendenciasRevalidarTransferencia,
    loadingRevalidarTransferencia,
    revalidarTransferencia,
    alertasRevalidar,
    errosRevalidar,
    pendenciasRevalidar,
    modalErro,
    setModalErro,
    setModalAlerta,
    modalAlerta,
    setPendenciasRevalidar,
    handleCloseModalAlerta,
    handleCloseModalErro,
  };
};

export default useRevalidarPendenciasTransferencia;
