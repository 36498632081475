import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  formatDecimalValue,
  tryGetMonetaryValueOrDefault,
} from 'main/utils/money';
import * as CONSTS from 'painelInadimplencia/constants/constants';
import {
  IGerarBoletoPrevidenciaParams,
  IGerarBoletoVida,
} from 'painelInadimplencia/types/PainelInadimplencia';
import { PropsTable } from 'painelInadimplencia/types/ConsultarInadimplenciaConstants';
import { DadosCliente } from 'painelInadimplencia/types/ConsultarInadimplenciaResponse';

/**
 * Gera o boleto para o segmento "Previdência".
 *
 * @async
 * @function
 * @param {IGerarBoletoPrevidenciaParams} params - Parâmetros necessários para gerar o boleto de previdência.
 * @param {IPagamentosAdimplenciaResponse} params.row - Dados da linha selecionada na tabela.
 * @param {DadosCliente | undefined} params.detalhesAdimplenciaAgencia - Detalhes do cliente associado ao boleto.
 * @param {Function} params.validarReemissaoBoleto - Função que valida a possibilidade de reemissão do boleto.
 * @param {Function} params.obterSegundaVia - Função que realiza a obtenção da segunda via do boleto.
 * @returns {Promise<void>} - Retorna uma `Promise` resolvida após a execução bem-sucedida.
 */
export const gerarBoletoPrevidencia = async ({
  row,
  detalhesAdimplenciaAgencia,
  validarReemissaoBoleto,
  obterSegundaVia,
  handleToggleModalValidaEmissaoBoleto,
}: IGerarBoletoPrevidenciaParams) => {
  const retornoReemissaoBoleto = await validarReemissaoBoleto({
    numeroCobranca: row.numeroContrato.toString(),
  });

  const reemitidoComSucesso: boolean = checkIfAllItemsAreTrue([
    !!retornoReemissaoBoleto?.sucessoBFF,
    !!retornoReemissaoBoleto?.sucessoGI,
  ]);

  if (reemitidoComSucesso) {
    await obterSegundaVia(
      row.numeroContrato.toString(),
      tryGetValueOrDefault([detalhesAdimplenciaAgencia?.cpf?.toString()], ''),
    );
  } else {
    handleToggleModalValidaEmissaoBoleto();
  }
};

/**
 * Gera o boleto para o segmento "Vida".
 *
 * @async
 * @function
 * @param {IGerarBoletoVida} params - Parâmetros necessários para gerar o boleto de vida.
 * @param {IPagamentosAdimplenciaResponse} params.row - Dados da linha selecionada na tabela.
 * @param {Function} params.obterCobrancaFlexivel - Função que valida a cobrança flexível.
 * @param {Function} params.obterSegundaViaBoletoVida - Função que realiza a obtenção da segunda via do boleto de vida.
 * @returns {Promise<void>} - Retorna uma `Promise` resolvida após a execução bem-sucedida.
 */
export const gerarBoletoVida = async ({
  row,
  obterCobrancaFlexivel,
  obterSegundaViaBoletoVida,
  handleToggleModalValidaEmissaoBoleto,
}: IGerarBoletoVida) => {
  const retornoReemissaoBoleto = await obterCobrancaFlexivel({
    sistemaChamador: CONSTS.SISTEMA_CHAMADOR,
    numeroCertificado: row.numeroContrato.toString(),
    numeroParcela: row.numeroParcela.toString(),
    dataVencimento: row.dataVencimentoParcela,
  });

  if (retornoReemissaoBoleto?.entidade?.podeReemitirCobranca) {
    await obterSegundaViaBoletoVida(
      row.numeroContrato.toString(),
      row.numeroParcela.toString(),
    );
  } else {
    handleToggleModalValidaEmissaoBoleto();
  }
};

/**
 * Obtém o rótulo (label) correspondente ao valor (value) de uma opção de pagamento.
 *
 * @param {string} value - O valor da opção de pagamento a ser pesquisada.
 * @returns {string} - O rótulo correspondente à opção de pagamento ou uma string vazia caso não seja encontrado.
 */
export const getPeriodicidadePagamentoLabel = (value: string): string => {
  const opcao = Object.values(CONSTS.OPCAO_PAGAMENTO).find(
    item => item.value === value,
  );

  return tryGetValueOrDefault([opcao?.label], '');
};

export const formatarValorInadimplencia = () => {
  return ({ valorInadimplencia }: PropsTable) => {
    return tryGetMonetaryValueOrDefault(valorInadimplencia, '-');
  };
};

export const formatarValorDecimal = (
  row: DadosCliente,
  propriedade: keyof DadosCliente,
  padrao = 0,
) => {
  return formatDecimalValue(tryGetValueOrDefault([row[propriedade]], padrao));
};
