// @ts-nocheck
import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { formatarPorcentagem } from 'main/utils/money';

export const formatarCpfCnpj = (numero: string): string =>
  masks.cpfCnpj.mask(tryGetValueOrDefault([numero], ''));

export const formatarParticipacao = (percentual: string): string =>
  `${formatarPorcentagem(tryGetValueOrDefault([percentual], ''))}%`;
