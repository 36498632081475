// @ts-nocheck
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import PrivateRoute from 'main/components/Route';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import React from 'react';
import { Switch } from 'react-router-dom';
import ConsultarOcorrencia from 'registroOcorrenciaASC/features/consultarOcorrencia/pages/ConsultarOcorrencia';
import DetalhesOcorrencia from 'registroOcorrenciaASC/features/consultarOcorrencia/pages/DetalhesOcorrencia/DetalhesOcorrencia';
import RegistrarOcorrencia from 'registroOcorrenciaASC/features/registrarOcorrencia/pages/RegistrarOcorrencia';

export const routes: IPrivateRoute[] = [
  {
    path: '/registro-ocorrencias/registrar-ocorrencia',
    component: RegistrarOcorrencia,
    key: 'registrar-ocorrencia',
    authenticated: true,
    breadcrumb: 'Registrar Ocorrência',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.SAC_CAIXA_TERCEIROS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_PJ,
    ],
  },
  {
    path: '/registro-ocorrencias/consultar-ocorrencia',
    component: ConsultarOcorrencia,
    key: 'consultar-ocorrencia',
    authenticated: true,
    breadcrumb: 'Consultar Ocorrência',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.SAC_CAIXA_TERCEIROS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_PJ,
    ],
  },
  {
    path: '/registro-ocorrencias/detalhes-ocorrencia',
    component: DetalhesOcorrencia,
    key: 'detalhes-ocorrencia',
    authenticated: true,
    breadcrumb: 'Detalhes Ocorrência',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.SAC_CAIXA_TERCEIROS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_PJ,
    ],
  },
];

export const registrarOcorrenciasAscBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/registro-ocorrencias': null,
};

const RotasRegistrarOcorrenciasAsc = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);

export default RotasRegistrarOcorrenciasAsc;
