// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Card,
  Grid,
  Text,
  Display,
  Divider,
  TextField,
  TextFieldMask,
  Select,
  Checkbox,
  Button,
  Radio,
} from '@cvp/design-system/react';
import { TitleSection } from 'main/styles/GlobalStyle';
import { TextArea } from 'main/components/form';
import {
  FieldArray,
  Field,
  FieldProps,
  FieldArrayRenderProps,
  Formik,
  ErrorMessage,
} from 'formik';
import masks from 'main/utils/masks';
import { TEXTOS_UPLOAD_SALES_FORCE } from 'main/components/form/InputFile/constants';

import InputFile from 'main/components/form/InputFile';
import {
  filesFormatsAllowed,
  maxFileSizeAllowed,
} from 'main/features/Validation/validations';
import useFieldLink from 'main/hooks/useFieldLink';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import { useHistory } from 'react-router';
import useSolicitarTransferenciaFop223 from 'extranet/hooks/useSolicitarTransferenciaFop223';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import { ISolicitarTransferenciaFop223 } from 'extranet/types/ISolicitarTransferenciaFop223';
import {
  TEXTOS_FOP223,
  TIPOS_TRANSFERENCIA,
  TIPOS_TRANSFERENCIA_TEXT,
  initialValues,
} from '../../features/fops/constants/constantsFop223';
import { useFop223Context } from '../../features/fops/context/Fop223Context';
import { IMessageEvent } from 'main/types/IMessageEvent';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  getContribuicaoRegularRendaVariavel,
  getDadosContribuicaoRegularRendaFixa,
  getDadosTransferenciaRendaFixa,
  getDadosTransferenciaRendaVariavel,
} from 'extranet/utils/fopsDadosTransferencia';
import {
  fopOnChangeDadosTransferenciaRendaFixa,
  fopOnChangeDadosTransferenciaRendaVariavel,
} from 'extranet/utils/fopOnChangeDadosTransferencia';
import { DynamicSelectFop223 } from './DynamicSelectFop223';
import { useToast } from 'main/hooks/useToast';
import { validationSchema } from 'extranet/utils/Fop223Validation';
import RenderConditional from 'main/components/RenderConditional';
import { ISolicitacaoAberturaProtocoloResponseError } from 'extranet/types/IUseSolicitarAberturaProtocoloFop223';

const SolicitacaoTransferenciaFop223: React.FC = () => {
  const [arquivoOcorrenciaFop223] = useFieldLink<FileList>({} as FileList);
  const { subscribe } = useMessageEvent();
  const { toastError } = useToast();
  const [openModalDocuSing, setOpenModalDocuSign] = useState(false);
  const [loading, setLoading] = useState(false);
  const { salvarPayloadProtocolo, isDigitalAgency } = useFop223Context();
  const [selectedTipoTransferencia, setTipoTransferencia] =
    useState<string>('');
  const [selectedBeneficioProtecao, setSelectedBeneficioProtecao] =
    useState<string>('');
  const [opcaofundo, setOpcaoFunco] = useState<string>('');

  const { response: { entidade: responseAssinatura } = {}, fetchData } =
    useSolicitarTransferenciaFop223();

  const history = useHistory();

  const handleSubmitForm = async (values: ISolicitarTransferenciaFop223) => {
    setLoading(true);

    try {
      await validationSchema.validate(values, { abortEarly: false });

      const response = await fetchData(values);
      salvarPayloadProtocolo({
        cpf: values.cpf,
        ComprovanteRenda: arquivoOcorrenciaFop223,
        NumeroCertificado: values.certificadoOrigem,
        CodigoCallback: tryGetValueOrDefault(
          [response?.entidade?.codigoCallback],
          0,
        ),
      });
    } catch (errors: unknown) {
      const typedError = errors as ISolicitacaoAberturaProtocoloResponseError;

      setLoading(false);
      const errorCount = typedError.inner.length;
      const errorMessages = typedError.inner
        .map(error => error.message)
        .join(', ');

      const finalErrorMessage =
        errorCount > 1
          ? `${errorMessages} são obrigatórios`
          : `${errorMessages} é obrigatório`;

      toastError(finalErrorMessage);
    }
  };

  useEffect(() => {
    if (responseAssinatura?.url) {
      setOpenModalDocuSign(true);
      setLoading(false);
    }
  }, [responseAssinatura]);

  useEffect(() => {
    subscribe(async item => {
      const {
        data: { eventName, eventSuccess },
      } = item as MessageEvent<IMessageEvent>;

      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        history.push('/extranet/fop-223/dados-protocolo');
      }
    });
  }, []);

  const addOpcaoFundo = () => ({
    total: '',
    parcial: '',
    valor: '',
  });

  const formatarValorParaAPI = (valor: string) => {
    if (typeof valor === 'string') {
      return valor.replace(/[R$]/g, '');
    }
    return valor;
  };
  const renderButton = (values: ISolicitarTransferenciaFop223) => {
    if (loading) {
      return (
        <Button variant="primary" loading>
          Enviar
        </Button>
      );
    }
    return (
      <Button variant="primary" onClick={() => handleSubmitForm(values)}>
        Enviar
      </Button>
    );
  };

  const AcessoNegadoFop = () => {
    if (!isDigitalAgency) {
      history.push({
        pathname: '/acesso-negado',
        state: {
          from: 'LISTA_FOPS',
        },
      });
    }
    return null;
  };

  return (
    <>
      <RenderConditional condition={isDigitalAgency}>
        <Formik initialValues={initialValues} onSubmit={() => undefined}>
          {({ values, setFieldValue, handleChange }) => (
            <Display type="display-block">
              <Card>
                <Card.Content>
                  <Grid>
                    <Grid.Item xs={1}>
                      <Text
                        variant="headline-05"
                        color="primary-dark"
                        key="formulario-titulo"
                      >
                        {TEXTOS_FOP223.tituloFop}
                      </Text>
                    </Grid.Item>
                  </Grid>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Display type="display-block">
                    <TitleSection>
                      {' '}
                      {TEXTOS_FOP223.tituloSecaoDadoCliente}
                    </TitleSection>

                    <Grid>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <TextField
                          label="Nome do Participante"
                          onChange={handleChange}
                          name="nomeParticipante"
                        />
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <TextFieldMask
                          label="CPF"
                          onChange={(value: string) => {
                            setFieldValue('cpf', value);
                          }}
                          name="cpf"
                          mask="cpf"
                        />
                        <ErrorMessage name="cpf" component="div" />
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <TextField
                          label="Endereço completo"
                          onChange={handleChange}
                          name="endereco"
                        />
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <TextField
                          label="E-mail"
                          name="email"
                          onChange={handleChange}
                        />
                        <ErrorMessage name="email" component="div" />
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1 / 2}>
                        <TextFieldMask
                          label="Telefone(s) para contato"
                          onChange={(value: string) => {
                            setFieldValue('telefones', value);
                          }}
                          name="telefones"
                          mask="phone"
                        />
                      </Grid.Item>
                      <Grid.Item>{TEXTOS_FOP223.avisoImportante}</Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        <Checkbox
                          name="deAcordoDadosAtualizados"
                          value="x"
                          onClick={({
                            target: { value },
                          }: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(`deAcordoDadosAtualizados`, value);
                          }}
                        />{' '}
                        {TEXTOS_FOP223.textoDeAcordo}
                      </Grid.Item>
                    </Grid>
                  </Display>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Display type="display-block">
                    <TitleSection>
                      {' '}
                      {TEXTOS_FOP223.tituloSecaoDadosTransferencia}{' '}
                    </TitleSection>
                    <Grid>
                      <Grid.Item xs={1} lg={1}>
                        <TitleSection>
                          {TEXTOS_FOP223.subTituloSecaoDadosTransferencia}
                        </TitleSection>
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        {TEXTOS_FOP223.textoExplicativoSecaoDadosTransferencia}
                      </Grid.Item>
                      <Divider />
                      <Grid.Item xs={1} lg={1 / 3}>
                        <TextField
                          label="Certificado"
                          onChange={handleChange}
                          name="certificadoOrigem"
                        />
                        <ErrorMessage
                          name="certificadoOrigem"
                          component="div"
                        />
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        <Display>
                          <Display alignItems="center">
                            <Radio
                              name="tipoTransferencia"
                              value="pgbl"
                              checked={selectedTipoTransferencia === 'pgbl'}
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<HTMLInputElement>) => {
                                setTipoTransferencia('pgbl');
                                setFieldValue(`pgbl`, 'x');
                                setFieldValue(`vgbl`, '');
                              }}
                            />{' '}
                            {TEXTOS_FOP223.PGBL}
                          </Display>
                          <Display alignItems="center">
                            <Radio
                              name="tipoTransferencia"
                              value="vgbl"
                              checked={selectedTipoTransferencia === 'vgbl'}
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<HTMLInputElement>) => {
                                setTipoTransferencia('vgbl');
                                setFieldValue(`vgbl`, 'x');
                                setFieldValue(`pgbl`, '');
                              }}
                            />{' '}
                            {TEXTOS_FOP223.VGBL}
                          </Display>
                        </Display>
                      </Grid.Item>
                      <Divider />
                      <Grid.Item xs={1} lg={1}>
                        <FieldArray
                          name="dadosTransferenciaRendaFixa"
                          render={(arrayHelpers: FieldArrayRenderProps) => (
                            <Accordion>
                              <Accordion.Item title="Renda Fixa">
                                {values.dadosTransferenciaRendaFixa.map(
                                  (fop223, index) => {
                                    return (
                                      <Grid style={{ marginBottom: 20 }}>
                                        <>
                                          <Grid.Item xs={1} md={1 / 2}>
                                            <Text variant="body02-sm" margin>
                                              {TEXTOS_FOP223.fundoOrigem}
                                            </Text>
                                            <>
                                              <Divider />
                                              <Field
                                                name={getDadosTransferenciaRendaFixa(
                                                  index,
                                                  'familiaFundoOrigem',
                                                )}
                                              >
                                                {({ field }: FieldProps) => {
                                                  return (
                                                    <DynamicSelectFop223
                                                      {...field}
                                                      key={0}
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      getDados={getDadosTransferenciaRendaFixa(
                                                        index,
                                                        'familiaFundoOrigem',
                                                      )}
                                                      values={
                                                        values
                                                          .dadosTransferenciaRendaFixa[
                                                          index
                                                        ].familiaFundoOrigem
                                                      }
                                                    />
                                                  );
                                                }}
                                              </Field>

                                              <br />
                                              <Select
                                                label="Tipo de transferência"
                                                placeholder="Escolha a opção"
                                                onChange={({
                                                  target: { value },
                                                }: React.ChangeEvent<HTMLInputElement>) => {
                                                  fopOnChangeDadosTransferenciaRendaFixa(
                                                    value,
                                                    index,
                                                    setFieldValue,
                                                  );
                                                }}
                                              >
                                                <Select.Item
                                                  value={
                                                    TIPOS_TRANSFERENCIA.TOTAL
                                                  }
                                                  text={
                                                    TIPOS_TRANSFERENCIA_TEXT.TOTAL
                                                  }
                                                  selected={
                                                    !!values
                                                      .dadosTransferenciaRendaFixa[
                                                      index
                                                    ].total
                                                  }
                                                />
                                                <Select.Item
                                                  value={
                                                    TIPOS_TRANSFERENCIA.PARCIAL
                                                  }
                                                  text={
                                                    TIPOS_TRANSFERENCIA_TEXT.PARCIAL
                                                  }
                                                  selected={
                                                    !!values
                                                      .dadosTransferenciaRendaFixa[
                                                      index
                                                    ].parcial
                                                  }
                                                />
                                              </Select>
                                              <br />
                                              <TextFieldMask
                                                label="Valor"
                                                onChange={(value: string) => {
                                                  setFieldValue(
                                                    getDadosTransferenciaRendaFixa(
                                                      index,
                                                      'valor',
                                                    ),
                                                    formatarValorParaAPI(value),
                                                  );
                                                }}
                                                mask="currencyInput"
                                              />
                                            </>
                                          </Grid.Item>
                                          <Grid.Item xs={1} md={1 / 2}>
                                            <Text variant="body02-sm" margin>
                                              {TEXTOS_FOP223.fundoDestino}
                                            </Text>
                                            <Divider />
                                            <Field
                                              name={getDadosTransferenciaRendaFixa(
                                                index,
                                                'familiaFundoDestino',
                                              )}
                                            >
                                              {({ field }: FieldProps) => {
                                                return (
                                                  <DynamicSelectFop223
                                                    {...field}
                                                    key={1}
                                                    setFieldValue={
                                                      setFieldValue
                                                    }
                                                    getDados={getDadosTransferenciaRendaFixa(
                                                      index,
                                                      'familiaFundoDestino',
                                                    )}
                                                    values={
                                                      values
                                                        .dadosTransferenciaRendaFixa[
                                                        index
                                                      ].familiaFundoDestino
                                                    }
                                                  />
                                                );
                                              }}
                                            </Field>

                                            <br />
                                          </Grid.Item>
                                        </>
                                      </Grid>
                                    );
                                  },
                                )}
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    arrayHelpers.push(addOpcaoFundo())
                                  }
                                >
                                  {TEXTOS_FOP223.botaoRendaFixa}
                                </Button>
                              </Accordion.Item>
                            </Accordion>
                          )}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        <FieldArray
                          name="dadosTransferenciaRendaVarivavel"
                          render={(arrayHelpers: FieldArrayRenderProps) => (
                            <Accordion>
                              <Accordion.Item title="Renda Variável">
                                {values.dadosTransferenciaRendaVarivavel.map(
                                  (_, index) => (
                                    <Grid style={{ marginBottom: 20 }}>
                                      <Grid.Item xs={1} md={1 / 2}>
                                        <Text variant="body02-sm" margin>
                                          {TEXTOS_FOP223.fundoOrigem}
                                        </Text>
                                        <Divider />
                                        <Field
                                          name={getDadosTransferenciaRendaVariavel(
                                            index,
                                            'familiaFundoOrigem',
                                          )}
                                        >
                                          {({ field }: FieldProps) => {
                                            return (
                                              <DynamicSelectFop223
                                                {...field}
                                                key={2}
                                                setFieldValue={setFieldValue}
                                                getDados={getDadosTransferenciaRendaVariavel(
                                                  index,
                                                  'familiaFundoOrigem',
                                                )}
                                                values={
                                                  values
                                                    .dadosTransferenciaRendaVarivavel[
                                                    index
                                                  ].familiaFundoOrigem
                                                }
                                              />
                                            );
                                          }}
                                        </Field>
                                        <br />
                                        <Select
                                          label="Tipo de transferência"
                                          placeholder="Escolha a opção"
                                          onChange={({
                                            target: { value },
                                          }: React.ChangeEvent<HTMLInputElement>) => {
                                            fopOnChangeDadosTransferenciaRendaVariavel(
                                              value,
                                              index,
                                              setFieldValue,
                                            );
                                          }}
                                        >
                                          <Select.Item
                                            value={TIPOS_TRANSFERENCIA.TOTAL}
                                            text={
                                              TIPOS_TRANSFERENCIA_TEXT.TOTAL
                                            }
                                            selected={
                                              !!values
                                                .dadosTransferenciaRendaVarivavel[
                                                index
                                              ].total
                                            }
                                          />
                                          <Select.Item
                                            value={TIPOS_TRANSFERENCIA.PARCIAL}
                                            text={
                                              TIPOS_TRANSFERENCIA_TEXT.PARCIAL
                                            }
                                            selected={
                                              !!values
                                                .dadosTransferenciaRendaVarivavel[
                                                index
                                              ].parcial
                                            }
                                          />
                                        </Select>
                                        <br />
                                        <TextFieldMask
                                          label="Valor"
                                          onChange={(value: string) => {
                                            setFieldValue(
                                              getDadosTransferenciaRendaVariavel(
                                                index,
                                                'valor',
                                              ),
                                              formatarValorParaAPI(value),
                                            );
                                          }}
                                          mask="currencyInput"
                                        />
                                      </Grid.Item>
                                      <Grid.Item xs={1} md={1 / 2}>
                                        <Text variant="body02-sm" margin>
                                          {TEXTOS_FOP223.fundoDestino}
                                        </Text>
                                        <Divider />
                                        <Field
                                          name={getDadosTransferenciaRendaVariavel(
                                            index,
                                            'familiaFundoDestino',
                                          )}
                                        >
                                          {({ field }: FieldProps) => {
                                            return (
                                              <DynamicSelectFop223
                                                {...field}
                                                key={3}
                                                setFieldValue={setFieldValue}
                                                getDados={getDadosTransferenciaRendaVariavel(
                                                  index,
                                                  'familiaFundoDestino',
                                                )}
                                                values={
                                                  values
                                                    .dadosTransferenciaRendaVarivavel[
                                                    index
                                                  ].familiaFundoDestino
                                                }
                                              />
                                            );
                                          }}
                                        </Field>
                                        <br />

                                        <br />
                                      </Grid.Item>
                                    </Grid>
                                  ),
                                )}
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    arrayHelpers.push(addOpcaoFundo())
                                  }
                                >
                                  {TEXTOS_FOP223.botaoRendaVariavel}
                                </Button>
                              </Accordion.Item>
                            </Accordion>
                          )}
                        />
                      </Grid.Item>
                      <Grid.Item>{TEXTOS_FOP223.avisoUpload}</Grid.Item>
                      <Grid.Item xs={1} md={1 / 3}>
                        <Text variant="caption-02">
                          {TEXTOS_UPLOAD_SALES_FORCE.EXTENSOES_PERMITIDAS}
                        </Text>
                        <Text variant="caption-02" margin>
                          Tamanho máximo do arquivo:{' '}
                          {TEXTOS_UPLOAD_SALES_FORCE.TAMANHO_ARQUIVO_UPLOAD.toString()}
                          MB
                        </Text>
                        <InputFile
                          maxSizeMb={
                            TEXTOS_UPLOAD_SALES_FORCE.TAMANHO_ARQUIVO_UPLOAD
                          }
                          formatsAllowed={
                            TEXTOS_UPLOAD_SALES_FORCE.FORMATOS_PERMITIDOS_UPLOAD
                          }
                          link={arquivoOcorrenciaFop223}
                          validationRules={[
                            filesFormatsAllowed(
                              () =>
                                TEXTOS_UPLOAD_SALES_FORCE.MSG_FORMATO_NAO_SUPORTADO,
                              TEXTOS_UPLOAD_SALES_FORCE.FORMATOS_PERMITIDOS_UPLOAD,
                            ),
                            maxFileSizeAllowed(
                              () => TEXTOS_UPLOAD_SALES_FORCE.TAMANHO_MAX_ANEXO,
                              TEXTOS_UPLOAD_SALES_FORCE.TAMANHO_ARQUIVO_UPLOAD,
                            ),
                          ]}
                          multiple
                        />
                        <ErrorMessage
                          name="arquivoOcorrenciaFop223"
                          component="div"
                        />
                      </Grid.Item>
                    </Grid>
                  </Display>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Display type="display-block">
                    <Grid>
                      <Grid.Item xs={1} lg={1}>
                        <TitleSection>
                          {
                            TEXTOS_FOP223.subTituloSecaoDadosTransferenciaBeneficioProtecao
                          }
                        </TitleSection>
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        {
                          TEXTOS_FOP223.avisosBeneficioProtecao
                            .autorizacaoBeneficioProtecao
                        }
                      </Grid.Item>
                      <Divider />
                      <Grid.Item xs={1} lg={1}>
                        {
                          TEXTOS_FOP223.avisosBeneficioProtecao
                            .benficioProtecaoAtivo
                        }
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        {
                          TEXTOS_FOP223.avisosBeneficioProtecao
                            .periodoDeCarencia
                        }
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        {
                          TEXTOS_FOP223.avisosBeneficioProtecao
                            .contribuicaoCertificadoOrigem
                        }
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        {
                          TEXTOS_FOP223.avisosBeneficioProtecao
                            .beneficioProtecaoNaoMaisComercializado
                        }
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        {TEXTOS_FOP223.avisosBeneficioProtecao.coberturaRisco}
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        {TEXTOS_FOP223.avisosBeneficioProtecao.manutencaoRisco}
                      </Grid.Item>
                      <Divider />
                      <Grid.Item xs={1} lg={1}>
                        <TitleSection>
                          {
                            TEXTOS_FOP223.avisosBeneficioProtecao
                              .opcaoBeneficioProtecao
                          }
                        </TitleSection>
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        <Display>
                          <Display alignItems="center">
                            <Radio
                              name="x"
                              value="desejoManterValorContribuicao"
                              checked={
                                selectedBeneficioProtecao ===
                                'desejoManterValorContribuicao'
                              }
                              onChange={() => {
                                setSelectedBeneficioProtecao(
                                  'desejoManterValorContribuicao',
                                );
                                setFieldValue(
                                  `desejoManterValorImportanciaSegurada`,
                                  '',
                                );
                                setFieldValue(
                                  `desejoManterValorContribuicao`,
                                  'x',
                                );
                              }}
                            />{' '}
                            {TEXTOS_FOP223.manterValorContribuicao}
                          </Display>
                          <Display alignItems="center">
                            <Radio
                              name="x"
                              value="desejoManterValorImportanciaSegurada"
                              checked={
                                selectedBeneficioProtecao ===
                                'desejoManterValorImportanciaSegurada'
                              }
                              onChange={() => {
                                setSelectedBeneficioProtecao(
                                  'desejoManterValorImportanciaSegurada',
                                );
                                setFieldValue(
                                  `desejoManterValorContribuicao`,
                                  '',
                                );
                                setFieldValue(
                                  `desejoManterValorImportanciaSegurada`,
                                  'x',
                                );
                              }}
                            />{' '}
                            {TEXTOS_FOP223.manterImportanciaSegurada}
                          </Display>
                        </Display>
                      </Grid.Item>
                    </Grid>
                  </Display>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Display type="display-block">
                    <Grid>
                      <Grid.Item xs={1} lg={1}>
                        <TitleSection>
                          {TEXTOS_FOP223.tituloSecaoDadosContribuicoes}
                        </TitleSection>
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        {TEXTOS_FOP223.avisoContribuicoes}
                      </Grid.Item>
                      <Divider />
                      <Grid.Item xs={1} lg={1}>
                        <Display alignItems="center">
                          <Checkbox
                            name="desejoManterContribuicaoFundoOrigem"
                            onClick={({
                              target: { value },
                            }: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(
                                `desejoManterContribuicaoFundoOrigem`,
                                value,
                              );
                            }}
                            value="x"
                          />
                          {TEXTOS_FOP223.contribuirMensalmente}
                        </Display>
                      </Grid.Item>

                      <Divider />
                      <Grid.Item xs={1} lg={1}>
                        <TitleSection>
                          {TEXTOS_FOP223.opcoesContribuicaoMensal}
                        </TitleSection>
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        <Grid>
                          <Grid.Item xs={1} md={1 / 2}>
                            <Radio
                              value="desejoContribuirMensalFundoDiversificado"
                              name="opcaoFundo"
                              checked={
                                opcaofundo ===
                                'desejoContribuirMensalFundoDiversificado'
                              }
                              onChange={() => {
                                setOpcaoFunco(
                                  'desejoContribuirMensalFundoDiversificado',
                                );
                                setFieldValue(
                                  `desejoContribuirMensalFundoNovo`,
                                  '',
                                );
                                setFieldValue(
                                  `desejoContribuirMensalFundoDiversificado`,
                                  'x',
                                );
                              }}
                            />{' '}
                            {TEXTOS_FOP223.contribuirFundoDiversificado}
                            <Divider />
                          </Grid.Item>
                          <Grid.Item xs={1} md={1 / 2}>
                            <Radio
                              value="desejoContribuirMensalFundoNovo"
                              name="opcaoFundo"
                              checked={
                                opcaofundo === 'desejoContribuirMensalFundoNovo'
                              }
                              onChange={() => {
                                setOpcaoFunco(
                                  'desejoContribuirMensalFundoNovo',
                                );
                                setFieldValue(
                                  `desejoContribuirMensalFundoDiversificado`,
                                  '',
                                );
                                setFieldValue(
                                  `desejoContribuirMensalFundoNovo`,
                                  'x',
                                );
                              }}
                            />{' '}
                            {TEXTOS_FOP223.criarNovoFundo}
                            <Divider />
                          </Grid.Item>
                        </Grid>
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        <FieldArray
                          name="fundoContribuicaoRegularRendaFixa"
                          render={arrayHelpers => (
                            <Accordion>
                              <Accordion.Item title="Renda Fixa">
                                {values.fundoContribuicaoRegularRendaFixa.map(
                                  (_, index) => (
                                    <Grid style={{ marginBottom: 20 }}>
                                      <Grid.Item xs={1} md={1 / 2}>
                                        <Field
                                          name={`fundoContribuicaoRegularRendaFixa[${index}].familiaFundoOrigem`}
                                        >
                                          {({ field }: FieldProps) => {
                                            return (
                                              <DynamicSelectFop223
                                                {...field}
                                                key={4}
                                                setFieldValue={setFieldValue}
                                                getDados={getDadosContribuicaoRegularRendaFixa(
                                                  index,
                                                  'familiaFundoOrigem',
                                                )}
                                                values={
                                                  values
                                                    .fundoContribuicaoRegularRendaFixa[
                                                    index
                                                  ].familiaFundoOrigem
                                                }
                                              />
                                            );
                                          }}
                                        </Field>
                                        <br />
                                      </Grid.Item>
                                      <Grid.Item xs={1} md={1 / 2}>
                                        <Field
                                          name={`fundoContribuicaoRegularRendaFixa[${index}].familiaFundoDestino`}
                                        >
                                          {({ field }: FieldProps) => {
                                            return (
                                              <DynamicSelectFop223
                                                {...field}
                                                key={5}
                                                setFieldValue={setFieldValue}
                                                getDados={getDadosContribuicaoRegularRendaFixa(
                                                  index,
                                                  'familiaFundoDestino',
                                                )}
                                                values={
                                                  values
                                                    .fundoContribuicaoRegularRendaFixa[
                                                    index
                                                  ].familiaFundoDestino
                                                }
                                              />
                                            );
                                          }}
                                        </Field>
                                        <br />
                                      </Grid.Item>
                                    </Grid>
                                  ),
                                )}

                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    arrayHelpers.push(addOpcaoFundo())
                                  }
                                >
                                  {TEXTOS_FOP223.botaoRendaFixa}
                                </Button>
                              </Accordion.Item>
                            </Accordion>
                          )}
                        />
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        <FieldArray
                          name="fundoContribuicaoRegularRendaVariavel"
                          render={arrayHelpers => (
                            <Accordion>
                              <Accordion.Item title="Renda Variável">
                                {values.fundoContribuicaoRegularRendaVariavel.map(
                                  (_, index) => (
                                    <Grid style={{ marginBottom: 20 }}>
                                      <Grid.Item xs={1} md={1 / 2}>
                                        <Field
                                          name={`fundoContribuicaoRegularRendaVariavel[${index}].familiaFundoOrigem`}
                                        >
                                          {({ field }: FieldProps) => {
                                            return (
                                              <DynamicSelectFop223
                                                {...field}
                                                key={6}
                                                setFieldValue={setFieldValue}
                                                getDados={getContribuicaoRegularRendaVariavel(
                                                  index,
                                                  'familiaFundoOrigem',
                                                )}
                                                values={
                                                  values
                                                    .fundoContribuicaoRegularRendaVariavel[
                                                    index
                                                  ].familiaFundoOrigem
                                                }
                                              />
                                            );
                                          }}
                                        </Field>
                                        <br />
                                      </Grid.Item>
                                      <Grid.Item xs={1} md={1 / 2}>
                                        <Field
                                          name={`fundoContribuicaoRegularRendaVariavel[${index}].familiaFundoDestino`}
                                        >
                                          {({ field }: FieldProps) => {
                                            return (
                                              <DynamicSelectFop223
                                                {...field}
                                                key={7}
                                                setFieldValue={setFieldValue}
                                                getDados={getContribuicaoRegularRendaVariavel(
                                                  index,
                                                  'familiaFundoDestino',
                                                )}
                                                values={
                                                  values
                                                    .fundoContribuicaoRegularRendaVariavel[
                                                    index
                                                  ].familiaFundoDestino
                                                }
                                              />
                                            );
                                          }}
                                        </Field>
                                        <br />
                                      </Grid.Item>
                                    </Grid>
                                  ),
                                )}
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    arrayHelpers.push(addOpcaoFundo())
                                  }
                                >
                                  {TEXTOS_FOP223.botaoRendaVariavel}
                                </Button>
                              </Accordion.Item>
                            </Accordion>
                          )}
                        />
                      </Grid.Item>
                    </Grid>
                  </Display>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Display>
                    <Grid>
                      <Grid.Item xs={1} lg={1}>
                        <TitleSection>
                          {TEXTOS_FOP223.informacoesComplementares}
                        </TitleSection>
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        {TEXTOS_FOP223.avisoInformacoesComplementares}
                      </Grid.Item>
                      <Grid.Item xs={1} lg={1}>
                        <TextArea
                          name="informacoesComplementares"
                          onChange={handleChange}
                        />
                      </Grid.Item>
                    </Grid>
                  </Display>
                </Card.Content>
              </Card>
              <Card>
                <Card.Content>
                  <Display>
                    <Grid>
                      <Grid.Item xs={1} lg={1}>
                        <TitleSection>
                          {TEXTOS_FOP223.tituloDeclaracao}
                        </TitleSection>
                      </Grid.Item>
                      <Grid>
                        <Grid.Item xs={1} lg={1}>
                          <Text variant="lead">
                            {TEXTOS_FOP223.declaracaoRegulamento}
                          </Text>
                        </Grid.Item>
                        <Grid.Item xs={1} lg={1 / 2}>
                          <TextField
                            label="Local"
                            name="local"
                            onChange={handleChange}
                          />
                        </Grid.Item>
                        <Grid.Item xs={1} lg={1 / 2}>
                          <TextFieldMask
                            label="Data"
                            onChange={(value: string) => {
                              setFieldValue(`data`, masks.date.unmask(value));
                            }}
                            mask="date"
                          />
                        </Grid.Item>
                      </Grid>
                    </Grid>
                  </Display>
                  <br />
                  {renderButton(values)}
                </Card.Content>
              </Card>
            </Display>
          )}
        </Formik>
        <ModalTokenDocuSign
          open={openModalDocuSing}
          urlTokenPage={responseAssinatura?.url}
          handleClose={() => setOpenModalDocuSign(false)}
          exibirBotaoFecharModal={false}
        />
      </RenderConditional>
      <RenderConditional condition={!isDigitalAgency}>
        {AcessoNegadoFop()}
      </RenderConditional>
    </>
  );
};
export default SolicitacaoTransferenciaFop223;
