/* eslint-disable @typescript-eslint/no-explicit-any */

import { Radio } from '@cvp/design-system/react';

import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { isNegativeCurrent } from 'main/utils/string';
import { createColumn } from 'previdencia/features/AlteracaoBeneficiarios/utils/criarColunaTabela';
import {
  CellTypeRentabilidadeCotas,
  TabelaFundosType,
} from 'previdencia/types/Tabelas';
import {
  obterCodigoOperacaoENumeroConta,
  ordenaDataRealizado,
  ordenaValor,
} from 'previdencia/utils/previdencia';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';
import * as ALTERACAO_CONTA_UTILS from 'previdencia/features/AlteracaoFormaDadosPagamento/utils/alteracaoFormaDadosPagamento';
import { IDataTableColumn } from 'react-data-table-component';
import { AnoHistorico } from 'previdencia/features/ReajusteAnualPlano/types/ResponseAnosHistorico';
import { ExtratoRentabilidade } from 'previdencia/features/RentabilidadeCotas/types/ResponseExtratoRentabilidadeCotas';

export const COLUNAS_TABELA_FUNDOS = [
  {
    wrap: true,
    selector: 'check',
    width: '50px',
    name: '',
  },
  {
    selector: 'descFundo',
    name: 'Fundo',
    minWidth: '250px',
    cell: ({ descFundo }: TabelaFundosType) => (
      <p title={descFundo}>{descFundo}</p>
    ),
  },
  {
    name: 'Perfil do Risco',
    minWidth: '180px',
    selector: 'perfil',
  },
  {
    name: 'Rentabilidade (últimos 12 meses)',
    minWidth: '140px',
    selector: 'rentabilidade',
  },
  {
    selector: 'saldo',
    minWidth: '140px',
    name: 'Saldo',
  },
];

export const COLUNAS_TABELA_EXTRATO_RENTABILIDADE_COTAS: IDataTableColumn<ExtratoRentabilidade>[] =
  [
    {
      name: 'Data Extrato',
      minWidth: '135px',
      selector: row => row.dataExtrato,
    },
    {
      name: 'Data Movimentação',
      minWidth: '130px',
      selector: row => row.dataMotivo,
    },
    {
      name: 'Tipo Movimentação',
      minWidth: '200px',
      selector: row => row.nomeMotivo,
      cell: ({ nomeMotivo }: CellTypeRentabilidadeCotas) => (
        <p title={nomeMotivo}>{nomeMotivo}</p>
      ),
    },
    {
      name: 'Valor Movimentação',
      selector: row => row.valorMotivo,
      minWidth: '160px',
      conditionalCellStyles: [
        {
          when: (row: any) => isNegativeCurrent(row.valorMotivo),
          style: {
            color: 'red',
          },
        },
      ],
    },
    {
      name: 'Data Cota Movimentação',
      selector: row => row.dataCota,
      minWidth: '130px',
      style: {
        backgroundColor: 'rgba(239, 239, 239, 1)',
      },
    },
    {
      name: 'Valor Cota no Dia *',
      selector: row => row.valorCota,
      minWidth: '180px',
      style: {
        backgroundColor: 'rgba(239, 239, 239, 1)',
      },
    },
    {
      name: 'Quantidade Total de Cotas',
      selector: row => row.valorTotalCota,
      minWidth: '180px',
      style: {
        backgroundColor: 'rgba(239, 239, 239, 1)',
      },
      conditionalCellStyles: [
        {
          when: (row: any) => isNegativeCurrent(row.valorTotalCota),
          style: {
            color: 'red',
          },
        },
      ],
    },
    {
      name: 'Reserva Total',
      minWidth: '140px',
      selector: row => row.valorSaldo,
      conditionalCellStyles: [
        {
          when: (row: any) => isNegativeCurrent(row.Reserva),
          style: {
            color: 'red',
          },
        },
      ],
    },
    {
      name: 'Rentabilidade',
      minWidth: '180px',
      selector: row => row.valorRentabilidade,
      conditionalCellStyles: [
        {
          when: (row: any) => isNegativeCurrent(row.valorRentabilidade),
          style: {
            color: 'red',
          },
        },
      ],
    },
    {
      name: 'Cód fundo **',
      selector: row => row.codigoFundo,
      minWidth: '90px',
      style: {
        backgroundColor: 'rgba(239, 239, 239, 1)',
      },
    },
  ];

export const COLUNAS_TABELA_RESERVA = [
  {
    id: '1',
    name: 'Beneficiário',
    selector: 'beneficiario',
    minWidth: '400px',
  },
  {
    id: '2',
    name: 'Parentesco',
    selector: 'parentesco',
  },
  {
    id: '3',
    name: 'Data Nascimento',
    selector: 'dataNascimento',
  },
  {
    id: '4',
    name: 'Percentual',
    selector: 'percentual',
  },
  {
    id: '5',
    name: 'Remover',
    selector: 'remover',
  },
];

export const COLUNAS_TABELA_RESERVA_IMPRIMIR = [
  {
    id: '1',
    name: 'Beneficiário',
    selector: 'beneficiario',
    minWidth: '260px',
  },
  {
    id: '2',
    name: 'Parentesco',
    selector: 'parentesco',
    maxWidth: '40px',
  },
  {
    id: '3',
    name: 'Dt Nascimento',
    selector: 'dataNascimento',
    maxWidth: '130px',
  },
  {
    id: '4',
    name: 'Percentual',
    selector: 'percentual',
  },
];

export const COLUNAS_TABELA_ALTERACAO_BENEFICIARIOS = [
  {
    id: '1',
    name: 'Beneficiário',
    selector: 'beneficiario',
    minWidth: '500px',
  },
  {
    id: '2',
    name: 'Data Nascimento',
    selector: 'dataNascimento',
  },
  createColumn('3', 'Grau Parentesco', 'grauParentesco'),
  createColumn('4', 'Coberturas', 'coberturas'),
];

export const COLUNAS_TABELA_ALTERACAO_BENEFICIARIOS_IMPRIMIR = [
  {
    id: '1',
    name: 'Beneficiário',
    selector: 'beneficiario',
    minWidth: '280px',
  },
  {
    id: '2',
    name: 'Dt Nascimento',
    selector: 'dataNascimento',
    maxWidth: '130px',
  },
  {
    id: '3',
    name: 'Parentesco',
    selector: 'grauParentesco',
    maxWidth: '40px',
  },
  {
    id: '4',
    name: 'Coberturas',
    selector: 'coberturas',
    maxWidth: '75px',
  },
];

export const COLUNAS_TABELA_TIPO_RESERVA: IDataTableColumn<AnoHistorico>[] = [
  {
    name: 'Tipo de atualização',
    selector: row => row.desTipoAtualizacao,
    minWidth: '320px',
  },
  {
    name: 'Data',
    selector: row => row.dthRecal,
  },
  {
    name: 'Valor anterior',
    selector: row => row.vlrAnterior,
  },
  {
    name: 'Atualização*',
    selector: row => row.pctAtualizacaoCodIndice,
  },
  {
    name: 'Valor final',
    selector: row => row.vlrFinal,
  },
];

export const COLUNAS_TABELA_TIPO_CUIDADO_EXTRA: IDataTableColumn<AnoHistorico>[] =
  [
    {
      name: 'Tipo de atualização',
      selector: row => row.desTipoAtualizacao,
      minWidth: '360px',
    },
    {
      name: 'Data',
      selector: row => row.dthRecal,
    },
    {
      name: 'Cuidado Extra',
      minWidth: '250px',
      selector: row => row.desPagamento,
    },
    {
      name: 'Valor anterior',
      selector: row => row.vlrFinal,
    },
    {
      name: 'Atualização*',
      selector: row => row.pctAtualizacaoCodIndice,
    },
    {
      name: 'Valor final',
      selector: row => row.vlrFinal,
    },
  ];

export const COLUNAS_HISTORICO_SOLICITACOES = [
  {
    name: 'Serviço',
    selector: 'servico',
    wrap: true,
    sortable: true,
    minWidth: '180px',
  },
  {
    name: 'Realizado',
    selector: 'realizado',
    sortable: true,
    minWidth: '140px',
    sortFunction: ordenaDataRealizado,
  },
  {
    name: 'Canal',
    selector: 'canal',
    sortable: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: 'Status',
    selector: 'status',
    wrap: true,
    sortable: true,
    minWidth: '150px',
  },
  {
    name: 'Valor',
    selector: 'valor',
    minWidth: '150px',
    sortable: true,
    sortFunction: ordenaValor,
  },
  {
    name: 'Comprovante',
    selector: 'comprovante',
    wrap: true,
    minWidth: '180px',
  },
];

export const COLUNAS_INFORMES_BENEFICIARIOS = [
  {
    name: 'Nome do Beneficiário',
    selector: 'nomeBeneficiario',
    minWidth: '500px',
  },
  {
    name: 'CPF',
    selector: 'cpf',
    minWidth: '100px',
  },
  {
    name: 'Informe de Rendimentos',
    selector: 'informe',
    minWidth: '350px',
  },
];

export const COLUNAS_TABELA_CONTAS_BANCARIAS = ({
  canal,
  obterContaCadastradaSelecionada,
}: ALTERACAO_CONTA_TYPES.IColunasTabelaContasBancariasParams) => [
  {
    name: '',
    selector: 'marcarBanco',
    wrap: true,
    width: '50px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) => (
      <Radio
        data-testid="btnEmail"
        onChange={() => {
          obterContaCadastradaSelecionada(row);
        }}
        checked={checkIfAllItemsAreTrue([
          !canal.isDisabled,
          canal.canal === row.idCanalRecuperado,
        ])}
        value="EMAIL"
        name="selectedMethod"
        disabled={canal.isDisabled}
      />
    ),
  },
  {
    name: 'Banco',
    selector: 'numeroBanco',
    minWidth: '140px',
  },
  {
    name: 'Agência',
    selector: 'numeroAgencia',
    minWidth: '140px',
  },
  {
    name: 'Operação',
    selector: 'operacao',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      obterCodigoOperacaoENumeroConta(row.numeroConta).operacao,
  },
  {
    name: 'Conta',
    selector: 'conta',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      `${obterCodigoOperacaoENumeroConta(row.numeroConta).numeroConta}-${
        row.digitoConta
      }`,
  },
];

export const COLUNAS_TABELA_DADOS_BANCARIOS_CONFIRMACAO = (
  certificado?: string,
) => [
  {
    name: 'CERTIFICADO',
    selector: 'certificado',
    wrap: true,
    minWidth: '140px',
    cell: () => certificado,
  },
  {
    name: 'FORMA DE PAGAMENTO',
    selector: 'formaPagamento',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      ALTERACAO_CONTA_UTILS.buscarMetodoPagamento(row.metodoPagamento),
  },
  {
    name: 'BANCO',
    selector: 'banco',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      row.numeroBanco,
  },
  {
    name: 'AGÊNCIA',
    selector: 'agencia',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      row.numeroAgencia,
  },
  {
    name: 'CONTA',
    selector: 'conta',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      `${
        obterCodigoOperacaoENumeroConta(
          tryGetValueOrDefault([row.numeroConta], ''),
        ).numeroConta
      }-${row.digitoConta}`,
  },
];

export const COLUNAS_TABELA_DADOS_ANTIGO_BANCARIOS_CONFIRMACAO = (
  certificado?: string,
) => [
  {
    name: 'CERTIFICADO',
    selector: 'certificadoAntigo',
    wrap: true,
    minWidth: '140px',
    cell: () => certificado,
  },
  {
    name: 'FORMA DE PAGAMENTO',
    selector: 'formaPagamentoAntigo',
    minWidth: '140px',
  },
  {
    name: 'BANCO',
    selector: 'bancoAntigo',
    minWidth: '140px',
  },
  {
    name: 'AGÊNCIA',
    selector: 'agenciaAntiga',
    minWidth: '140px',
  },
  {
    name: 'CONTA',
    selector: 'contaAntiga',
    minWidth: '140px',
  },
];

export const prevQueryCacheDuration = (duration = 60): number =>
  Number(process.env.REACT_APP_CACHE_DURATION) * duration;

export const COD_EMPRESA = '500';

export const ASSINATURA_BENEFICIO_PROTECAO = {
  CODIGO_EMPRESA: COD_EMPRESA,
  TIPO_ASSINATURA: 'OFERTA_RISCO',
  TIPO_ENVIO: 'SMS',
};

export enum TabelaFundosDefaultValue {
  PERFIL_RISCO_MODERADO = 'Moderado',
}
export const ALIQUOTA = {
  TIPO_REGIME_REGRESSIVO: 'R',
  TIPO_REGIME_PROGRESSIVO: 'P',
  TIPO_RESGATE_TOTAL: 'T',
  TIPO_RESGATE_PARCIAL: 'P',
  TRIBUTACAO_REGRESSIVA: 'TR',
  TRIBUTACAO_PROGRESSIVA: 'TP',
  PROGRESSIVO: 'Tributação Progressiva',
  REGRESSIVO: 'Tributação Regressiva',
};
export const TIPO_ASSINATURA = {
  ENTRE_CERTIFICADOS: 'TRANSCERT',
  TRANSFERENCIA_ADESAO: 'T_ADS',
};

export const TIPO_ENVIO_ASSINATURA = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};
export const FLAGS_FOPS = {
  FOP223: '_FOP223',
};
