// @ts-nocheck
import {
  COLUNAS_TABELA_ASSISTENCIAS,
  SEM_DADOS_ASSISTENCIAS,
} from 'prestamista/features/dadosSeguro/constants/Assistencias';
import React, { useMemo } from 'react';
import { IAssistenciaProps } from 'prestamista/features/dadosSeguro/types/ICoberturas';
import { TableSemHeader } from 'prestamista/components/TabelaSemHeader';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const Assistencias: React.FC<IAssistenciaProps> = ({ data }) => {
  const columns = useMemo(() => COLUNAS_TABELA_ASSISTENCIAS, []);

  return (
    <TableSemHeader
      noHeader
      responsive
      striped
      highlightOnHover
      columns={columns}
      data={tryGetValueOrDefault([data], [])}
      noDataComponent={SEM_DADOS_ASSISTENCIAS}
      pagination
      paginationPerPage={10}
      paginationComponentOptions={{
        rowsPerPageText: 'Itens por página',
        rangeSeparatorText: 'de',
      }}
    />
  );
};
