import {
  Button,
  Card,
  Display,
  Skeleton,
  Text,
} from '@cvp/design-system/react';
import { HeaderPendentesDPS } from 'dps/features/pendentesDps/components/HeaderPendentesDPS';
import { TabelaPainelDPS } from 'dps/features/pendentesDps/components/TabelaPainelDPS';
import { DADOS_INICIAL_AGENCIADPS } from 'dps/features/pendentesDps/constants/constants';
import { colunasDPSAgenciaFactory } from 'dps/features/pendentesDps/factories/colunasDPSAgenciaFactory';
import { colunasDPSItemsFactory } from 'dps/features/pendentesDps/factories/colunasDPSItemsFactory';
import { totalDpsFactory } from 'dps/features/pendentesDps/factories/totalDpsFactory';
import { usePendentesDPS } from 'dps/features/pendentesDps/hooks/usePendentesDPS';
import ModalAlteracaoDetalhes from 'main/components/ModalAlteracaoDetalhes/ModalAlteracaoDetalhes';
import ModalRetornoAcaoDps from 'main/components/ModalRetornoAcaoDps/ModalRetornoAcaoDps';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { removerMascaraValorMonetario } from 'main/utils/money';
import { verificarVisaoAgencia } from 'main/utils/verificarVisaoAgencia';
import React, { useMemo } from 'react';

export const PendentesDPS: React.FC = () => {
  const {
    unidade,
    handleChangeRotaUnidade,
    handleBackStep,
    handleAbriModalAlteracaoDetalhes,
    handleEnviarFormulario,
    handleRetornoReenvioDps,
    setModalAlteracaoDetalhesOpen,
    loadingHierarquiaAgencia,
    responseHierarquiaAgencia,
    responseBuscarClientesDPS,
    responseBuscarAgenciaDPS,
    loadingBuscarClientesDPS,
    loadingBuscarAgenciaDPS,
    loadingSolicitaDPS,
    desabilitarVoltar,
    modalAlteracaoDetalhesOpen,
    itemSelecionado,
    modalRetornoAcaoDps,
  } = usePendentesDPS();

  const visaoCliente = responseBuscarClientesDPS?.tipofilhos;
  const tipoHierarquia = responseHierarquiaAgencia?.entidade?.tipoHierarquia;
  const dadosClientesDPS = responseBuscarClientesDPS?.filhos;
  const dadosAgenciaDPS = responseBuscarAgenciaDPS?.agencias;
  const visaoAgencia = verificarVisaoAgencia(tipoHierarquia);

  const loadingPainelDps = checkIfSomeItemsAreTrue([
    loadingBuscarClientesDPS,
    loadingBuscarAgenciaDPS,
  ]);

  const colunas = visaoAgencia
    ? colunasDPSAgenciaFactory(handleAbriModalAlteracaoDetalhes)
    : colunasDPSItemsFactory({ visaoCliente, handleChangeRotaUnidade });

  const totalDps = totalDpsFactory({
    visaoAgencia,
    dadosClientesDPS,
    dadosAgenciaDPS,
  });

  const dados = visaoAgencia
    ? tryGetValueOrDefault([dadosAgenciaDPS], [])
    : tryGetValueOrDefault([dadosClientesDPS], []);

  const exibirTotal = useMemo(
    () => !!removerMascaraValorMonetario(totalDps),
    [totalDps],
  );

  return (
    <>
      <HeaderPendentesDPS
        tipoHierarquia={getTernaryResult(
          loadingHierarquiaAgencia,
          '-',
          tryGetValueOrDefault([tipoHierarquia], '-'),
        )}
        codigoUnidade={unidade}
      />

      <Display type="display-block">
        <Card>
          <Card.Content>
            <TabelaPainelDPS
              dados={dados}
              colunas={colunas}
              loading={loadingPainelDps}
              agencia={visaoAgencia}
            />
            <RenderConditional condition={exibirTotal}>
              <Text variant="body02-sm" color="text" margin>
                <Display alignItems="center">
                  Total:{' '}
                  <RenderConditional condition={!loadingPainelDps}>
                    <strong>{totalDps}</strong>
                  </RenderConditional>
                  <RenderConditional condition={loadingPainelDps}>
                    <Skeleton lines={1} />
                  </RenderConditional>
                </Display>
              </Text>
            </RenderConditional>

            <Display>
              <Button
                type="submit"
                variant="outlined"
                disabled={desabilitarVoltar}
                onClick={handleBackStep}
              >
                Voltar
              </Button>
            </Display>
          </Card.Content>
        </Card>
        <ModalAlteracaoDetalhes
          handleSubmitForm={handleEnviarFormulario}
          open={modalAlteracaoDetalhesOpen}
          onClose={() => setModalAlteracaoDetalhesOpen(false)}
          dadosForm={itemSelecionado}
          loadingSolicitaDPS={loadingSolicitaDPS}
          initialValues={DADOS_INICIAL_AGENCIADPS}
        />
        <ModalRetornoAcaoDps
          openModal={modalRetornoAcaoDps.isOpen}
          handleClose={handleRetornoReenvioDps}
          message={modalRetornoAcaoDps.message}
        />
      </Display>
    </>
  );
};
