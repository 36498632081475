// @ts-nocheck
import React from 'react';
import { Text, Display } from '@cvp/design-system/react';

import {
  ATENCAO_COTAS_ABERTURA_APURACAO_FUNDOS,
  FUNDOS,
  IMPORTANTE,
  PRODUTOS_PGBL_VGBL,
  RENTABILIDADE_FUNDOS_INVESTIMENTOS,
  RESGATE_COTA_UTILIZADA_CALCULO_VALOR,
  TRANSFERENCIA_MUDANCA_FUNDO,
  TRANSFERENCIA_QUE_NAO_ALTERA_FUNDO,
} from '../../constants';

export interface IExtratoRentabilidadeCotasFooter {
  nomeFundos: string[] | undefined;
}

const ExtratoRentabilidadeCotasFooter: React.FC<
  IExtratoRentabilidadeCotasFooter
> = ({ nomeFundos }) => {
  return (
    <Display type="block">
      <Text variant="body02-sm" color="text-light" margin>
        {RENTABILIDADE_FUNDOS_INVESTIMENTOS}
      </Text>
      <Text variant="body01-md" color="primary" margin>
        {IMPORTANTE}
      </Text>
      <Text variant="body02-sm" color="text-light" margin>
        {PRODUTOS_PGBL_VGBL}
      </Text>
      <Text variant="body02-sm" color="text-light" margin>
        {TRANSFERENCIA_MUDANCA_FUNDO}
      </Text>
      <Text variant="body02-sm" color="text-light" margin>
        {TRANSFERENCIA_QUE_NAO_ALTERA_FUNDO}
      </Text>
      <Text variant="body02-sm" color="text-light" margin>
        {RESGATE_COTA_UTILIZADA_CALCULO_VALOR}
      </Text>
      <Text variant="body02-sm" color="text-light" margin>
        {ATENCAO_COTAS_ABERTURA_APURACAO_FUNDOS}
      </Text>
      <Text variant="body02-sm" color="text-light" margin>
        {FUNDOS}
      </Text>
      {nomeFundos?.map(Fundos => {
        return (
          <Text variant="body02-sm" color="text-light" margin>
            {Fundos}
          </Text>
        );
      })}
    </Display>
  );
};

export default ExtratoRentabilidadeCotasFooter;
