import { ChangeEvent } from 'react';
import {
  Display,
  Grid,
  Select,
  Button,
  TextField,
} from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { required } from 'main/features/Validation/validations';
import * as CONSTS from 'painelInadimplencia/constants/constants';
import { IFiltrosProps } from 'painelInadimplencia/types/PainelInadimplencia';
import { payloadRequest } from 'painelInadimplencia/types/ConsultarInadimplenciaPayload';
import masks from 'main/utils/masks';
import { tryGetValueOrDefault } from 'main/utils/conditional';

const Filtros = ({
  tipoVisao,
  payloadConsulta,
  setPayloadConsulta,
  consultarInadimplencia,
  filterFormaPagamento,
  handleBackStep,
  clearFilters,
  isDisabledBotaoVoltar,
}: IFiltrosProps) => {
  return (
    <>
      <Grid>
        <RenderConditional condition={tipoVisao === CONSTS.TiposVisao.AG}>
          <Grid.Item xl={1 / 4} lg={2 / 4} xs={1}>
            <Select
              dettach
              validationRules={[required()]}
              label="Periodicidade de Pagamento"
              placeholder="Escolha a opção"
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                const selectedValue = event.target.value;
                setPayloadConsulta((prevState: payloadRequest) => ({
                  ...prevState,
                  PeriodicidadePagamento: selectedValue,
                }));
              }}
            >
              {CONSTS.PeriodicidadePagamentoFilter.map(item => (
                <Select.Item
                  key={item.key}
                  text={item.label}
                  value={item.value}
                  selected={
                    item.value === payloadConsulta?.PeriodicidadePagamento
                  }
                />
              ))}
            </Select>
          </Grid.Item>

          <Grid.Item xl={1 / 4} lg={2 / 4} xs={1}>
            <Select
              dettach
              validationRules={[required()]}
              label="Forma de Pagamento"
              placeholder="Escolha a opção"
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                const selectedValue = event.target.value;
                setPayloadConsulta((prevState: payloadRequest) => ({
                  ...prevState,
                  FormaPagamento: selectedValue,
                }));
              }}
            >
              {filterFormaPagamento().map(item => (
                <Select.Item
                  key={item.key}
                  text={item.value}
                  value={item.value}
                  selected={item.value === payloadConsulta?.FormaPagamento}
                />
              ))}
            </Select>
          </Grid.Item>

          <Grid.Item xl={1 / 4} lg={2 / 4} xs={1}>
            <TextField
              name="cpfCnpj"
              label="CPF / CNPJ"
              placeholder="Digite o CPF/CNPJ"
              value={masks.cpfCnpj.mask(
                tryGetValueOrDefault([payloadConsulta?.cpfCnpj], ''),
              )}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                setPayloadConsulta((prevState: payloadRequest) => ({
                  ...prevState,
                  cpfCnpj: masks.cpfCnpj.unmask(event.target.value),
                }));
              }}
            />
          </Grid.Item>

          <Grid.Item xl={1 / 4} lg={2 / 4} xs={1}>
            <TextField
              name="numeroContrato"
              label="Número do Contrato"
              placeholder="Digite o número do contrato"
              value={tryGetValueOrDefault(
                [payloadConsulta?.numeroContrato],
                '',
              )}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                setPayloadConsulta((prevState: payloadRequest) => ({
                  ...prevState,
                  numeroContrato: event.target.value,
                }));
              }}
            />
          </Grid.Item>
        </RenderConditional>
      </Grid>

      <Display>
        <Button
          disabled={isDisabledBotaoVoltar}
          type="submit"
          variant="outlined"
          onClick={() => {
            handleBackStep();
          }}
        >
          Voltar
        </Button>

        <RenderConditional condition={tipoVisao === CONSTS.TiposVisao.AG}>
          <Button
            data-testid="filtrar"
            variant="secondary"
            onClick={() => consultarInadimplencia()}
          >
            Filtrar
          </Button>

          <Button
            data-testid="limpar-filtros"
            variant="outlined"
            onClick={clearFilters}
          >
            Limpar filtros
          </Button>
        </RenderConditional>
      </Display>
    </>
  );
};

export default Filtros;
