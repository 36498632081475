// @ts-nocheck
import {
  Accordion,
  Button,
  Card,
  Display,
  Text,
} from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { CardResumoPrestamista } from 'prestamista/components/CardResumoPrestamista';
import {
  Coberturas,
  CoberturasSkeleton,
} from 'prestamista/features/dadosSeguro/components/Coberturas';
import { DadosFinanciamento } from 'prestamista/features/dadosSeguro/components/DadosFinanciamento';
import { useDadosSeguroPrestamista } from 'prestamista/features/dadosSeguro/hooks/useDadosSeguroPrestamista';
import usePecoBeneficiarios from 'prestamista/hooks/usePecoBeneficiarios';
import usePecoCoberturaAssistencias from 'prestamista/hooks/usePecoCoberturaAssistencias';
import usePrestNavigation from 'prestamista/hooks/usePrestNavigation';
import { Assistencias } from '../components/Assistencias/Assistecias';
import { Beneficiarios } from '../components/Beneficiarios/Beneficiarios';

const DadosSeguro: React.FC = () => {
  const { goHomePrest } = usePrestNavigation();
  const { dadosFinanciamento, loading } = useDadosSeguroPrestamista();
  const {
    responseAssitencias,
    responseCoberturas,
    loadingCoberturaAssitencia,
  } = usePecoCoberturaAssistencias();
  const { responseBeneficirios, loadingBeneficiarios } = usePecoBeneficiarios();

  return (
    <>
      <CardResumoPrestamista />
      <Card>
        <Card.Content>
          <Text variant="headline-05" color="primary" margin>
            Dados do Seguro
          </Text>
          <DadosFinanciamento
            data={{ ...dadosFinanciamento }}
            loading={loading}
          />
          <RenderConditional condition={loadingCoberturaAssitencia}>
            <CoberturasSkeleton />
          </RenderConditional>
          <RenderConditional condition={!loading}>
            <Accordion open>
              <Accordion.Item
                title="Coberturas e Assistências"
                key="Coberturas e Assistências"
                data-testid="tabela-coberturas"
              >
                <Coberturas data={responseCoberturas} />
                <Assistencias data={responseAssitencias} />
              </Accordion.Item>
            </Accordion>
          </RenderConditional>
          <Beneficiarios
            data={responseBeneficirios}
            loading={loadingBeneficiarios}
          />
          <Display>
            <Button variant="outlined" onClick={goHomePrest}>
              Voltar
            </Button>
          </Display>
        </Card.Content>
      </Card>
    </>
  );
};

export default DadosSeguro;
