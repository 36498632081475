// @ts-nocheck
import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from 'main/components/Route';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import PainelInadimplenciaContextProvider from 'painelInadimplencia/contexts/PainelInadimplenciaContextProvider';
import RelatorioInadimplenciaHome from 'painelInadimplencia/pages/RelatorioInadimplencia';

const routes: IPrivateRoute[] = [
  {
    path: '/vida/inadimplencia',
    component: RelatorioInadimplenciaHome,
    key: 'vida-inadimplencia',
    breadcrumb: 'VIDA - Inadimplência',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_PJ,
    ],
  },
  {
    path: '/previdencia/contribuicoes-pendentes',
    component: RelatorioInadimplenciaHome,
    key: 'previdencia-contribuicoes-pendentes',
    breadcrumb: 'PREVIDÊNCIA - Contribuições Pendentes',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_PJ,
    ],
  },
];

export const faturamentoBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/faturamento': null,
  '/vida': null,
};

const RotasInadimplencia = (): React.ReactElement => (
  <Switch>
    <PainelInadimplenciaContextProvider>
      {routes.map(route => (
        <PrivateRoute
          key={route.key}
          path={route.path}
          exact={route.exact}
          component={route.component}
          authenticated={route.authenticated}
          requiredRoles={route.requiredRoles}
        />
      ))}
    </PainelInadimplenciaContextProvider>
  </Switch>
);

export default RotasInadimplencia;
