// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Card, Display, Divider, Grid, Text } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import SkeletonLoading from 'main/components/SkeletonLoading';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { obterLinkAtendimentoOnline } from 'previdencia/config/endpoints';
import useExerciciosDisponiveisIR from 'previdencia/features/ServicosIR/hooks/useObterExerciciosDisponiveisIR';
import ConsultaIRPorAno from 'previdencia/features/ServicosIR/components/ConsultaIRPorAno';
import { useObterInformeIR } from 'previdencia/hooks/useObterInformeIR';
import { useEnviarIREmail } from '../hooks/useEnviarIREmail';

const ServicosIR: React.FC = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { isLoadingExerciciosDisponiveisIR, responseExerciciosDisponiveisIR } =
    useExerciciosDisponiveisIR(cpfCnpj);
  const [anoSelecionado, setAnoSelecionado] = useState<string>('');

  const [emailDefaultCliente, setEmailDefaultCliente] = useState<string>('');
  const [recuperacaoEmailExecutada, setRecuperacaoEmailExecutada] =
    useState<boolean>(false);

  const [messageError, setMessageError] = useState<boolean>(false);
  const { responseIR, loading, obterIR } = useObterInformeIR(
    cpfCnpj,
    anoSelecionado,
  );

  const hookEmitirIR = useEnviarIREmail();

  const { atualizarParametrosIR } = hookEmitirIR;

  const recuperarEmailCliente = async (): Promise<void> => {
    try {
      setEmailDefaultCliente('');
      setRecuperacaoEmailExecutada(false);
    } catch {
      setEmailDefaultCliente('');
    } finally {
      setRecuperacaoEmailExecutada(true);
    }
  };

  useEffect(() => {
    recuperarEmailCliente();
  }, [numCertificado]);

  useEffect(() => {
    setMessageError(responseIR === null);
  }, [responseIR]);

  useEffect(() => {
    setMessageError(false);
    atualizarParametrosIR({
      cpfCnpj,
      anoBase: anoSelecionado,
      tipoPessoa: 'PF',
    });
  }, [anoSelecionado]);

  if (isLoadingExerciciosDisponiveisIR) {
    return <SkeletonLoading blocks={2} lines={4} />;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredPermissions={[PREV_PERMISSIONS.IMPOSTO_DE_RENDA]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
          USER_PROFILES.ANALISTA_PJ,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  Informe de Rendimentos
                </Text>

                <Text variant="body02-sm" margin>
                  O Informe de rendimentos para IR é o somatório das
                  contribuições pagas, saldos e movimentações vinculados ao CPF
                  e por isso, contempla todos os certificados do cliente.
                </Text>
                <Text variant="body02-sm">Escolha o ano que deseja ver:</Text>
              </Grid.Item>

              <ConsultaIRPorAno
                anoSelecionado={anoSelecionado}
                setAnoSelecionado={setAnoSelecionado}
                exerciciosIR={responseExerciciosDisponiveisIR}
                messageError={messageError}
                obterIR={obterIR}
                loading={loading}
                hookEmitirIR={hookEmitirIR}
                emailDefaultCliente={emailDefaultCliente}
                certificadoSelecionado={numCertificado}
                recuperacaoEmailExecutada={recuperacaoEmailExecutada}
              />

              <Grid.Item xs={1}>
                <Divider />
                <Text variant="body01-md" color="text-light">
                  Em caso de dúvidas, acesse o{' '}
                  <a
                    href={obterLinkAtendimentoOnline('573Do0000000Aj0')}
                    target="blank"
                  >
                    atendimento online
                  </a>
                  , disponível de segunda à sexta-feira das 9h às 21h.
                </Text>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default ServicosIR;
