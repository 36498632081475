// @ts-nocheck
import masks from 'main/utils/masks';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IMFTransferirProdutoRequest } from '../types/IMFTransferirProduto';
import { ITransferenciaAlteracaoTaxaContext } from '../types/ITransferenciaAlteracaoTaxaContext';
import { IUser } from 'main/features/Auth/interfaces';

export const gerarRequestMFTransferirProduto = (
  featureData: ITransferenciaAlteracaoTaxaContext,
  user: IUser,
): IMFTransferirProdutoRequest => {
  return {
    certificado: tryGetValueOrDefault([featureData?.certificado], ''),
    produto: {
      codigoProduto: tryGetValueOrDefault([featureData?.codigoProduto], ''),
    },
    codigoOficina: masks.agencyCode.mask(String(user?.agenciaVinculada)),
    codigoAgenteVenda: user.nomeAcesso,
    observacaoAlteracao: 'Transferencia com emissão - FOP 223',
    fundos: tryGetValueOrDefault([featureData.fundosSelecionados], []).map(
      item => ({
        codigoReserva: item.codigoReserva,
        codigoFundo: item.codigoFundo,
        percentualDistribuicao: Number(item.percentualDistribuicao),
        indicadorContribuicaoFundo: item.indicadorContribuicaoFundo,
      }),
    ),
    valorContribuicaoPeculio: tryGetValueOrDefault(
      [featureData?.valorPeculio],
      0,
    ),
    valorContribuicaoPensao: tryGetValueOrDefault(
      [featureData?.valorPensao],
      0,
    ),
    valorContribuicaoRenda: tryGetValueOrDefault(
      [featureData?.valorContribuicao],
      0,
    ),
    prazoPensao: 0,
  };
};
