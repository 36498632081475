// @ts-nocheck
import React, { useState } from 'react';
import { Container, Display, Text, Radio } from '@cvp/design-system/react';
import Input from 'main/components/form/Input';
import RenderConditional from 'main/components/RenderConditional';
import masks from 'main/utils/masks';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import useFieldLink from 'main/hooks/useFieldLink';
import { validate } from 'main/features/Validation/utils/validateRules';
import { scrollEndScreen } from 'previdencia/features/AlterarValorContribuicao/utils/fundosUtils';
import { AlterarValorContribuicaoBeneficioProtecaoProps } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoComponentProps';
import { OpcoesProtecaoBeneficio } from 'previdencia/features/AlterarValorContribuicao/types/InitialStateTypes';
import { initialStateOpcoesProtecaoBeneficio } from 'previdencia/features/AlterarValorContribuicao/factories/initialStateFactory';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';
import {
  Botao,
  ContainerInput,
  ContainerInputChild,
} from 'previdencia/features/AlterarValorContribuicao/components/styles';

const AlterarValorContribuicaoBeneficioProtecao: React.FC<
  AlterarValorContribuicaoBeneficioProtecaoProps
> = ({
  resumoContribuicao,
  dadosBeneficioProtecao,
  setNovoValorTipoBeneficio,
  loadingCalculoBeneficioProtecao,
  calculoBeneficioProtecao,
}) => {
  const [opcoesProtecaoBeneficio, setOpcoesProtecaoBeneficio] =
    useState<OpcoesProtecaoBeneficio>(initialStateOpcoesProtecaoBeneficio);

  const [descricaoTipoBeneficio, setDescricaoTipoBeneficio] =
    useState<string>('');

  const [valorPeculioLink, validarValorPeculioLink] = useFieldLink('0');
  const [valorPensaoLink, validarValorPensaoLink] = useFieldLink('0');
  const contribuicaoCustomLink = (valorContribuicao: number) => ({
    value: valorContribuicao.toFixed(2),
    isValid: true,
  });

  const alterarValorPeculio = async (): Promise<void> => {
    if (validate([validarValorPeculioLink])) {
      const valorConvertido =
        Number(masks.currencyInput.unmask(valorPeculioLink.get().value)) / 100;

      setNovoValorTipoBeneficio(DefaultValue.TIPO_BENEFICIO_PECULIO);

      calculoBeneficioProtecao({
        tipoBeneficio: DefaultValue.TIPO_BENEFICIO_PECULIO,
        descricaoTipoBeneficio,
        novoValor: valorConvertido,
        prazo: tryGetValueOrDefault([dadosBeneficioProtecao.peculio.prazo], 0),
      });
    }
  };

  const alterarValorPensao = async (): Promise<void> => {
    if (validate([validarValorPensaoLink])) {
      const valorConvertido =
        Number(masks.currencyInput.unmask(valorPensaoLink.get().value)) / 100;

      setNovoValorTipoBeneficio(DefaultValue.TIPO_BENEFICIO_PENSAO);

      calculoBeneficioProtecao({
        tipoBeneficio: DefaultValue.TIPO_BENEFICIO_PENSAO,
        descricaoTipoBeneficio,
        novoValor: valorConvertido,
        prazo: tryGetValueOrDefault([dadosBeneficioProtecao.pensao.prazo], 0),
      });
    }
  };

  const handleOpcaoPeculio = (valorContribuicaoAtual: number): void => {
    if (!opcoesProtecaoBeneficio.peculio) {
      setOpcoesProtecaoBeneficio({
        ...initialStateOpcoesProtecaoBeneficio,
        peculio: true,
      });

      if (valorContribuicaoAtual) {
        valorPeculioLink.set(contribuicaoCustomLink(valorContribuicaoAtual));
      }
    }
  };

  const handleOpcaoPensao = (valorContribuicaoAtual: number): void => {
    if (!opcoesProtecaoBeneficio.pensao) {
      setOpcoesProtecaoBeneficio({
        ...initialStateOpcoesProtecaoBeneficio,
        pensao: true,
      });

      if (valorContribuicaoAtual) {
        valorPensaoLink.set(contribuicaoCustomLink(valorContribuicaoAtual));
      }
    }
  };

  const handleSelecionar = (
    tipoBeneficioRI: string,
    valorContribuicaoAtual: number,
  ): void => {
    if (tipoBeneficioRI === DefaultValue.DESCRICAO_TIPO_BENEFICIO_PECULIO) {
      handleOpcaoPeculio(valorContribuicaoAtual);
    }

    if (tipoBeneficioRI === DefaultValue.DESCRICAO_TIPO_BENEFICIO_PENSAO) {
      handleOpcaoPensao(valorContribuicaoAtual);
    }

    setDescricaoTipoBeneficio(tipoBeneficioRI);
    scrollEndScreen();
  };

  const exibirOpcaoBeneficioProtecao = (tipoBeneficioRI: string): boolean => {
    if (
      tipoBeneficioRI === DefaultValue.DESCRICAO_TIPO_BENEFICIO_PECULIO &&
      dadosBeneficioProtecao.peculio.alterar ===
        DefaultValue.PERMITIR_ALTERACAO_PECULIO
    ) {
      return true;
    }

    if (
      tipoBeneficioRI === DefaultValue.DESCRICAO_TIPO_BENEFICIO_PENSAO &&
      dadosBeneficioProtecao.peculio.alterar ===
        DefaultValue.PERMITIR_ALTERACAO_PENSAO
    ) {
      return true;
    }

    return false;
  };

  return (
    <Container margin={10}>
      <Text variant="body02-sm" fontWeight="bold">
        Qual benefício de proteção deseja alterar o valor?
      </Text>
      {resumoContribuicao.listaProtecoesAtivas.map((item, idx) => {
        return (
          <RenderConditional
            condition={exibirOpcaoBeneficioProtecao(item.incomeDescription)}
          >
            <Display alignItems="center" key={item.incomeDescription}>
              <Radio
                name="group1"
                value={idx}
                onChange={() =>
                  handleSelecionar(
                    item.incomeDescription,
                    item.initialContributionValue,
                  )
                }
              />
              <Text variant="body02-sm" margin>
                Alterar valor da contribuição de {item.incomeDescription}
              </Text>
            </Display>
          </RenderConditional>
        );
      })}

      <RenderConditional condition={opcoesProtecaoBeneficio.peculio}>
        <ContainerInput>
          <Text variant="body02-sm" marginTop={20}>
            Qual o novo valor deseja contribuir para o{' '}
            <strong>pecúlio por morte?</strong>
          </Text>
          <ContainerInputChild>
            <Input link={valorPeculioLink} inputMask={masks.currencyInput} />
            <Botao
              onClick={alterarValorPeculio}
              variant="secondary"
              loading={loadingCalculoBeneficioProtecao}
            >
              Alterar
            </Botao>
          </ContainerInputChild>
        </ContainerInput>
      </RenderConditional>

      <RenderConditional condition={opcoesProtecaoBeneficio.pensao}>
        <ContainerInput>
          <Text variant="body02-sm" marginTop={20}>
            Qual o novo valor que deseja contribuir para a{' '}
            <strong>pensão por prazo certo?</strong>
          </Text>
          <ContainerInputChild>
            <Input link={valorPensaoLink} inputMask={masks.currencyInput} />
            <Botao
              onClick={alterarValorPensao}
              variant="secondary"
              loading={loadingCalculoBeneficioProtecao}
            >
              Alterar
            </Botao>
          </ContainerInputChild>
        </ContainerInput>
      </RenderConditional>
    </Container>
  );
};

export default AlterarValorContribuicaoBeneficioProtecao;
