// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { getTimer15Minutos } from 'main/utils';
import { useAuth } from 'main/features/Auth/hooks';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import * as REQUEST_TYPES from 'painelInadimplencia/types/ConsultarInadimplenciaRequest';
import * as RESPONSE_TYPES from 'painelInadimplencia/types/ConsultarInadimplenciaResponse';
import { payloadRequest } from 'painelInadimplencia/types/ConsultarInadimplenciaPayload';
import useSegmento from 'painelInadimplencia/hooks/useSegmento';

const usePecoConsultarInadimplencia = (payload?: payloadRequest) => {
  const { obterSegmentoRota } = useSegmento();
  const { user } = useAuth();
  const {
    response: dadosInadimplencia,
    loading: loadingDadosInadimplencia,
    fetchData,
  } = usePeco<
    REQUEST_TYPES.IDadosInadimplenciaPayload,
    RESPONSE_TYPES.IDadosInadimplenciaResponse
  >({
    api: { operationPath: PECOS_VIDA.ConsultarAdimplencia },
    autoFetch: false,
    cache: true,
    cacheKey: `${tryGetValueOrDefault(
      [payload?.Codigo],
      '',
    )},${obterSegmentoRota()},${tryGetValueOrDefault(
      [payload?.FormaPagamento],
      '',
    )},${tryGetValueOrDefault(
      [payload?.PeriodicidadePagamento],
      '',
    )},${tryGetValueOrDefault(
      [payload?.numeroContrato],
      '',
    )},${tryGetValueOrDefault([payload?.cpfCnpj], '')}`,
    cacheTime: getTimer15Minutos(),
    handleResponse: { throwToastErrorBFF: false },

    payload: {
      codigo: tryGetValueOrDefault([payload?.Codigo], user?.agenciaVinculada),
      segmento: obterSegmentoRota(),
      periodicidadePagamento: tryGetValueOrDefault(
        [payload?.PeriodicidadePagamento],
        '',
      ),
      formaPagamento: tryGetValueOrDefault([payload?.FormaPagamento], ''),
      numeroContrato: tryGetValueOrDefault([payload?.numeroContrato], ''),
      cpfCnpj: tryGetValueOrDefault([payload?.cpfCnpj], ''),
    },
  });

  return {
    dadosInadimplencia: dadosInadimplencia?.entidade,
    loadingDadosInadimplencia,
    fetchData,
  };
};

export default usePecoConsultarInadimplencia;
