// @ts-nocheck
import React, {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
  useEffect,
  useMemo,
} from 'react';
import { Grid, Checkbox, Text, Display } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import {
  COLUNAS_TABELA_FUNDOS,
  TabelaFundosDefaultValue,
} from 'previdencia/constants/constants';
import {
  Fundo,
  TipoResgate,
  TipoInputRefs,
  TipoRetornoValidaFundos,
  valorTotalTabelaFundos,
  QuantidadeMaximaFundosPorTipos,
} from 'previdencia/types/Fundo.type';
import SelectTipoResgate from 'previdencia/components/SelectTipoResgate';
import Table from 'main/components/Table';
import {
  verificarInclusaoFundosTotais,
  somarFundosSelecionados,
  modificarFundoSelecionado,
  validaFundos,
  modificarFundoPorTipoTransferencia,
  desabilitarFundoEntreTiposDiferentes,
  fundosAtivos,
} from 'previdencia/utils/tabelaFundos';
import { levelRisk } from 'previdencia/utils/riskStatus';
import Modal from 'main/components/Modal';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { useTransferenciaFundosContext } from 'previdencia/features/TransferenciaFundoInvestimento/contexts/ContextTransferenciaFundos';
import masks from 'main/utils/masks';
import { ITransferenciaFundosFeatureData } from 'previdencia/features/TransferenciaFundoInvestimento/types/ITransferenciaFundosFeatureData';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import InputRedistribuirValor from '../InputRedistribuirValor';
import { RiskBarStyle } from '../stylesPrevidencia';

export interface TabelaFundosHandles {
  validateFundos: () => TipoRetornoValidaFundos | undefined;
}

type TabelaFundosProps = {
  fundos: Fundo[];
  valorMinimoContribuicao?: string;
  quantidadeMaximaFundos: number;
  isLoading: boolean;
  valorTotal: number | undefined;
  resgate: boolean | undefined;
  tipoResgate: TipoResgate | undefined;
  setFundosSelecionados?: (data: Fundo[]) => void;
  descricaoTabela?: React.ReactElement;
  quantidadeMaximaFundosPorTipos?: QuantidadeMaximaFundosPorTipos;
};

const TabelaFundos: React.ForwardRefRenderFunction<
  TabelaFundosHandles,
  TabelaFundosProps
> = (
  {
    fundos,
    valorMinimoContribuicao,
    quantidadeMaximaFundos,
    isLoading,
    valorTotal,
    resgate,
    tipoResgate,
    descricaoTabela,
    setFundosSelecionados = () => undefined,
    quantidadeMaximaFundosPorTipos,
  },
  ref,
) => {
  const { etapa } = useTransferenciaFundosContext();
  const { featureData } =
    usePrevidenciaContext<ITransferenciaFundosFeatureData>();
  const colunas = useMemo(
    () => [
      ...COLUNAS_TABELA_FUNDOS,
      {
        name: resgate ? 'Tipo de Transferência' : 'Valor de Contribuição Atual',
        minWidth: '140px',
        selector: (row: Fundo) => row.dadoGenerico,
      },
      {
        name: 'Valor',
        minWidth: '140px',
        selector: (row: Fundo) => row.valor,
      },
    ],
    [],
  );

  const [tabelaFundos, setTabelaFundos] = useState<Fundo[]>(
    tryGetValueOrDefault([fundos], []),
  );
  const inputRedistribuirValorRefs = useRef<Record<string, TipoInputRefs>>({});
  const [desabilitaTotal, setDesabilitaTotal] = useState<boolean>(false);
  const valorTotalTabela: valorTotalTabelaFundos[] = [];
  const [somaValorTotal, setSomaValorTotal] = useState<number>(0);
  const [
    modalQtdeMaximaFundosSelecionados,
    setModalQtdeMaximaFundosSelecionados,
  ] = useState(false);
  const execedeuQuantidadeMaximaPermitida =
    tabelaFundos.filter(x => x.selecionado).length > quantidadeMaximaFundos;

  const qtdeMaximaFundosSelecionados = [
    !resgate,
    execedeuQuantidadeMaximaPermitida,
  ].every(x => x);

  const validateFundos = () =>
    validaFundos(inputRedistribuirValorRefs, tabelaFundos, valorTotal, resgate);

  useImperativeHandle(ref, () => {
    return { validateFundos };
  });

  const handleSelectedFund = (id: string): void => {
    setTabelaFundos(prevFundos => {
      let fundosSelecionados = prevFundos.map(fundo => {
        return modificarFundoSelecionado(fundo, id);
      });

      fundosSelecionados = desabilitarFundoEntreTiposDiferentes(
        fundosSelecionados,
        quantidadeMaximaFundosPorTipos as QuantidadeMaximaFundosPorTipos,
      );

      setFundosSelecionados(fundosSelecionados);

      return fundosSelecionados;
    });

    setDesabilitaTotal(
      verificarInclusaoFundosTotais(tabelaFundos, id, tipoResgate) === true,
    );
  };

  const handleTipoTransferencia = (
    tipoTransferencia: string,
    id: string,
    saldo: number,
  ): void => {
    setTabelaFundos(prevFundos => {
      const fundosSelecionados = modificarFundoPorTipoTransferencia(
        prevFundos,
        tipoTransferencia,
        id,
        saldo,
      );
      setFundosSelecionados(fundosSelecionados);
      return fundosSelecionados;
    });
  };

  useEffect(() => {
    setTabelaFundos(fundos);
  }, [isLoading]);

  useEffect(() => {
    if (qtdeMaximaFundosSelecionados)
      setModalQtdeMaximaFundosSelecionados(true);
  }, [qtdeMaximaFundosSelecionados]);

  if (isLoading) {
    return <SkeletonLoading blocks={1} />;
  }

  const exibirValorMinimo = (
    valorMinimo: number,
    valorMinContribuicao: number,
  ) => {
    if (
      checkIfAllItemsAreTrue([
        etapa === 'definirFundosDestino',
        !!featureData?.transferenciaTotal,
      ])
    )
      return 0;

    return tryGetValueOrDefault([valorMinimo], valorMinContribuicao);
  };

  const formatData =
    tabelaFundos?.map(
      ({
        saldo,
        codFundo,
        valor,
        perfilRisco,
        selecionado,
        contribuicaoAtual,
        rentabilidade,
        valorMinimo,
        isParcial,
        ...rest
      }) => ({
        check: (
          <Checkbox
            id={codFundo}
            disabled={
              checkIfAllItemsAreTrue([
                !selecionado,
                fundosAtivos(tabelaFundos) === quantidadeMaximaFundos,
              ]) || rest.desabilitado
            }
            checked={selecionado}
            onChange={() => handleSelectedFund(codFundo)}
          />
        ),
        perfil: (
          <RiskBarStyle
            risk={levelRisk(
              tryGetValueOrDefault(
                [perfilRisco],
                TabelaFundosDefaultValue.PERFIL_RISCO_MODERADO,
              ),
            )}
          />
        ),
        saldo: formatarValorPadraoBrasileiro(saldo),
        dadoGenerico: resgate ? (
          <SelectTipoResgate
            onTipoTransferencia={(tipoTransferencia: string) =>
              handleTipoTransferencia(tipoTransferencia, codFundo, saldo)
            }
            tipoResgate={tipoResgate}
            hidden={!selecionado}
            desabilitaTotal={desabilitaTotal}
          />
        ) : (
          formatarValorPadraoBrasileiro(contribuicaoAtual)
        ),
        valor: (
          <InputRedistribuirValor
            ref={inputRedistribuir => {
              valorTotalTabela[parseFloat(codFundo)] = {
                value: tryGetValueOrDefault([inputRedistribuir?.value], 0),
                cod: codFundo,
                selecionado,
              };
              setSomaValorTotal(somarFundosSelecionados(valorTotalTabela));
              inputRedistribuirValorRefs.current[codFundo] = inputRedistribuir;
            }}
            valorMinimo={exibirValorMinimo(
              valorMinimo,
              Number(valorMinimoContribuicao),
            )}
            valorInicialInput={valor}
            isResgate={resgate}
            hidden={checkIfAllItemsAreTrue([
              !selecionado || !isParcial,
              !(selecionado && !resgate),
            ])}
          />
        ),

        rentabilidade: `${rentabilidade}%`,
        ...rest,
      }),
    ) ?? [];

  return (
    <Grid.Item xs={1}>
      <RenderConditional condition={!!descricaoTabela}>
        <Display>{descricaoTabela}</Display>
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !resgate,
          etapa === 'definirFundosDestino',
        ])}
      >
        <Text variant="body02-md" margin>
          Você selecionou{' '}
          <strong>{tabelaFundos.filter(x => x.selecionado).length}</strong> de{' '}
          <strong>{quantidadeMaximaFundos}</strong> fundos possíveis.
        </Text>
      </RenderConditional>

      <Table
        noHeader
        responsive
        data={quantidadeMaximaFundos === 0 ? [] : formatData}
        columns={tryGetValueOrDefault([colunas], [])}
        noDataComponent="Não há dados para exibir."
      />
      <Text variant="body02-md">
        Total: <strong>{formatarValorPadraoBrasileiro(somaValorTotal)}</strong>
      </Text>
      <RenderConditional condition={etapa === 'definirFundosDestino'}>
        <Text variant="body02-md">
          Restante:{' '}
          <strong>
            {masks.currency.mask(
              tryGetValueOrDefault([valorTotal], 0) - somaValorTotal,
            )}
          </strong>
        </Text>
      </RenderConditional>

      <Modal
        open={modalQtdeMaximaFundosSelecionados}
        onClose={() => setModalQtdeMaximaFundosSelecionados(false)}
      >
        <p>Você atingiu a quantidade máxima de fundos permitida.</p>
      </Modal>
    </Grid.Item>
  );
};

export default forwardRef(TabelaFundos);
