// @ts-nocheck
import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'main/components/Route';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import Consulta from 'consultaCliente/features/pesquisaCliente/pages/BuscaCliente';
import ClienteProdutos from 'consultaCliente/features/listaCardProduto/pages/ConsultaResultado';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';

const routes: IPrivateRoute[] = [
  {
    path: '/cliente',
    component: Consulta,
    key: 'cliente-consulta',
    breadcrumb: 'Consulta',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_PJ,
    ],
  },
  {
    path: '/cliente/produtos',
    component: ClienteProdutos,
    key: 'cliente-produtos',
    breadcrumb: 'Produtos',
    exact: true,
    authenticated: true,
    withSidebar: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_PJ,
    ],
  },
];

/**
 * Utilizar caso seja necessário personalizar o nome dos breadcrumbs
 */
export const consultaClienteBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
};

const RotasConsultaCliente = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);

export default RotasConsultaCliente;
