// @ts-nocheck
import { Card, Display, Text, Button } from '@cvp/design-system/react';
import * as S from './styles';
import Icon from 'main/components/Icon';
import TransferenciaWrapper from '../components/TransferenciaWrapper/TransferenciaWrapper';
import useComprovante from '../hooks/useComprovante';

export const Comprovante = () => {
  const { fetchData, loading } = useComprovante();

  return (
    <TransferenciaWrapper>
      <Display type="block">
        <Card>
          <Card.Content>
            <Text variant="headline-05" color="primary" margin>
              A transferência foi realizada com sucesso!
            </Text>
            <Text variant="caption">
              Caso necessário, consulte no Histórico de Solicitações.
            </Text>

            <S.ComprovanteSection>
              <div>
                <Text variant="caption">
                  <Icon name="documentPaper" /> <strong>Comprovante</strong>
                </Text>
              </div>
              <Display alignItems="center" style={{ marginTop: 20 }}>
                <Button
                  onClick={() => fetchData()}
                  loading={loading}
                  disabled={loading}
                  small
                  variant="outlined"
                >
                  <Icon name="print" />
                  Visualizar ou imprimir
                </Button>
              </Display>
            </S.ComprovanteSection>
          </Card.Content>
        </Card>
      </Display>
    </TransferenciaWrapper>
  );
};
