// @ts-nocheck
import { useAuth } from 'main/features/Auth/hooks';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';

const PRIORIDADES_PERFIS = [
  USER_PROFILES.MEDICO,
  USER_PROFILES.ANALISTA_PRODUTO,
  USER_PROFILES.OPERADOR,
  USER_PROFILES.ANALISTA_CVP,
  USER_PROFILES.ANALISTA_TI,
  USER_PROFILES.ANALISTA_MANUTENCAO,
  USER_PROFILES.ANALISTA_SAIDA,
  USER_PROFILES.ANALISTA_ENTRADA,
  USER_PROFILES.ANALISTA_CONSULTA,
  USER_PROFILES.ECONOMIARIO,
  USER_PROFILES.ANALISTA_PJ,
];

export const useObterPerfilUsuario = () => {
  const { userRoles } = useAuth();

  const userHasProfile = (profile: string) => userRoles.includes(profile);
  const userProfile = PRIORIDADES_PERFIS.find(userHasProfile);
  return userProfile || '';
};
