// @ts-nocheck
import { levelRisk } from 'previdencia/utils/riskStatus';
import ColunaSelecionarFundo from '../components/TabelaContribuicaoRegular/ColunaSelecionarFundo';
import {
  TTabelaContribuicaoRegular,
  IDadosTabelaContribuicaoRegularProps,
} from '../types/TableColumns';
import RiskBar from 'previdencia/components/RiskBar';
import { IFundo } from '../types/VGBLListarOfertas';

export const colunasTabelaContribuicaoRegular: TTabelaContribuicaoRegular = ({
  handlers,
}: IDadosTabelaContribuicaoRegularProps) => [
  {
    name: '',
    width: '20px',
    selector: (row: IFundo) => row.check,
    cell: (row: IFundo) => (
      <ColunaSelecionarFundo row={row} handlers={handlers} />
    ),
  },
  {
    name: 'Fundos',
    selector: row => row.descricaoFundo,
    wrap: true,
  },
  {
    name: 'Perfil de Risco',
    selector: row => row.perfilRiscoNivel,
    center: true,
    cell: (row: IFundo) => (
      <div style={{ minHeight: 50, minWidth: 150 }}>
        <RiskBar risk={levelRisk(row.perfilRiscoNivel)} />
      </div>
    ),
  },
  {
    name: 'Rentabilidade (Últimos 12 Meses)',
    selector: row => row.percentualRentabilidadeUltimoAno,
    center: true,
  },
];
