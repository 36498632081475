import React from 'react';
import { Display, Text } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { DEFAULT_NO_DATA_TABLE } from 'main/constants/messages';
import useRelatorioAgencia from 'painelInadimplencia/hooks/useRelatorioAgencia';
import ModalDetalhamentoParcelas from 'painelInadimplencia/components/ModalDetalhamentoParcelas';
import ModalDetalhesInadimplencia from 'painelInadimplencia/components/ModalDetalhes/ModalDetalhesInadimplencia';
import ModalValidaEmissaoBoleto from 'painelInadimplencia/components/ModalValidaEmissaoBoleto';
import { TableInadimplencia } from 'painelInadimplencia/pages/TableInadimplencia';
import { RelatorioInadimplenciaProps } from 'painelInadimplencia/types/ConsultaInadimplenciaUsePeco';

const RelatorioAgencia: React.FC<RelatorioInadimplenciaProps> = ({
  loading,
  data,
}) => {
  const {
    listaPagamentosDetalhado,
    detalhesAdimplenciaAgencia,
    colunasDetalhesAgencia,
    colunasDetalhesPagamentos,
    openModalDetalhesAgencia,
    openModalPagamento,
    openModalValidaEmissaoBoleto,
    isLoadingListaPagamentosAdimplencia,
    handleToggleModalDetalhesAdimplencia,
    handleToggleModalPagamento,
    handleToggleModalValidaEmissaoBoleto,
  } = useRelatorioAgencia();

  return (
    <>
      <RenderConditional condition={!loading}>
        <Display>
          <TableInadimplencia
            noHeader
            responsive
            striped
            highlightOnHover
            data={tryGetValueOrDefault([data?.dados], [])}
            columns={colunasDetalhesAgencia}
            noDataComponent={DEFAULT_NO_DATA_TABLE}
            pagination
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: 'Items por página',
              rangeSeparatorText: 'de',
            }}
          />
        </Display>
        <Display>
          <RenderConditional condition={!!data?.valorTotalInadimplencia}>
            {' '}
            <Text variant="body02-sm" color="text" margin>
              Total:{' '}
              {tryGetMonetaryValueOrDefault(data?.valorTotalInadimplencia)}
            </Text>{' '}
          </RenderConditional>
        </Display>
      </RenderConditional>

      <ModalDetalhesInadimplencia
        pagamentoDetalhado={listaPagamentosDetalhado?.at(0)}
        dados={detalhesAdimplenciaAgencia}
        open={openModalDetalhesAgencia}
        isLoading={isLoadingListaPagamentosAdimplencia}
        onClose={handleToggleModalDetalhesAdimplencia}
      />

      <ModalDetalhamentoParcelas
        colunasDetalhesPagamentos={colunasDetalhesPagamentos}
        listaPagamentosDetalhado={listaPagamentosDetalhado}
        open={openModalPagamento}
        isLoading={isLoadingListaPagamentosAdimplencia}
        onClose={handleToggleModalPagamento}
      />

      <ModalValidaEmissaoBoleto
        open={openModalValidaEmissaoBoleto}
        onClose={handleToggleModalValidaEmissaoBoleto}
      />
    </>
  );
};

export default RelatorioAgencia;
