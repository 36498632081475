// @ts-nocheck
import React, { useState, useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { Text, Display, Card, Button } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import Modal from 'main/components/Modal';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import getAuthData from 'main/features/Auth/utils/auth';
import { dataPorExtenso } from 'main/utils/date';
import usePrevSummary from 'previdencia/hooks/usePrevSummary';
import ResultadoSolicitacaoTabelas from 'previdencia/features/AlterarValorContribuicao/components/ResultadoSolicitacaoTabelas';
import { SolicitarAlteracaoContribuicaoRendaProps } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoComponentProps';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';
import * as CONSTS from 'previdencia/features/AlterarValorContribuicao/constants/constants';

const SolicitarAlteracaoContribuicaoRenda: React.FC<
  SolicitarAlteracaoContribuicaoRendaProps
> = ({
  tabelaAtualizada,
  tabelaAntiga,
  resultadoSolicitacaoContribuicaoRenda,
  voltarTelaAlterarValorContribuicao,
}) => {
  const { cliente } = useContext(AppContext);
  const { informacaoPorCertificado } = usePrevSummary();
  const [openModal, setOpenModal] = useState<boolean>(
    tryGetValueOrDefault(
      [!resultadoSolicitacaoContribuicaoRenda.possuiMensagemAlerta],
      false,
    ),
  );
  const { agenciaVinculada, nomeUsuario } = getAuthData().user;
  const dataAtualPorExtenso = dataPorExtenso(new Date());

  const tabelaAtualizadaComNovoValor = tabelaAtualizada.filter(
    item => !!item.valor,
  );

  const exibirMensagemErroAmigavel = (): string => {
    if (
      resultadoSolicitacaoContribuicaoRenda.lstMensagens.status ===
      DefaultValue.STATUS_ERRO_SOLICITACAO_CONTRIBUICAO
    ) {
      return CONSTS.MSG_ERRO_AMIGAVEL_SOLICITACAO_CONTRIBUICAO;
    }

    return tryGetValueOrDefault(
      [resultadoSolicitacaoContribuicaoRenda.lstMensagens.desMensagem],
      '',
    );
  };

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[4, 4]}>
          <Text variant="headline-05" color="primary">
            Solicitação de Alteração da Contribuição de Renda
          </Text>
        </Card.Content>

        <RenderConditional
          condition={
            resultadoSolicitacaoContribuicaoRenda.possuiMensagemErro ===
            DefaultValue.POSSUI_MENSAGEM
          }
        >
          <Card.Content padding={[4, 4]}>
            <Text variant="body02-lg" marginBottom={10}>
              {exibirMensagemErroAmigavel()}
            </Text>
          </Card.Content>
        </RenderConditional>

        <RenderConditional
          condition={
            resultadoSolicitacaoContribuicaoRenda.possuiMensagemSucesso ===
            DefaultValue.POSSUI_MENSAGEM
          }
        >
          <Card.Content padding={[4, 4]}>
            <Text variant="body02-lg" fontWeight="bold" marginBottom={10}>
              Situação Alteração da Contribuição de Renda
            </Text>

            <Text variant="body02-sm">
              <strong>Certificado:</strong> {cliente?.numCertificado}
            </Text>

            <Text variant="body02-sm">
              <strong>Produto:</strong>{' '}
              {informacaoPorCertificado?.produto.descricaoProduto}
            </Text>

            <Text variant="body02-sm">
              <strong>Número da Solicitação:</strong>{' '}
              {tryGetValueOrDefault(
                [
                  resultadoSolicitacaoContribuicaoRenda?.dados
                    .codManutencaoCertificado,
                ],
                '--',
              )}
            </Text>

            <Text variant="body02-sm">
              <strong>Data/Hora:</strong> {dataAtualPorExtenso}
            </Text>
          </Card.Content>

          <Card.Content padding={[6, 4]}>
            <Text variant="body02-lg" fontWeight="bold" marginBottom={10}>
              Dados da Solicitação
            </Text>

            <ResultadoSolicitacaoTabelas
              fundosAntigos={tabelaAntiga}
              fundosAtualizados={tabelaAtualizadaComNovoValor}
            />
          </Card.Content>

          <Card.Content padding={[4, 4]}>
            <Text variant="body02-sm">
              <strong>Data da Operação:</strong> {dataAtualPorExtenso}
            </Text>

            <Text variant="body02-sm">
              <strong>Por:</strong> {nomeUsuario}
            </Text>

            <Text variant="body02-sm">
              <strong>Canal:</strong> Agência {agenciaVinculada}
            </Text>

            <Text variant="body02-sm">
              <strong>Documento Gerado em:</strong> {dataAtualPorExtenso}
            </Text>
          </Card.Content>
        </RenderConditional>
      </Card>

      <Button variant="outlined" onClick={voltarTelaAlterarValorContribuicao}>
        Voltar
      </Button>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Text variant="body02-md">
          Você mudou seu perfil de investimento para um mais{' '}
          <strong>arriscado</strong>!!
        </Text>
      </Modal>
    </Display>
  );
};

export default SolicitarAlteracaoContribuicaoRenda;
