// @ts-nocheck
import React from 'react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { Text, Grid, Divider, Display } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { ValoresContribuicaoProps } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoComponentProps';

const ValoresContribuicao: React.FC<ValoresContribuicaoProps> = ({
  resumoContribuicao,
  loadingValoresContribuicao,
}) => {
  return (
    <Display padding={0} marginBottom={30}>
      <Text variant="headline-05" color="primary" margin>
        Alteração do Valor de Contribuiçãooo
      </Text>
      <Text variant="body02-sm">
        <strong>
          Confira como está o valor de contribuição para Sobrevivência, em cada
          um dos fundos de investimento.
        </strong>
      </Text>
      <Divider />

      <RenderConditional condition={loadingValoresContribuicao}>
        <SkeletonLoading blocks={1} />
      </RenderConditional>

      <RenderConditional condition={!loadingValoresContribuicao}>
        <Grid>
          <Grid.Item xs={1}>
            <Text variant="body02-sm" marginBottom={10}>
              O valor total de contribuição atual é de:{' '}
              <strong>
                {tryGetMonetaryValueOrDefault(
                  resumoContribuicao.valorTotalContribuicoesAtivas,
                )}{' '}
              </strong>
              distribuídos da seguinte forma:
            </Text>

            <Text variant="body02-sm">
              Valor total de <strong>sobrevivência</strong>:{' '}
              <strong>
                {tryGetMonetaryValueOrDefault(
                  resumoContribuicao.valorTotalSobrevivenciasAtivas,
                )}
                .
              </strong>
            </Text>

            <Text variant="body02-sm">
              Valor total de <strong>proteção</strong>:{' '}
              <strong>
                {tryGetMonetaryValueOrDefault(
                  resumoContribuicao.valorTotalProtecoesAtivas,
                )}
                .
              </strong>
            </Text>

            <RenderConditional
              condition={resumoContribuicao.listaProtecoesAtivas.length > 1}
            >
              {resumoContribuicao.listaProtecoesAtivas.map(protecao => (
                <Text
                  variant="body02-sm"
                  paddingLeft="10"
                  key={protecao.incomeDescription}
                >
                  - Valor da contribuição de{' '}
                  <strong>{protecao.incomeDescription}</strong>:{' '}
                  <strong>
                    {tryGetMonetaryValueOrDefault(
                      protecao.initialContributionValue,
                    )}
                    .
                  </strong>
                </Text>
              ))}
            </RenderConditional>
          </Grid.Item>
        </Grid>
      </RenderConditional>
    </Display>
  );
};

export default ValoresContribuicao;
