// @ts-nocheck
import { MENUS_ALTERNATIVOS } from 'config/menusAlternativos';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { MENU_PERMISSIONS } from './configMenuPermissions';
import { IMenu } from 'main/components/Menu/Sidebar/types';

const iconDocument = 'documentPaper';
const iconCreditCard = 'creditCardVerse';

export const menuPrevidencia: IMenu = {
  name: 'Previdência',
  type: 'previdencia',
  roles: [
    ...PRINCIPAL_USERS,
    USER_PROFILES.ANALISTA_CONSULTA,
    USER_PROFILES.ANALISTA_MANUTENCAO,
    USER_PROFILES.ANALISTA_SAIDA,
    USER_PROFILES.ANALISTA_ENTRADA,
    USER_PROFILES.ANALISTA_PJ,
  ],
  items: [
    ...MENUS_ALTERNATIVOS,
    {
      alt: 'menu-consultas',
      label: 'Consultas',
      icon: 'search',
      path: '',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_PJ,
      ],
      permissions: MENU_PERMISSIONS.CONSULTAS,
      subItems: [
        {
          label: 'Beneficiários',
          path: '/cliente/produtos/previdencia/beneficiarios',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.BENEFICIARIOS_CADASTRADOS_EDITAR],
        },
        {
          label: 'Dados do Plano/Certificado',
          path: '/cliente/produtos/previdencia/dados-plano',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.INFORMACOES_DO_PLANO],
        },
        {
          label: 'Dados do Participante',
          path: '/cliente/produtos/previdencia/dados-participante',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [
            PREV_PERMISSIONS.ENDERECO_DE_CORRESPONDENCIA__CONSULTA,
            PREV_PERMISSIONS.ENDERECO_DE_CORRESPONDENCIA__EDITAR,
          ],
        },
        {
          label: 'Contribuições',
          path: '/cliente/produtos/previdencia/contribuicoes',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [
            PREV_PERMISSIONS.CONTRIBUICAO_ADICIONAL,
            PREV_PERMISSIONS.CONTRIBUICOES_EM_ABERTO,
            PREV_PERMISSIONS.CONTRIBUICOES_PAGAS,
          ],
        },
        {
          label: 'Histórico de Solicitações',
          path: '/cliente/produtos/previdencia/historico-solicitacoes',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.HISTORICO_DE_SOLICITACOES],
        },
        {
          label: 'Reajuste Anual do Plano',
          path: '/cliente/produtos/previdencia/reajuste-anual-do-plano',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.REAJUSTE_E_REENQUADRAMENTO],
        },
        {
          label: 'Simulação de Renda',
          path: '/cliente/produtos/previdencia/simulacao-renda',
          roles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
          permissions: [PREV_PERMISSIONS.SIMULADOR_DE_RENDA],
        },
      ],
    },
    {
      alt: 'menu-alteracoes',
      label: 'Alterações',
      icon: 'edit',
      path: '',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_PJ,
      ],
      permissions: MENU_PERMISSIONS.ALTERACOES,
      subItems: [
        {
          label: 'Dia de Vencimento',
          path: '/cliente/produtos/previdencia/alteracao-dia-vencimento',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.DIA_DE_VENCIMENTO_DO_PLANO],
        },
        {
          label: 'Forma de Pagamento',
          path: '/cliente/produtos/previdencia/alterar-forma-dados-pagamento',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.ALTERAR_DADOS_DE_PAGAMENTO],
        },
        {
          label: 'Prazo Diferimento',
          path: '/cliente/produtos/previdencia/alteracao-prazo-diferimento',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.PRAZO_DE_TERMINO_DO_PLANO],
        },
        {
          label: 'Valor de Contribuição',
          path: '/cliente/produtos/previdencia/alterar-valor-contribuicao',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.ALTERAR_VALOR_DA_CONTRIBUICAO],
        },

        {
          label: 'Alterar Regime Tributário',
          path: '/cliente/produtos/previdencia/alteracao-regime-tributario',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.ALTERAR_REGIME_TRIBUTARIO],
        },
      ],
    },
    {
      alt: 'menu-emissoes',
      label: 'Emissões',
      icon: iconDocument,
      path: '',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_PJ,
      ],
      permissions: MENU_PERMISSIONS.EMISSOES,
      subItems: [
        {
          label: 'Extrato detalhado',
          path: '/cliente/produtos/previdencia/extrato',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.EXTRATO_DETALHADO],
        },
        {
          label: 'Extrato de Rentabilidade e Cotas',
          path: '/cliente/produtos/previdencia/extrato-rentabilidade-cotas',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.RENTABILIDADE_E_COTAS],
        },
        {
          label: 'Declaração de Quitação Anual',
          path: '/cliente/produtos/previdencia/declaracao-quitacao-anual',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.QUITACAO_ANUAL],
        },
        {
          label: 'Imposto de Renda',
          path: '',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [
            PREV_PERMISSIONS.INFORMACOES_DOS_BENEFICIARIOS_CONSULTA,
          ],
          subItems: [
            {
              label: 'Guia de Preenchimento do IR',
              path: '/cliente/produtos/previdencia/guia-preenchimento',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.ANALISTA_CONSULTA,
                USER_PROFILES.ANALISTA_MANUTENCAO,
                USER_PROFILES.ANALISTA_SAIDA,
                USER_PROFILES.ANALISTA_ENTRADA,
                USER_PROFILES.ANALISTA_PJ,
              ],
            },
            {
              label: 'Informe de Rendimentos',
              path: '/cliente/produtos/previdencia/servicos-ir',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.ANALISTA_CONSULTA,
                USER_PROFILES.ANALISTA_MANUTENCAO,
                USER_PROFILES.ANALISTA_SAIDA,
                USER_PROFILES.ANALISTA_ENTRADA,
                USER_PROFILES.ANALISTA_PJ,
              ],
              permissions: [PREV_PERMISSIONS.IMPOSTO_DE_RENDA],
            },
            {
              label: 'Informe de Rendimento(s) de Beneficiário(s)',
              path: '/cliente/produtos/previdencia/informes-beneficiarios',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.ANALISTA_CONSULTA,
                USER_PROFILES.ANALISTA_MANUTENCAO,
                USER_PROFILES.ANALISTA_SAIDA,
                USER_PROFILES.ANALISTA_ENTRADA,
                USER_PROFILES.ANALISTA_PJ,
              ],
              permissions: [
                PREV_PERMISSIONS.INFORMACOES_DOS_BENEFICIARIOS_CONSULTA,
              ],
            },
          ],
        },
        {
          label: 'Emissão de Certificado',
          path: '/cliente/produtos/previdencia/emissao-certificado',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.EMITIR_CERTIFICADO],
        },
      ],
    },
    {
      alt: 'menu-servicos',
      label: 'Serviços',
      icon: iconCreditCard,
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_PJ,
      ],
      permissions: MENU_PERMISSIONS.SERVICOS,
      subItems: [
        {
          label: 'Aporte Adicional',
          path: '/cliente/produtos/previdencia/aporte',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.CONTRIBUICAO_ADICIONAL],
        },
        {
          label: 'Ativação/Suspensão de Contribuição',
          path: '/cliente/produtos/previdencia/ativacao-suspensao-contribuicoes',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.ATIVACAO_E_SUSPENSAO_DE_CONTRIBUICOES],
        },
        {
          label: 'Resgate',
          path: '',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
          ],
          permissions: [PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE],
          subItems: [
            {
              label: 'Solicitação de Resgate',
              path: '/cliente/produtos/previdencia/solicitacao-resgate',
              roles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
              permissions: [PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE],
            },
            {
              label: 'Histórico e Cancelamento de Resgate',
              path: '/cliente/produtos/previdencia/cancelamento-solicitacao-resgate',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.ANALISTA_CONSULTA,
                USER_PROFILES.ANALISTA_SAIDA,
                USER_PROFILES.ANALISTA_ENTRADA,
              ],
              permissions: [
                PREV_PERMISSIONS.HISTORICO_E_CANCELAMENTO_DE_RESGATE,
              ],
            },
          ],
        },
        {
          label: 'Transferência Multifundos PJ',
          path: '/cliente/produtos/previdencia/transferencia-multifundos',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.TRANSFERENCIA_REST],
        },
        {
          label: 'Incluir Benefício de Proteção',
          path: '/cliente/produtos/previdencia/incluir-beneficio-de-protecao',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [PREV_PERMISSIONS.INCLUIR_PROTECAO],
        },
        {
          label: 'Cancelar Certificado',
          path: '/cliente/produtos/previdencia/cancelamento',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [],
        },
        {
          label: 'Transferências',
          path: '',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_PJ,
          ],
          permissions: [
            PREV_PERMISSIONS.TRANSFERENCIA_DE_FUNDOS,
            PREV_PERMISSIONS.TRANSFERENCIA_CERTIFICADO,
          ],
          subItems: [
            {
              label: 'Transferência Entre Fundos',
              path: '/cliente/produtos/previdencia/transferencia-fundo-investimento',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.ANALISTA_MANUTENCAO,
                USER_PROFILES.ANALISTA_CONSULTA,
                USER_PROFILES.ANALISTA_PJ,
              ],
              permissions: [PREV_PERMISSIONS.TRANSFERENCIA_DE_FUNDOS],
            },
            {
              label: 'Transferência Interna com Emissão de Conjugado',
              path: '/cliente/produtos/previdencia/transferencia-interna-emissao-conjugado',
              roles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
              permissions: [PREV_PERMISSIONS.TRANSFERENCIA_DE_FUNDOS],
            },
            {
              label: 'Transferência entre certificados existentes',
              path: '/cliente/produtos/previdencia/transferencia-certificados-existentes',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.ANALISTA_MANUTENCAO,
                USER_PROFILES.ANALISTA_CONSULTA,
              ],
              permissions: [PREV_PERMISSIONS.TRANSFERENCIA_CERTIFICADO],
            },
            {
              label: 'Transferência com alteração de taxa',
              path: '/cliente/produtos/previdencia/transferencia-alteracao-taxa',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.ANALISTA_MANUTENCAO,
                USER_PROFILES.ANALISTA_CONSULTA,
              ],
              permissions: [PREV_PERMISSIONS.TRANSFERENCIA_EMISSAO],
            },
          ],
        },
      ],
    },
  ],
};
