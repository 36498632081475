// @ts-nocheck
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Dropdown } from 'main/components/Dropdown';
import {
  DropdownMenu,
  DropdownItem,
} from 'main/components/Menu/Horizontal/styles';
import { Display, Hidden, Text } from '@cvp/design-system/react';
import Logo from 'assets/img/caixa_vida_previdencia_2d_vertical_negativa.svg';

import { ReactComponent as Avatar } from 'assets/icons/user.svg';
import { ReactComponent as Matricula } from 'assets/icons/document_front.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';

import { useAuth } from 'main/features/Auth/hooks/useAuth';
import * as S from './styles';
import RenderConditional from '../RenderConditional';

const Header: React.FunctionComponent<{
  showUserData?: boolean;
  children?: React.ReactNode;
}> = ({ children, showUserData = true }) => {
  const [hidden, setHidden] = useState(true);
  const history = useHistory();
  const { logout, user, sessionId } = useAuth();

  const handleLogout = () => {
    logout();
    history.push('/login');
  };

  return (
    <>
      <S.HeaderBar>
        <S.Container>
          <Display alignItems="center" data-testid="header-display-container">
            <Link to="/">
              <img
                src={Logo}
                data-testid="logo-cvp"
                alt="Logo Caixa Vida e Previdência"
                width="180"
                style={{ padding: 10 }}
              />
            </Link>
            <Display.Separator />
            <Hidden only={['xs', 'sm']}>
              {!!user && (
                <div>
                  <Dropdown>
                    <S.Button
                      variant="text-white"
                      onClick={() => setHidden(!hidden)}
                    >
                      <RenderConditional condition={showUserData}>
                        <Avatar /> &nbsp;
                        {user?.nomeUsuario}
                      </RenderConditional>
                    </S.Button>

                    <DropdownMenu
                      fullWidth
                      hidden={hidden}
                      toggle={() => setHidden(!hidden)}
                    >
                      <DropdownItem onClick={() => history.push('/perfil')}>
                        Meu perfil
                      </DropdownItem>
                      <DropdownItem onClick={handleLogout}>Sair</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <RenderConditional condition={showUserData}>
                    <S.LoggedUserInfo>
                      <Text color="white" variant="body02-sm">
                        <Matricula /> Matrícula: {user?.nomeAcesso} |{' '}
                        <HomeIcon /> Agência: {user?.agenciaVinculada}
                      </Text>
                      <Text color="primary-dark" variant="body02-sm">
                        Sessão: {sessionId}
                      </Text>
                    </S.LoggedUserInfo>
                  </RenderConditional>
                </div>
              )}
            </Hidden>
          </Display>
        </S.Container>
      </S.HeaderBar>

      {children}
    </>
  );
};

export default Header;
