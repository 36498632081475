import { ITotalDpsFactorieProps } from 'dps/features/pendentesDps/types/ITotalDpsFactorieProps';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';

export function totalDpsFactory({
  visaoAgencia,
  dadosClientesDPS,
  dadosAgenciaDPS,
}: ITotalDpsFactorieProps): string {
  if (visaoAgencia) {
    return tryGetMonetaryValueOrDefault(
      dadosAgenciaDPS?.reduce(
        (acumulado, item) => acumulado + item.valorPremio,
        0,
      ),
      0,
    );
  }
  return tryGetMonetaryValueOrDefault(
    dadosClientesDPS?.reduce(
      (acumulado, item) => acumulado + item.valorContratos,
      0,
    ),
    0,
  );
}
