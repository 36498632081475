// @ts-nocheck
export const COLUNAS_TABELA_ASSISTENCIAS = [
  {
    name: 'Nome Acoplado',
    selector: 'nomeAcoplado',
  },
];

export const SEM_DADOS_ASSISTENCIAS =
  'Não há dados de assistência a serem exibidos.';
