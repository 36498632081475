// @ts-nocheck
import React from 'react';
import { Grid, Display, Text, Card } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { IResponseObterImportanciaSegurada } from 'main/types/IResponseObterImportanciaSegurada';

type CardResultadoProps = {
  dataResult?: IResponseObterImportanciaSegurada | null;
  valorSegurado: number;
  showResult: boolean;
};
export const CardResultado: React.FunctionComponent<CardResultadoProps> = ({
  dataResult,
  valorSegurado,
  showResult,
}) => {
  const sumTotalValue = (valorTotalContratos: number): string => {
    const valorTotal = valorTotalContratos + valorSegurado;
    return formatarValorPadraoBrasileiro(valorTotal.toString());
  };

  return (
    <>
      {showResult && (
        <>
          <Display alignItems="center">
            <Text variant="body-medium3" color="primary" margin>
              Resultado:
            </Text>
          </Display>
          {dataResult && (
            <>
              <Grid>
                <Grid.Item xs={1 / 3}>
                  <Card>
                    <Card.Content>
                      <Text variant="caption-02" color="primary" margin>
                        Acúmulo de IS
                      </Text>
                      <Text variant="body-medium3" color="text-light">
                        {formatarValorPadraoBrasileiro(
                          dataResult.valorTotalContratos.toString(),
                        )}
                      </Text>
                    </Card.Content>
                  </Card>
                </Grid.Item>
                <Grid.Item xs={1 / 3}>
                  <Card>
                    <Card.Content>
                      <Text variant="caption-02" color="primary" margin>
                        Valor total de IS
                      </Text>
                      <Text variant="body-medium3" color="text-light">
                        {sumTotalValue(dataResult.valorTotalContratos)}
                      </Text>
                    </Card.Content>
                  </Card>
                </Grid.Item>
              </Grid>
              <Display alignItems="center">
                <Text variant="body-medium3" color="text-light" margin>
                  *Os valores expostos referem-se à uma simulação e não
                  contemplam propostas recém-contratadas, portanto podem ocorrer
                  variações.
                </Text>
              </Display>
            </>
          )}
        </>
      )}
    </>
  );
};
