// @ts-nocheck
import { TextField } from '@cvp/design-system/react';

import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';

const ColunaValorTransferenciaDistribuicaoEntreFundos = ({
  row,
  selectedTableInputFocus,
  atribuirParametroInputFocus,
  handleValorTransferencia,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunaValorTransferenciaDistribuicaoEntreFundosProps) => {
  const isInvalidoPorValorMinimo = () => {
    const valorMinimo = row?.valorMinimo;
    if (
      checkIfAllItemsAreTrue([
        !!row?.selecionado,
        Number(row?.valorTransferencia) < Number(valorMinimo),
      ])
    ) {
      return {
        error: true,
        touched: false,
        message: `Valor minimo ${tryGetMonetaryValueOrDefault(
          [valorMinimo],
          0,
        )}.`,
      };
    }
    return {
      touched: false,
      error: false,
      message: '',
    };
  };

  let status = isInvalidoPorValorMinimo();
  return (
    <TextField
      onFocus={() => {
        atribuirParametroInputFocus(row.codFundo);
      }}
      autoFocus={row.codFundo === selectedTableInputFocus}
      name={row.codFundo}
      placeholder="R$ 0,00"
      disabled={checkIfSomeItemsAreTrue([!row.selecionado])}
      value={masks.currencyInput.mask(row.valorTransferencia)}
      onChange={({
        target: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        handleValorTransferencia(
          row.codFundo,
          (Number(masks.currencyInput.unmask(value)) / 100).toFixed(2),
        );
        status = isInvalidoPorValorMinimo();
      }}
      error={status.error}
      errorMessage={status.message}
    />
  );
};

export default ColunaValorTransferenciaDistribuicaoEntreFundos;
