// @ts-nocheck
import { Modal, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { Skeleton as TableSkeleton } from 'main/components/Table';
import Icon from 'main/components/Icon';
import TableFilters from 'main/components/Table/TableFilter/TableFilter';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as S from 'main/components/Table/customStyles';
import useConsultarDps from 'contratosPrestamista/features/reenvioDpsEletronica/hooks/useConsultarDps';
import ModalRetornoAcaoDps from 'main/components/ModalRetornoAcaoDps/ModalRetornoAcaoDps';
import FormAtualizacaoDados from 'contratosPrestamista/features/reenvioDpsEletronica/components/FormAtualizacaoDados';
import * as CONSTS from 'contratosPrestamista/features/reenvioDpsEletronica/constants/constants';

const ConsultarDps = () => {
  const {
    serviceListaDps,
    serviceReenviarLinkDps,
    colunas,
    modalAcaoDps,
    setModalAcaoDps,
    formik,
    handleSubmitReenviarLink,
    handleSubmitRetornoAcaoDps,
    modalRetornoAcaoDps,
  } = useConsultarDps();

  return (
    <>
      <RenderConditional condition={serviceListaDps.loadingListaDps}>
        <TableSkeleton colunas={colunas} />
      </RenderConditional>

      <RenderConditional condition={!serviceListaDps.loadingListaDps}>
        <TableFilters
          dataToFilter={tryGetValueOrDefault(
            [serviceListaDps.dadosListaDps],
            [],
          )}
          filterOptions={CONSTS.FILTER_OPTIONS}
          filterTextPartial
        >
          {dataFiltered => (
            <>
              <S.Table
                highlightOnHover
                striped
                responsive
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: 'Items por página',
                  rangeSeparatorText: 'de',
                }}
                columns={colunas}
                data={tryGetValueOrDefault([dataFiltered], [])}
                noDataComponent="Não há dados para exibir."
              />
            </>
          )}
        </TableFilters>
      </RenderConditional>

      <Modal
        show={modalAcaoDps}
        onClose={() => setModalAcaoDps(prevState => !prevState)}
        style={{ minWidth: 700, width: '100%' }}
      >
        <Text margin variant="headline-06">
          <Icon name="warning" />
          Se necessário, realize a atualização dos dados.
        </Text>

        <FormAtualizacaoDados
          formik={formik}
          handleSubmitReenviarLink={handleSubmitReenviarLink}
          serviceReenviarLinkDps={serviceReenviarLinkDps}
        />
      </Modal>

      <ModalRetornoAcaoDps
        openModal={modalRetornoAcaoDps.isOpen}
        message={modalRetornoAcaoDps.message}
        handleClose={handleSubmitRetornoAcaoDps}
      />
    </>
  );
};

export default ConsultarDps;
