import {
  Button,
  Display,
  Grid,
  Select,
  TextField,
} from '@cvp/design-system/react';
import { Formik } from 'formik';
import Icon from 'main/components/Icon';
import {
  IDadosClienteDps,
  IModalAlteracaoDetalhesProps,
} from 'main/components/ModalAlteracaoDetalhes/types/ModalAlteracaoDetalhesProps';
import RenderConditional from 'main/components/RenderConditional';
import { TEXT_MODAL } from 'main/constants/atualizaçãoDados';
import { ISelecItem } from 'main/types/DesignSystem/ISelectItem';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

const ModalAlteracaoDetalhes = <T extends IDadosClienteDps>({
  open,
  onClose,
  handleSubmitForm,
  dadosForm,
  loadingSolicitaDPS,
  initialValues,
}: IModalAlteracaoDetalhesProps<T>) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      if (dadosForm?.cpfCnpj) {
        setIsLoading(false);
      }
    }, 1000);
  }, [dadosForm]);

  return (
    <S.Modal show={open} onClose={onClose}>
      <RenderConditional condition={isLoading}>
        <Display justify="center" alignItems="center" type="display-block">
          <Button variant="text" loading />
        </Display>
      </RenderConditional>
      <RenderConditional condition={!isLoading}>
        <S.Text color="text" margin={10}>
          <Icon name="warning" />
          {TEXT_MODAL.TITULO}
        </S.Text>
        <Formik
          initialValues={tryGetValueOrDefault([dadosForm], initialValues)}
          onSubmit={values => handleSubmitForm(values)}
        >
          {({ handleSubmit, handleBlur, setFieldValue, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('cpfCnpj', masks.numberOnly.mask(value));
                      }}
                      placeholder="CPF/CNPJ"
                      onBlur={handleBlur}
                      margin
                      name="cpfCnpj"
                      value={masks.cpf.mask(values.cpfCnpj)}
                      label="CPF/CNPJ*"
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      label="Nome*"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('nomeCliente', value);
                      }}
                      onBlur={handleBlur}
                      margin
                      placeholder="Nome"
                      name="nomeCliente"
                      value={values.nomeCliente}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <Select
                      placeholder="Escolha a opção"
                      label="Sexo*"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<ISelecItem>) => {
                        setFieldValue('codigoSexo', value);
                      }}
                      onBlur={handleBlur}
                      style={{ textAlign: 'left' }}
                    >
                      <Select.Item
                        text="Feminino"
                        selected={values.codigoSexo === 'F'}
                        value="F"
                      />
                      <Select.Item
                        value="M"
                        selected={values.codigoSexo === 'M'}
                        text="Masculino"
                      />
                    </Select>
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('dataNascimento', masks.date.mask(value));
                      }}
                      label="Data Nascimento*"
                      placeholder="Data Nascimento"
                      onBlur={handleBlur}
                      value={masks.date.mask(values.dataNascimento)}
                      name="dataNascimento"
                    />
                  </Grid.Item>

                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      label="E-mail*"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('emailCliente', value);
                      }}
                      onBlur={handleBlur}
                      placeholder="E-mail"
                      value={values.emailCliente}
                      name="emailCliente"
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      label="Telefone*"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          'numeroTelefoneCliente',
                          masks.numberOnly.mask(value),
                        );
                      }}
                      onBlur={handleBlur}
                      placeholder="Telefone"
                      value={masks.phone.mask(values.numeroTelefoneCliente)}
                      name="numeroTelefoneCliente"
                    />
                  </Grid.Item>
                </Grid>
                <Display>
                  <Button variant="primary" disabled={loadingSolicitaDPS}>
                    Solicitar DPS
                  </Button>
                </Display>
              </form>
            );
          }}
        </Formik>
      </RenderConditional>
    </S.Modal>
  );
};

export default ModalAlteracaoDetalhes;
