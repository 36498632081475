import { MAPEIA_STRING_COM_PARAMETROS_DINAMICOS } from 'main/utils/regex';
import { getPlaceholderVars, isDefined } from './Breadcrumb.utils';
import { getTernaryResult } from 'main/utils/conditional';

const processNameWithParams = (
  routeName: unknown,
  key: string,
  url: string,
  currentFRouteName: unknown,
) => {
  let fRouteName = currentFRouteName;
  if (key.indexOf(':') !== -1) {
    const match = getPlaceholderVars(url, key);
    if (match) {
      if (!isDefined(routeName)) {
        fRouteName = null;
      } else if (routeName instanceof Function) {
        fRouteName = routeName(url, match);
      } else {
        fRouteName = Object.keys(match).reduce(
          (currentName, placeholder) =>
            (currentName as string).replace(
              placeholder,
              match[placeholder] as string,
            ),
          routeName,
        );
      }
    }
  }

  return fRouteName;
};

const processNameEqualUrl = (
  key: string,
  url: string,
  routeName: unknown,
  currentFRouteName: unknown,
) => {
  let fRouteName = currentFRouteName;

  if (key === url) {
    if (!isDefined(routeName)) {
      fRouteName = null;
    } else if (routeName instanceof Function) {
      fRouteName = routeName(url, null);
    } else {
      fRouteName = routeName;
    }
  }
  return fRouteName;
};

const sortedPaths = (routesCollection: { [key: string]: string | unknown }) => {
  return Object.keys(routesCollection).sort((atual, prox) => {
    const aTokenCount = (
      atual.match(MAPEIA_STRING_COM_PARAMETROS_DINAMICOS) ?? []
    ).length;
    const bTokenCount = (
      prox.match(MAPEIA_STRING_COM_PARAMETROS_DINAMICOS) ?? []
    ).length;

    if (aTokenCount === bTokenCount) {
      return getTernaryResult(atual.length > prox.length, 1, -1);
    }
    return getTernaryResult(aTokenCount < bTokenCount, 1, -1);
  });
};

export const breadCrumbItemNameFactory = (
  url: string,
  routesCollection: { [key: string]: string | unknown },
) => {
  let fRouteName: unknown | string | null = '';

  const paths = sortedPaths(routesCollection);

  paths
    .filter(path => !!path)
    .forEach(key => {
      const routeName = routesCollection[key];
      fRouteName = processNameWithParams(routeName, key, url, fRouteName);
      fRouteName = processNameEqualUrl(key, url, routeName, fRouteName);
    });

  return fRouteName;
};
