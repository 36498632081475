// @ts-nocheck
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS_PRESTAMISTA } from 'prestamista/config/endpoints';
import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import {
  IRequestBeneficiarios,
  IResponseBeneficiarios,
} from 'prestamista/types/IBeneficiarios';

const usePecoBeneficiarios = () => {
  const { clientePrestamista } = useContext(AppContext);

  const { response: responseBeneficiarios, loading: loadingBeneficiarios } =
    usePeco<IRequestBeneficiarios, IResponseBeneficiarios[]>({
      api: {
        operationPath:
          PECOS_PRESTAMISTA.ObterBeneficiarioPorContratoPrestamista,
      },
      autoFetch: true,
      handleResponse: { throwToastErrorBFF: false },
      payload: {
        numeroContrato: tryGetValueOrDefault(
          [clientePrestamista?.numeroContrato],
          '',
        ),
      },
    });

  return {
    responseBeneficirios: responseBeneficiarios?.entidade,
    loadingBeneficiarios,
  };
};

export default usePecoBeneficiarios;
