// @ts-nocheck

import { tipoOperacaoContaCaixa } from 'main/constants/tipoOperacaoContaCaixa';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import * as FACTORY from 'previdencia/features/Aporte/factories/aporteFactory';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as RESPONSE_TYPES from 'previdencia/features/Aporte/types/AporteResponse';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import { datasInvalidas } from 'previdencia/features/BeneficioDeProtecao/constants';

export const tipoPagamento = (pagamento: string): string | undefined =>
  ({
    BOLETO: CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.BOLETO_BANCARIO,
    DEBITO: CONSTS.FORMA_PAGAMENTO_APORTE_LABELS.DEBITO_EM_CONTA,
  }[pagamento]);

export const mensagemlAlerta = (tipo: string) => {
  switch (tipo) {
    case CONSTS.enumTipoMensagemAporte.ORIGEM_DOS_RECURSOS:
      return CONSTS.PEP_NAO_DESEJA_INFORMAR_ORIGEM_RECURSOS;
    case CONSTS.enumTipoMensagemAporte.CONTA_BANCARIA_EXISTENTE:
      return CONSTS.INFORMACAO_CONTA_BANCARIA.CONTA_EXISTENTE;
    case CONSTS.enumTipoMensagemAporte.CONTA_BANCARIA_NOVA:
      return CONSTS.INFORMACAO_CONTA_BANCARIA.NOVA_CONTA;
    default:
      return '';
  }
};

export const somarFundosAporteSelecionados = (
  data: APORTE_TYPES.IValorTotalFundosAporte[],
): number => {
  let sum = 0;

  data.forEach(e => {
    if (e.selecionado) {
      sum += e.value;
    }
  });

  return sum;
};

export const metodoPagamento = (formaPagamento: string) => {
  return formaPagamento === APORTE_TYPES.EnumFormaPagamento.DEBITO
    ? APORTE_TYPES.EnumFormaPagamento.CB
    : APORTE_TYPES.EnumFormaPagamento.FC;
};

export const dataPagamentoRegistro = (
  dataPagamento: string,
  tipoPagamentoRegistro: string,
  dataPagamentoBoleto: string | undefined,
) => {
  return tipoPagamentoRegistro !== APORTE_TYPES.EnumFormaPagamento.BOLETO
    ? dataPagamento
    : dataPagamentoBoleto;
};

export function retornarOperacaoContaCaixa(value: string): string {
  const result = tipoOperacaoContaCaixa.find(item => value.startsWith(item.id));

  return tryGetValueOrDefault([result?.valor], '-');
}

export function retornarContaCaixaSemOperacao(numeroConta: string): string {
  const result = tipoOperacaoContaCaixa.find(item =>
    numeroConta.startsWith(item.id),
  );

  const idEncontrado = tryGetValueOrDefault([result?.id], '');
  const contaSemOperacao = numeroConta.substring(idEncontrado.length);

  return tryGetValueOrDefault([contaSemOperacao], '-');
}

export const ehMultiFundo = (qantidade: string) => {
  if (Number(qantidade) !== 0) {
    return true;
  }

  return false;
};

export function setDadosTabela(
  fundoId: string,
  reservaId: string,
  quantidadeMaximaFundos: string,
) {
  return (prevFundos: RESPONSE_TYPES.IDadosFundosAporte[]) =>
    prevFundos?.map((fundo: RESPONSE_TYPES.IDadosFundosAporte) =>
      FACTORY.modificaSelecionado(
        fundo,
        fundoId,
        reservaId,
        quantidadeMaximaFundos,
      ),
    );
}

export const recuperaTipoConta = (valor: string): number => {
  const tipoContaSidec = 3;
  const tipoContaNsgd = 4;

  if (Number(valor?.length) > 11) {
    return tipoContaNsgd;
  }

  return tipoContaSidec;
};

export const recuperaNumeroConta = (valor: string): string => {
  if (valor?.length > 0)
    return valor?.substring(recuperaTipoConta(valor), valor?.length);
  return '';
};

export const recuperaNumeroOperacao = (valor: string): string => {
  if (valor?.length > 0) return valor?.substring(0, recuperaTipoConta(valor));
  return '';
};

export const retornoItemSelecionado = (
  resultConta: RESPONSE_TYPES.IDadosBancariosAporte[],
  canalId: string,
): RESPONSE_TYPES.IDadosBancariosAporte | undefined => {
  const resultadoConta = resultConta.find(conta => conta.canalId === canalId);

  return tryGetValueOrDefault([resultadoConta], undefined);
};

export function adicionarSubtrair(selecionado: boolean): number {
  return selecionado ? 1 : 0;
}

export const imprimirPaginaCustomizada = (size: string): void => {
  const style = document.createElement('style');
  style.innerHTML = `
    @media print {
      body {
        -webkit-print-color-adjust: exact;
        page-break-inside: avoid;
      }
      @page {
        size: 66% auto;
        margin: 0;
        transform: scale(${size});
        transform-origin: top left;
        left:0;
        toolbar:0;
        scrollbars:0;
        status:0;
      }
    }
  `;

  document.head.appendChild(style);
  window.print();
  style.remove();
};

export const validarVerificacaoCamposAporte = (
  values: {
    primeiroNome: string;
    finalCPF: string;
  },
  verifica: {
    nome: string;
    cpfCnpj: string;
  },
) => {
  const infoPrimeiroNome = verifica.nome.split(' ')[0];

  const infoFinalCPF = verifica.cpfCnpj.slice(-4);

  return {
    primeiroNome: getTernaryResult(
      values.primeiroNome.toLowerCase() !== infoPrimeiroNome.toLowerCase(),
      'Nome informado inválido',
      undefined,
    ),
    finalCPF: getTernaryResult(
      values.finalCPF !== infoFinalCPF,
      'CPF informado inválido',
      undefined,
    ),
  };
};
export const filtrarDatasValidas = (datas: string[] | undefined) => {
  return datas?.filter(item => !datasInvalidas.includes(item));
};
