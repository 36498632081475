import { IPecoParams } from 'main/types/IPecoParams';
import { usePeco } from './usePeco';
import {
  IRequestObterImportanciaSegurada,
  IResponseObterImportanciaSegurada,
} from 'main/types/IResponseObterImportanciaSegurada';

export const ObterValorImportanciaSegurada =
  'PECO_ObterValorImportanciaSegurada';

export function useObterValorImportanciaSegurada(
  params?: Partial<IPecoParams<IRequestObterImportanciaSegurada>>,
) {
  const {
    loading: loadingObterIs,
    fetchData: fetchDataImportanciaSegurada,
    response,
    error,
  } = usePeco<
    IRequestObterImportanciaSegurada,
    IResponseObterImportanciaSegurada
  >({
    ...params,
    api: {
      operationPath: ObterValorImportanciaSegurada,
    },
    handleResponse: {
      throwToastErrorBFF: false,
      throwToastErrorGI: false,
    },
  });

  return {
    fetchDataImportanciaSegurada,
    loadingObterIs,
    error,
    response,
  };
}
