// @ts-nocheck
import React from 'react';
import { Display, Text, Card, Tag, Radio } from '@cvp/design-system/react';

import For from 'main/components/For';
import TagStatusCertificado from 'main/components/TagStatusCertificado/TagStatusCertificado';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as CONSTS from 'previdencia/features/TransferenciaEntreCertificados/constants/texts';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import { filtrarRisco } from '../../utils/TransferenciaEntreCertificados';

const CardCobertura: React.FunctionComponent<
  TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ICardCoberturaProps
> = ({ data, selecionarCertificadoDestinoContexto }) => {
  const { featureData } =
    usePrevidenciaContext<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.ITransferenciaEntreCertificadosContextData>();

  return (
    <Card>
      <Card.Content padding={[2, 4, 0]}>
        <Display alignItems="center">
          <Radio
            name={data?.codConta}
            onChange={(checked: boolean) => {
              selecionarCertificadoDestinoContexto(checked, data);
            }}
            checked={
              data?.codConta ===
              featureData?.certificadoDestinoSelecionado?.certificadoNumero
            }
          />

          <Text variant="body02-md" color="primary">
            <b>{data?.produto?.modalidade} - </b>
            {data?.regimeTributario}
          </Text>
        </Display>

        <Display>
          <TagStatusCertificado
            status={tryGetValueOrDefault([data?.situacao], '')}
          />
        </Display>
      </Card.Content>

      <Card.Content padding={[2, 4, 2]}>
        <Text variant="body01-sm">
          <b>{data?.descricaoProduto}</b>{' '}
        </Text>

        <Text variant="body01-sm" color="primary">
          <b>Certificado: {data?.certificadoNumero}</b>
        </Text>

        <Text variant="caption-02" color="secondary" margin>
          <i>
            {getTernaryResult(
              tryGetValueOrDefault(
                [filtrarRisco(data?.coberturas?.coberturas)],
                [],
              ).length > 0,
              CONSTS.CARD_COBERTURA.PECULIO,
              CONSTS.CARD_COBERTURA.SEM_COBERTURA,
            )}
          </i>
        </Text>

        <Text variant="body01-sm" color="text-light">
          Fundo (s):
        </Text>

        <For each={tryGetValueOrDefault([data?.fundos], [])}>
          {item => (
            <Text key={item.desFundo} variant="body01-sm">
              {item.desFundo}
            </Text>
          )}
        </For>
      </Card.Content>
      <Card.Content padding={[2, 4, 4]}>
        <Display alignItems="center">
          <Text variant="body01-sm">Saldo: </Text>

          <Tag
            variant="secondary"
            value={tryGetMonetaryValueOrDefault(data?.valorTotalBeneficios)}
          />
        </Display>
      </Card.Content>
    </Card>
  );
};

export default CardCobertura;
