// @ts-nocheck
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import {
  Display,
  Grid,
  TextField,
  Radio,
  Button,
  Text,
} from '@cvp/design-system/react';
import { endOfDay } from 'date-fns';
import Calendar from 'main/components/Calendar/Calendar';
import Icon from 'main/components/Icon';
import masks from 'main/utils/masks';
import { useHistory, useLocation } from 'react-router';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import { TooltipWrapper } from 'main/components/Wrappers';
import * as S from 'contratosPrestamista/features/uploadproposta/components/styles';
import UploadPropostasMasks from 'contratosPrestamista/utils/UploadpropostaMasks';
import { isCnpj } from 'main/utils/cpf_cnpj';
import { useContratosPrestamistaContext } from 'contratosPrestamista/contexts/ContratosPrestamistaContext';
import { IDpsEletronicaFeatureDataFluxoPF } from 'contratosPrestamista/features/dpseletronica/types/IDpsEletronicaFeatureDataFluxoPF';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import StepBarWrapper from '../components/StepBarWrapper';
import { defaultFormStateDadosContrato } from '../factories/defaultFormStateDadosContrato';
import { usePecoIdentificacao } from '../hooks/usePecoIdentificacao';
import { SISTEMAS_PRESTAMISTA } from '../../../../main/constants/parametrosDps';
import { IEntradaUploadDocumentoFluxoDps } from 'contratosPrestamista/types/IEntradaUploadDocumentoFluxoDps';
import { UploadPropostaDpsValidationSchema } from 'contratosPrestamista/utils/UploadPropostaDpsValidationSchema';

const Identificacao: React.FC = () => {
  const history = useHistory();
  const routeState = useLocation<{ cpfCnpj?: string }>();
  const defaultFormState = defaultFormStateDadosContrato(
    routeState?.state?.cpfCnpj,
  );
  const { setFeatureData, featureData, clearFeatureData } =
    useContratosPrestamistaContext<IDpsEletronicaFeatureDataFluxoPF>();
  const maxDate = endOfDay(new Date());
  const service = usePecoIdentificacao();

  const definirEtapas = () => {
    setFeatureData({
      ...featureData,
      etapas: ['Identificação', 'Upload/DPS'],
      etapaAtual: 1,
      showStepBar: true,
    });
  };

  const handleFormSubmit = async (values: IEntradaUploadDocumentoFluxoDps) => {
    const valorCapitalSegurado = Number(values.capitalSegurado) / 100;
    const valorAcumulo = await service.calcularAcumulo(
      masks.numberOnly.mask(values.cpfCnpj),
      valorCapitalSegurado,
    );
    setFeatureData({
      ...featureData,
      dadosContrato: {
        ...values,
        capitalSegurado: `${valorCapitalSegurado}`,
        valorCredito: `${Number(values.valorCredito) / 100}`,
        valorAcumulo,
      },
      etapaAtual: tryGetValueOrDefault([featureData?.etapaAtual], 0) + 1,
    });

    if (isCnpj(masks.numberOnly.unmask(values.cpfCnpj))) {
      history.push('/prestamista/dps/pj/upload');
      return;
    }
    history.push('/prestamista/dps/pf/upload');
  };

  const getNumeroContratoComMascara = (value: string, mask: string) => {
    if (UploadPropostasMasks[mask])
      return UploadPropostasMasks[mask].mask(value);
    return value;
  };

  const getTooltipText = (sistemaOrigem: string) => {
    if (
      sistemaOrigem === SISTEMAS_PRESTAMISTA.SIAPI ||
      sistemaOrigem === SISTEMAS_PRESTAMISTA.SIAPX
    ) {
      return 'Ex: 12.3456.789.0000291-xx\n12 - região\n3456 - agência\n789 - código operação\n0000291 - número de contrato\n\nXX - Dígito verificador';
    }

    return undefined;
  };

  useEffect(() => {
    clearFeatureData();
  }, []);

  return (
    <Display type="display-block">
      <Formik
        initialValues={defaultFormState}
        validationSchema={UploadPropostaDpsValidationSchema}
        onSubmit={(values: any) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
        }) => {
          const hasError = (field: string) => {
            return touched[field] && errors[field];
          };
          return (
            <StepBarWrapper>
              <br />
              <Text variant="body-medium3" color="text-light">
                *Atenção: Todos os campos são obrigatórios!
              </Text>
              <br />
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Item xs={1 / 3}>
                    <TextField
                      label="*CPF/CNPJ"
                      name="cpfCnpj"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          'cpfCnpj',
                          masks.numberOnly.unmask(value),
                        );
                        definirEtapas();
                      }}
                      value={maskCpfCnpj(values.cpfCnpj)}
                      onBlur={handleBlur}
                      placeholder=""
                      error={hasError('cpfCnpj')}
                      errorMessage={hasError('cpfCnpj')}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1 / 3}>
                    <S.InputLabel>*Data da concessão do crédito</S.InputLabel>
                    <S.InputValidation>
                      <Calendar
                        id="data-venda"
                        className={errors.dtaVenda && 'error'}
                        placeholder="Data da concessão do crédito"
                        maxDate={maxDate}
                        value={values.dtaVenda}
                        onChange={dateInit => {
                          setFieldValue('dtaVenda', dateInit);
                        }}
                        data-testid="data-venda"
                      />
                    </S.InputValidation>
                    {errors.dtaVenda && (
                      <S.ErrorMessage variant="caption-02" color="error">
                        <Icon name="information" />
                        Campo obrigatório
                      </S.ErrorMessage>
                    )}
                  </Grid.Item>
                </Grid>
                <Grid>
                  <Grid.Item xs={1 / 3}>
                    <S.InputLabel>*Sistema de origem</S.InputLabel>
                    <Display>
                      <Display alignItems="center" style={{ margin: 0 }}>
                        <Radio
                          name="desSistemaOrigem"
                          onClick={() => {
                            setFieldValue('desSistemaOrigem', 'SIFEC');
                          }}
                          value="SIFEC"
                          checked={
                            values.desSistemaOrigem ===
                            SISTEMAS_PRESTAMISTA.SIFEC
                          }
                        />
                        SIFEC
                      </Display>
                      <Display alignItems="center" style={{ margin: 0 }}>
                        <Radio
                          name="desSistemaOrigem"
                          onClick={() => {
                            setFieldValue('desSistemaOrigem', 'SIAPX');
                          }}
                          checked={
                            values.desSistemaOrigem ===
                            SISTEMAS_PRESTAMISTA.SIAPX
                          }
                          value="SIAPX"
                        />
                        SIAPX
                      </Display>
                      <Display alignItems="center" style={{ margin: 0 }}>
                        <Radio
                          name="desSistemaOrigem"
                          onClick={() => {
                            setFieldValue('desSistemaOrigem', 'SIAPI');
                          }}
                          checked={
                            values.desSistemaOrigem ===
                            SISTEMAS_PRESTAMISTA.SIAPI
                          }
                          value="SIAPI"
                        />
                        SIAPI
                      </Display>
                    </Display>
                    {errors.desSistemaOrigem && (
                      <S.ErrorMessage variant="caption-02" color="error">
                        <Icon name="information" />
                        Campo obrigatório
                      </S.ErrorMessage>
                    )}
                  </Grid.Item>
                  <Grid.Item xs={1 / 3}>
                    <TooltipWrapper
                      tooltip={getTooltipText(values.desSistemaOrigem)}
                    >
                      <TextField
                        label="*Número de contrato"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={
                          values.desSistemaOrigem ===
                            SISTEMAS_PRESTAMISTA.SIAPX ||
                          values.desSistemaOrigem === SISTEMAS_PRESTAMISTA.SIAPI
                            ? 'Ex: 00.0000.000.0000000-00'
                            : ''
                        }
                        value={getNumeroContratoComMascara(
                          values.codContrato,
                          values.desSistemaOrigem,
                        )}
                        name="codContrato"
                        error={hasError('codContrato')}
                        errorMessage={hasError('codContrato')}
                      />
                    </TooltipWrapper>
                  </Grid.Item>
                </Grid>
                <Grid>
                  <Grid.Item xs={1 / 3}>
                    <TextField
                      label="*Valor do Crédito"
                      name="valorCredito"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          'valorCredito',
                          masks.currencyInput.unmask(value),
                        );
                      }}
                      value={masks.currencyInput.mask(values.valorCredito)}
                      onBlur={handleBlur}
                      placeholder=""
                      error={hasError('valorCredito')}
                      errorMessage={hasError('valorCredito')}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1 / 3}>
                    <TextField
                      label="*Capital Segurado Prestamista"
                      name="capitalSegurado"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          'capitalSegurado',
                          masks.currencyInput.unmask(value),
                        );
                      }}
                      value={masks.currencyInput.mask(values.capitalSegurado)}
                      onBlur={handleBlur}
                      placeholder=""
                      error={hasError('capitalSegurado')}
                      errorMessage={hasError('capitalSegurado')}
                    />
                  </Grid.Item>
                </Grid>
                <br />
                <Display>
                  <Button
                    loading={service.loadingImportanciaSegurada}
                    type="submit"
                    variant="primary"
                    disabled={Object.keys(errors).length > 0}
                  >
                    Avançar
                  </Button>
                </Display>
              </form>
            </StepBarWrapper>
          );
        }}
      </Formik>
    </Display>
  );
};

export default Identificacao;
