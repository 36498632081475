import { usePeco } from 'main/hooks/usePeco';
import { PECOS_PAINELGESTAO } from 'main/features/HierarquiaAgencia/config/endpoints';
import { IBuscarDPSPayload } from 'dps/features/pendentesDps/types/IBuscarDPSPayload';
import { IBuscarAgenciaDPSResponse } from 'dps/features/pendentesDps/types/IBuscarBuscarAgenciaDPSResponse';

export const useBuscarAgenciaDPS = (codigo: string) => {
  const { fetchData, response, loading } = usePeco<
    IBuscarDPSPayload,
    IBuscarAgenciaDPSResponse
  >({
    api: { operationPath: PECOS_PAINELGESTAO.BuscarAgencia },
    handleResponse: { throwToastErrorBFF: false },
    payload: { codigo },
  });

  return {
    fetchData,
    response: response?.entidade,
    loading,
  };
};
