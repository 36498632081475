// @ts-nocheck
import { Breadcrumb } from '@cvp/design-system/react';
import { useHistory } from 'react-router';

export const BreadcrumbBaseLink = ({
  children,
  to,
}: {
  children: React.ReactNode;
  to: string;
}) => {
  const history = useHistory();
  return (
    <Breadcrumb.Item
      data-testid="breadcrumb-link"
      onClick={() => {
        history.push(to);
      }}
      value={children}
    />
  );
};

export const BreadcrumbActiveLink = ({
  children,
}: {
  children: React.ReactNode;
}) => <Breadcrumb.Item data-testid="breadcrumb-active" value={children} />;
