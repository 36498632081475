import { useState } from 'react';

import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { getTimer15Minutos } from 'main/utils';
import useSegmento from 'painelInadimplencia/hooks/useSegmento';
import usePecoObterPagamento from 'painelInadimplencia/hooks/usePecoObterPagamento';
import usePecoValidaReemissaoBoleto from 'painelInadimplencia/hooks/usePecoValidaReemissaoBoleto';
import usePecoSegundaViaBoleto from 'painelInadimplencia/hooks/usePecoSegundaViaBoleto';
import usePecoObterCobrancaFlexivel from 'painelInadimplencia/hooks/usePecoObterCobrancaFlexivel';
import usePecoObterArquivoBoletoSeguros from 'painelInadimplencia/hooks/usePecoObterArquivoBoletoSeguros';
import * as UTILS from 'painelInadimplencia/utils/PainelInadimplencia';
import * as CONSTS from 'painelInadimplencia/constants/constants';
import { DadosCliente } from 'painelInadimplencia/types/ConsultarInadimplenciaResponse';
import { IPagamentosAdimplenciaResponse } from 'painelInadimplencia/types/PainelInadimplencia';

const useRelatorioAgencia = () => {
  const { validarSegmentoRota } = useSegmento();

  const [openModalDetalhesAgencia, setOpenModalDetalhesAgencia] =
    useState<boolean>(false);
  const [detalhesAdimplenciaAgencia, setDetalhesAdimplenciaAgencia] =
    useState<DadosCliente>();

  const [openModalPagamento, setOpenModalPagamento] = useState<boolean>(false);
  const [listaPagamentosDetalhado, setListaPagamentosDetalhado] =
    useState<IPagamentosAdimplenciaResponse[]>();

  const [openModalValidaEmissaoBoleto, setOpenModalValidaEmissaoBoleto] =
    useState<boolean>(false);

  const {
    obterListaPagamentosAdimplencia,
    isLoadingListaPagamentosAdimplencia,
  } = usePecoObterPagamento();

  const { validarReemissaoBoleto, isLoadingValidaReemissaoBoleto } =
    usePecoValidaReemissaoBoleto();

  const { obterSegundaVia, isLoadingSegundaVia } = usePecoSegundaViaBoleto();

  const { obterCobrancaFlexivel, isLoadingCobrancaFlexivel } =
    usePecoObterCobrancaFlexivel();

  const { obterSegundaViaBoletoVida, isLoadingArquivoBoletoSeguros } =
    usePecoObterArquivoBoletoSeguros();

  const handleDetalhamentoParcelas = async (dados: DadosCliente) => {
    const retornoListaPagamentos = await obterListaPagamentosAdimplencia(
      {
        agencia: dados.agencia.toString(),
        segmento: dados.segmento,
        numeroContrato: dados.contrato,
      },
      {
        cache: true,
        cacheKey: `${dados.agencia}-${dados.segmento}-${dados.contrato}`,
        cacheTime: getTimer15Minutos(),
      },
    );

    setDetalhesAdimplenciaAgencia(dados);
    setListaPagamentosDetalhado(
      tryGetValueOrDefault([retornoListaPagamentos?.entidade], []),
    );
  };

  const handleToggleModalDetalhesAdimplencia = (): void => {
    setOpenModalDetalhesAgencia(prevState => !prevState);
  };

  const handleToggleModalPagamento = (): void => {
    setOpenModalPagamento(prevState => !prevState);
  };

  const handleToggleModalValidaEmissaoBoleto = (): void => {
    setOpenModalValidaEmissaoBoleto(prevState => !prevState);
  };

  const gerarBoletoPagamento = async (row: IPagamentosAdimplenciaResponse) => {
    if (validarSegmentoRota(CONSTS.SEGMENTO.PREV)) {
      await UTILS.gerarBoletoPrevidencia({
        row,
        detalhesAdimplenciaAgencia,
        validarReemissaoBoleto,
        obterSegundaVia,
        handleToggleModalValidaEmissaoBoleto,
      });
    }

    if (validarSegmentoRota(CONSTS.SEGMENTO.VIDA)) {
      await UTILS.gerarBoletoVida({
        row,
        obterCobrancaFlexivel,
        obterSegundaViaBoletoVida,
        handleToggleModalValidaEmissaoBoleto,
      });
    }
  };

  const isLoadingGeracaoBoleto = checkIfSomeItemsAreTrue([
    isLoadingValidaReemissaoBoleto,
    isLoadingSegundaVia,
    isLoadingCobrancaFlexivel,
    isLoadingArquivoBoletoSeguros,
  ]);

  const colunasDetalhesAgencia = CONSTS.buildColumnsAgencia(
    handleDetalhamentoParcelas,
    isLoadingListaPagamentosAdimplencia,
    handleToggleModalPagamento,
    handleToggleModalDetalhesAdimplencia,
  );

  const colunasDetalhesPagamentos = CONSTS.colunasDetalhamentoParcelas(
    gerarBoletoPagamento,
    isLoadingGeracaoBoleto,
  );

  return {
    listaPagamentosDetalhado,
    detalhesAdimplenciaAgencia,
    colunasDetalhesAgencia,
    colunasDetalhesPagamentos,
    openModalDetalhesAgencia,
    openModalPagamento,
    openModalValidaEmissaoBoleto,
    isLoadingListaPagamentosAdimplencia,
    handleToggleModalDetalhesAdimplencia,
    handleToggleModalPagamento,
    handleToggleModalValidaEmissaoBoleto,
  };
};

export default useRelatorioAgencia;
