// @ts-nocheck
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { IMenuItem } from 'main/components/Menu/Sidebar/types';

export const MENUS_ALTERNATIVOS: IMenuItem[] = [
  {
    alt: 'menu-home',
    label: 'Home',
    icon: 'home',
    path: '/',
    roles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_PJ,
    ],
    fixedColor: '#005FC8',
  },
  {
    alt: 'menu-produtos',
    label: 'Demais planos',
    icon: 'floatMenu',
    path: '/cliente/produtos',
    roles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_PJ,
    ],
    fixedColor: '#C27300',
  },
];
