// @ts-nocheck
import { formatarData, formatarDataAdicionandoSeparador } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  IConsultarSubscricaoResponse,
  ISubscricaoPessoaFisica,
} from '../../main/types/ConsultarSubcricao';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import masks from 'main/utils/masks';

export const obterObjetoSubscricaoPessoaFisica = (
  reponse?: IHandleReponseResult<IConsultarSubscricaoResponse>,
) => {
  if (reponse?.entidade) {
    const [dadosPessoaResult] = reponse.entidade.subscricao;
    if (dadosPessoaResult) {
      const [endereco, bairro, cidade, uf] = tryGetValueOrDefault(
        [dadosPessoaResult.enderecoResidencial],
        '',
      ).split('|');
      return {
        ...dadosPessoaResult,
        telefone: masks.numberOnly.mask(
          `${dadosPessoaResult.ddd}${dadosPessoaResult.telefone}`,
        ),
        dataNascimento: formatarData(
          new Date(
            formatarDataAdicionandoSeparador(dadosPessoaResult.dataNascimento),
          ),
        ),
        endereco,
        bairro,
        cidade,
        uf,
      } as ISubscricaoPessoaFisica;
    }
  }

  return {} as ISubscricaoPessoaFisica;
};
