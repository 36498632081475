import { Button, Tooltip } from '@cvp/design-system/react';
import { formatarData } from 'main/utils';
import { calculaTempoPassado } from 'main/utils/calculaTempoPassado';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { SUPORTE_COMERCIAL } from 'dps/features/pendentesDps/constants/constants';
import { IAgenciaDPS } from 'dps/features/pendentesDps/types/IBuscarBuscarAgenciaDPSResponse';
import { IDataTableColumn } from 'react-data-table-component';

export const colunasDPSAgenciaFactory = (
  handleAbriModalAlteracaoDetalhes: (item: IAgenciaDPS) => void,
): IDataTableColumn<IAgenciaDPS>[] => {
  return [
    {
      selector: 'cpfCnpj',
      name: 'CPF / CNPJ',
      minWidth: '150px',
      sortable: true,
      cell: (row: IAgenciaDPS) => formatarCpf(row.cpfCnpj).padStart(11, '0'),
    },
    {
      selector: 'proponente',
      name: 'Proponente',
      minWidth: '250px',
      cell: (row: IAgenciaDPS) => row.proponente,
    },
    {
      selector: 'valorPremio',
      name: 'Valor do Prêmio',
      cell: (row: IAgenciaDPS) =>
        tryGetMonetaryValueOrDefault(row.valorPremio, 0),
      minWidth: '100px',
      center: true,
      sortable: true,
    },
    {
      selector: 'status',
      name: 'Status DPS',
      cell: (row: IAgenciaDPS) => row.status,
      center: true,
    },
    {
      selector: 'prazoLimite',
      name: 'Prazo limite para envio da DPS',
      cell: (row: IAgenciaDPS) => formatarData(row.prazoLimite),
      minWidth: '150px',
      sortable: true,
      center: true,
    },
    {
      name: 'Ação',
      cell: (row: IAgenciaDPS) => {
        const contagemDiasPosVenda = calculaTempoPassado(
          Date.now(),
          new Date(row.dataVenda).getTime(),
        );

        if (contagemDiasPosVenda >= 7)
          return (
            <Tooltip text={SUPORTE_COMERCIAL} position="top" variant="gray">
              <Button variant="outlined">Suporte</Button>
            </Tooltip>
          );

        return (
          <Button onClick={() => handleAbriModalAlteracaoDetalhes(row)}>
            Reenviar Link
          </Button>
        );
      },
    },
  ];
};
