import { Display, Text, Modal, Button } from '@cvp/design-system/react';

import { IModalValidaEmissaoBoletoProps } from 'painelInadimplencia/types/PainelInadimplencia';

const ModalValidaEmissaoBoleto = ({
  open,
  onClose,
}: IModalValidaEmissaoBoletoProps) => {
  return (
    <Modal show={open} onClose={onClose}>
      <Text variant="body02-sm" margin>
        Não é possível reemitir o boleto.
      </Text>
      <Display justify="center">
        <Button variant="secondary" onClick={onClose}>
          Certo, entendi!
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalValidaEmissaoBoleto;
