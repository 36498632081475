import { usePeco } from 'main/hooks/usePeco';
import { PECOS_ADIMPLENCIA } from 'painelInadimplencia/config/endpoints';
import {
  IObterCobrancaFlexivelResponse,
  IObterCobrancaFlexivelPayload,
} from 'painelInadimplencia/types/PainelInadimplencia';

const usePecoObterCobrancaFlexivel = () => {
  const {
    loading: isLoadingCobrancaFlexivel,
    response: dadosCobrancaFlexivel,
    fetchData: obterCobrancaFlexivel,
  } = usePeco<
    Partial<IObterCobrancaFlexivelPayload>,
    IObterCobrancaFlexivelResponse
  >({
    api: { operationPath: PECOS_ADIMPLENCIA.ObterCobrancaFlexivel },
    handleResponse: { throwToastErrorBFF: false },
  });

  return {
    dadosCobrancaFlexivel: dadosCobrancaFlexivel?.entidade,
    isLoadingCobrancaFlexivel,
    obterCobrancaFlexivel,
  };
};

export default usePecoObterCobrancaFlexivel;
