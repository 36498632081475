import { Button, Display, Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import SkeletonLoading from 'main/components/SkeletonLoading';
import Table from 'main/components/Table';
import { AppContext } from 'main/contexts/AppContext';
import ReajusteIndisponivel from 'previdencia/features/ReajusteAnualPlano/components/ReajusteIndisponivel';
import useObterAnosDetalhes from 'previdencia/features/ReajusteAnualPlano/hooks/useObterAnosDetalhes';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import React, { useContext } from 'react';
import { AnosDisponiveisDetalhesColumn } from '../../types/ResponseAnosDisponiveisDetalhes';

const HistoricoAtualizacao: React.FC = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const { data, isLoading } = useObterAnosDetalhes(cpfCnpj, numCertificado);

  const { navigateTo } = usePrevNavigation();

  const popularTabelaDetalhes = () => {
    return data?.dados.map(item => ({
      ...item,
      detalhes: (
        <Button
          variant="text-black"
          onClick={() => {
            navigateTo('informacao-reajuste-anual-do-plano', {
              anoSelecionado: item.numAno,
            });
          }}
        >
          Detalhes
          <Icon name="arrowRight" />
        </Button>
      ),
    }));
  };

  if (isLoading) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <Display type="display-block">
      <Text variant="headline-05" color="primary" margin>
        Histórico de Atualizações
      </Text>

      <Text variant="body02-md" color="text" margin>
        Consulte as atualizações feitas a partir de 2019.
      </Text>

      {!data?.dados || data?.dados.length <= 0 ? (
        <ReajusteIndisponivel />
      ) : (
        <>
          <Table
            columns={[
              {
                name: 'Ano',
                selector: (row: AnosDisponiveisDetalhesColumn) => row.numAno,
              },
              {
                name: 'Detalhes',
                selector: (row: AnosDisponiveisDetalhesColumn) => row.detalhes,
              },
            ]}
            responsive
            noDataComponent="Não há dados para exibir."
            data={popularTabelaDetalhes() ?? []}
          />
        </>
      )}
    </Display>
  );
};

export default HistoricoAtualizacao;
