import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ROTA_INICIAL } from 'dps/features/pendentesDps/constants/constants';
import { InitialStateModalRetornoAcaoDps } from 'main/components/ModalRetornoAcaoDps/types/InitialStateModalRetornoAcaoDps';
import { INITIAL_STATE_MODAL_RETORNO_ACAO_DPS } from 'main/constants/dps';
import { useAuth } from 'main/features/Auth/hooks';
import { usePecoRecuperarHierarquiaAgencia } from 'main/features/HierarquiaAgencia/hooks/usePecoRecuperarHierarquiaAgencia';
import { useSolicitaDps } from 'main/hooks/useSolicitaDpsEletronica';
import { toastError, toastSuccess } from 'main/hooks/useToast';
import useURLQueryParam from 'main/hooks/useURLQueryParam';
import { Mensagem } from 'main/services/IApiResponse';
import { parseStringDateToISO } from 'main/utils';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { verificarVisaoAgencia } from 'main/utils/verificarVisaoAgencia';
import { IAgenciaDPS } from '../types/IBuscarBuscarAgenciaDPSResponse';
import { useBuscarAgenciaDPS } from './useBuscarAgenciaDPS';
import { useBuscarClientesDPS } from './useBuscarClientesDPS';

export const usePendentesDPS = () => {
  const { getParam } = useURLQueryParam();

  const { user } = useAuth();

  const history = useHistory();

  const [modalAlteracaoDetalhesOpen, setModalAlteracaoDetalhesOpen] =
    useState<boolean>(false);

  const [itemSelecionado, setItemSelecionado] = useState<IAgenciaDPS>();

  const [modalRetornoAcaoDps, setModalRetornoAcaoDps] =
    useState<InitialStateModalRetornoAcaoDps>(
      INITIAL_STATE_MODAL_RETORNO_ACAO_DPS,
    );

  const unidade = String(
    tryGetValueOrDefault([getParam('unidade')], user.agenciaVinculada),
  );

  const {
    response: responseBuscarClientesDPS,
    loading: loadingBuscarClientesDPS,
    fetchData: fetchBuscarClientesDPS,
  } = useBuscarClientesDPS(unidade);

  const {
    response: responseBuscarAgenciaDPS,
    loading: loadingBuscarAgenciaDPS,
    fetchData: fetchBuscarAgenciaDPS,
  } = useBuscarAgenciaDPS(unidade);

  const {
    response: responseHierarquiaAgencia,
    loading: loadingHierarquiaAgencia,
    fetchData: fetchRecuperarHierarquiaAgencia,
  } = usePecoRecuperarHierarquiaAgencia(unidade);

  const {
    response: responseSolicitaDPS,
    loading: loadingSolicitaDPS,
    fetchData: fetchDataSolicitaDPS,
  } = useSolicitaDps();

  const handleChangeRotaUnidade = (codigo: string | number | undefined) => {
    history.push(`${ROTA_INICIAL}?unidade=${codigo}`);
  };

  const handleBackStep = () => history.goBack();

  const handleAbriModalAlteracaoDetalhes = (item: IAgenciaDPS) => {
    setModalAlteracaoDetalhesOpen(true);
    setItemSelecionado(item);
  };

  const handleEnviarFormulario = async (itemAtualizado: IAgenciaDPS) => {
    setItemSelecionado(itemAtualizado);
    if (itemSelecionado?.numeroProposta) {
      const retornoSolcicitarDps = await fetchDataSolicitaDPS({
        nome: tryGetValueOrDefault([itemAtualizado?.nomeCliente], ''),
        numeroProposta: tryGetValueOrDefault(
          [itemAtualizado?.numeroProposta.toString()],
          '',
        ),
        cpf: tryGetValueOrDefault([itemAtualizado?.cpfCnpj], ''),
        email: tryGetValueOrDefault([itemAtualizado?.emailCliente], ''),
        dataNascimento: tryGetValueOrDefault(
          [parseStringDateToISO(itemAtualizado?.dataNascimento)],
          '',
        ),
        telefone: tryGetValueOrDefault(
          [itemAtualizado?.numeroTelefoneCliente],
          '',
        ),
        sexo: tryGetValueOrDefault([itemAtualizado?.codigoSexo], ''),
      });

      setModalAlteracaoDetalhesOpen(prevState => !prevState);

      setModalRetornoAcaoDps(prevState => ({
        ...prevState,
        isOpen: !prevState.isOpen,
        message: tryGetValueOrDefault(
          [retornoSolcicitarDps?.mensagens?.[0]?.descricao],
          '',
        ),
      }));
    }
  };

  const handleRetornoReenvioDps = (): void => {
    setModalRetornoAcaoDps(INITIAL_STATE_MODAL_RETORNO_ACAO_DPS);
  };

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        !!responseSolicitaDPS?.sucessoBFF,
        !!responseSolicitaDPS?.sucessoGI,
      ])
    ) {
      responseSolicitaDPS?.mensagens?.forEach((mensagem: Mensagem) => {
        toastSuccess(mensagem.descricao);
      });
      fetchBuscarAgenciaDPS(unidade);
    } else {
      responseSolicitaDPS?.mensagens?.forEach((mensagem: Mensagem) => {
        toastError(mensagem.descricao);
      });
    }
    setModalAlteracaoDetalhesOpen(false);
  }, [responseSolicitaDPS]);

  const obterDadosDps = useCallback(async () => {
    const response = await fetchRecuperarHierarquiaAgencia();

    if (verificarVisaoAgencia(response?.entidade?.tipoHierarquia)) {
      await fetchBuscarAgenciaDPS(unidade);
    } else {
      await fetchBuscarClientesDPS(unidade);
    }
  }, [unidade]);

  useEffect(() => {
    obterDadosDps();
  }, [unidade]);

  const desabilitarVoltar = checkIfSomeItemsAreTrue([
    !getParam('unidade'),
    loadingBuscarClientesDPS,
  ]);

  return {
    unidade,
    responseHierarquiaAgencia,
    loadingHierarquiaAgencia,
    responseBuscarClientesDPS,
    responseBuscarAgenciaDPS,
    loadingBuscarClientesDPS,
    loadingBuscarAgenciaDPS,
    loadingSolicitaDPS,
    handleChangeRotaUnidade,
    handleBackStep,
    handleAbriModalAlteracaoDetalhes,
    handleEnviarFormulario,
    handleRetornoReenvioDps,
    setModalAlteracaoDetalhesOpen,
    fetchBuscarAgenciaDPS,
    itemSelecionado,
    modalAlteracaoDetalhesOpen,
    desabilitarVoltar,
    modalRetornoAcaoDps,
  };
};
