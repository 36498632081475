import React, {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
} from 'react';

import { useFeatureData } from 'main/hooks/useFeatureData';
import { IModuleContext } from 'main/types/IModuleContext';

export function createPainelInadimplenciaContext<T = unknown>() {
  return createContext<Partial<IModuleContext<T>> | null>(null);
}

const PainelInadimplenciaContext = createPainelInadimplenciaContext<unknown>();

PainelInadimplenciaContext.displayName =
  'PainelInadimplenciaContextFeatureData';

export function usePainelInadimplenciaContext<T>() {
  const context = useContext(PainelInadimplenciaContext);
  if (!context) {
    throw new Error(
      'usePainelInadimplenciaContext deve ser usado com o PainelInadimplenciaContext',
    );
  }
  return context as IModuleContext<T>;
}

const PainelInadimplenciaContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { clearFeatureData, setFeatureData, featureData } = useFeatureData();

  const contextValue = useMemo(
    () => ({
      featureData,
      setFeatureData,
      clearFeatureData,
    }),
    [featureData, setFeatureData, clearFeatureData],
  );

  return (
    <PainelInadimplenciaContext.Provider value={contextValue}>
      {children}
    </PainelInadimplenciaContext.Provider>
  );
};

export default PainelInadimplenciaContextProvider;
