import {
  checkIfAllItemsAreTrue,
  getTernaryResult,
} from 'main/utils/conditional';
import {
  CONCAT_IDENTIFICA_VALOR_EM_URL,
  MAPEIA_STRING_COM_PARAMETROS_DINAMICOS,
} from 'main/utils/regex';

export const isDefined = (item: unknown): boolean => {
  return getTernaryResult(
    checkIfAllItemsAreTrue([
      item !== undefined,
      item !== null,
      item !== false,
      String(item)?.length > 0,
    ]),
    true,
    false,
  );
};

export const getPlaceholderVars = (url: string, key: string) => {
  const placeholders = key.match(MAPEIA_STRING_COM_PARAMETROS_DINAMICOS);
  if (!placeholders) return null;

  const routeMatcher = new RegExp(
    `^${key.replace(
      MAPEIA_STRING_COM_PARAMETROS_DINAMICOS,
      CONCAT_IDENTIFICA_VALOR_EM_URL,
    )}$`,
  );
  const match = url.match(routeMatcher);
  if (!match) return null;

  return placeholders.reduce((memo, placeholder, index) => {
    const value = match[index + 1] || null;
    return Object.assign(memo, {
      [placeholder]: value,
      [placeholder.substring(1)]: value,
    });
  }, {} as Record<string, string | null>);
};

export const getPaths = (pathname: string, rootName: string | null = null) => {
  let name = '';

  if (isDefined(rootName)) {
    if (typeof rootName === 'string') name = rootName;
    else name = '/';
  }

  const paths = [
    {
      name,
      path: '/',
    },
  ];

  if (pathname === '/') return { paths };

  const formatedPath = pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push({
      name: curr,
      path: currPath,
    });

    return currPath;
  });

  return {
    formatedPath,
    paths,
  };
};
