import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import React from 'react';
import { Link } from 'react-router-dom';
import { IBreadcrumbsItemProps } from './Breadcrumb.types';
import { isDefined } from './Breadcrumb.utils';
import { breadCrumbItemNameFactory } from './BreadcrumbItem.factory';

const BreadcrumbItem: React.FC<IBreadcrumbsItemProps> = ({
  matcher,
  name,
  mappedRoutes,
  parentProps,
}) => {
  const { ActiveLinkComponent, LinkComponent } = parentProps;

  let matchedRouteName: string | null = breadCrumbItemNameFactory(
    matcher.url,
    mappedRoutes,
  ) as string;

  matchedRouteName = getTernaryResult(
    matchedRouteName !== null,
    tryGetValueOrDefault([matchedRouteName], name),
    null,
  );

  if (isDefined(matchedRouteName)) {
    return matcher.isExact ? (
      <ActiveLinkComponent>{matchedRouteName}</ActiveLinkComponent>
    ) : (
      <LinkComponent to={matcher.url}>
        <Link to={tryGetValueOrDefault([matcher.url], '')}>
          {matchedRouteName}
        </Link>
      </LinkComponent>
    );
  }

  return null;
};

export default BreadcrumbItem;
