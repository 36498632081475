// @ts-nocheck
import { useHistory } from 'react-router';
import {
  Grid,
  Accordion,
  Card,
  Text,
  Button,
  Display,
  Skeleton,
} from '@cvp/design-system/react';
import { CardContainerFop } from './styles';
import { formatarData } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useEffect, useState } from 'react';
import { useFop223Context } from '../../features/fops/context/Fop223Context';
import { useSolicitarAberturaProtocoloFop223 } from 'extranet/hooks/useSolicitarAberturaProtocoloFop223';
import RenderConditional from 'main/components/RenderConditional';

const CardProtocoloFop = (): React.ReactElement => {
  const history = useHistory();
  const { payloadProtocolo, salvarProtocolo, protocoloFop223 } =
    useFop223Context();
  const { solicitarCriacaoProtocoloFop223 } =
    useSolicitarAberturaProtocoloFop223();
  const [loading, setLoading] = useState(true);

  const MontaPayloadCriarProtocolo = async () => {
    const formData = new FormData();
    if (payloadProtocolo) {
      formData.append(
        'ComprovanteRenda',
        payloadProtocolo.ComprovanteRenda.get().value[0],
      );
      const cpfSemMascara = payloadProtocolo.cpf.replace(/[^\d]/g, '');
      formData.append('Cpf', cpfSemMascara);
      formData.append('NumeroCertificado', payloadProtocolo.NumeroCertificado);
      formData.append(
        'CodigoCallback',
        String(payloadProtocolo.CodigoCallback),
      );
    }
    const resultProtocolo = await solicitarCriacaoProtocoloFop223(formData);
    if (resultProtocolo) {
      salvarProtocolo(resultProtocolo?.entidade);
      setLoading(false);
    }
  };

  useEffect(() => {
    MontaPayloadCriarProtocolo();
  }, []);
  return (
    <CardContainerFop>
      <Card.Content>
        <Accordion open>
          <Accordion.Item title="Solicitação Transferência com Redução de Taxa - Fop223">
            <RenderConditional condition={!loading}>
              <Grid>
                <Grid.Item xs={1}>
                  <Text variant="headline-08" margin>
                    Protocolo registrado com sucesso
                  </Text>
                  <Text variant="body02-sm">
                    <strong>Protocolo: </strong>

                    {tryGetValueOrDefault(
                      [protocoloFop223?.numeroProtocolo],
                      '-',
                    )}
                  </Text>

                  <Text variant="body02-sm">
                    <strong>Data prevista para atendimento:</strong>{' '}
                    {tryGetValueOrDefault(
                      [
                        formatarData(
                          protocoloFop223?.dataVencimento?.toString(),
                        ),
                      ],
                      '-',
                    )}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Display>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        history.push(
                          `/registro-ocorrencias/consultar-ocorrencia`,
                        )
                      }
                    >
                      Consultar Ocorrências
                    </Button>
                  </Display>
                </Grid.Item>
              </Grid>
            </RenderConditional>
            <RenderConditional condition={loading}>
              <Grid>
                <Grid.Item xs={1}>
                  <Text variant="headline-08" margin>
                    Registrando Protocolo de Transferência
                  </Text>
                </Grid.Item>
              </Grid>
              <Skeleton lines={3} />
            </RenderConditional>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </CardContainerFop>
  );
};

export default CardProtocoloFop;
