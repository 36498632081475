import * as Yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import masks from 'main/utils/masks';

const solicitarValidationSchema = Yup.object().shape({
  cpfCnpj: Yup.string()
    .required('Campo obrigatório')
    .test('cpfCnpj', 'CPF/CNPJ inválido', value => {
      const valueUnmasked = masks.numberOnly.mask(value);
      if (valueUnmasked.length === 11) return cpf.isValid(valueUnmasked);
      if (valueUnmasked.length === 14) return cnpj.isValid(valueUnmasked);
      return false;
    }),
});

export default solicitarValidationSchema;
