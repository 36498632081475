import { Text, Grid, Tag } from '@cvp/design-system/react';
import { IHierarquiaDPSProps } from 'dps/features/pendentesDps/types/IHierarquiaDPSProps';

export const HierarquiaDPS = ({
  tipoVisao,
  codigoUnidade,
}: IHierarquiaDPSProps) => {
  return (
    <Grid>
      <Grid.Item justify="space-between">
        <Text variant="body02-sm" color="primary">
          Tipo Visão : <Tag variant="primary" value={tipoVisao} />
        </Text>
      </Grid.Item>
      <Grid.Item justify="space-between">
        <Text variant="body02-sm" color="primary">
          Unidade : <Tag value={codigoUnidade} variant="secondary" />
        </Text>
      </Grid.Item>
    </Grid>
  );
};
