import { Card, Display, Text, Button } from '@cvp/design-system/react';
import * as S from '../components/styles';
import Icon from 'main/components/Icon';
import useComprovante from '../hooks/useComprovante';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import EmailSenderModal from 'main/components/EmailSenderModal';

export const ComprovanteTransferencia = () => {
  const {
    fetchData,
    loading,
    codigoRequisicao,
    cpfCnpj,
    idRequisicao,
    numeroCertificado,
  } = useComprovante();

  if (loading) return <CardSkeleton />;
  return (
    <Display type="block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content>
          <Text variant="headline-05" color="primary" margin>
            A transferência total foi realizada com sucesso!
          </Text>
          <Text variant="caption">
            Caso necessário, consulte no Histórico de Solicitações.
          </Text>

          <S.ComprovanteSection>
            <div>
              <Text variant="caption">
                <Icon name="documentPaper" /> <strong>Comprovante</strong>
              </Text>
            </div>
            <Display alignItems="center" style={{ marginTop: 20 }}>
              <Button onClick={() => fetchData()} small variant="outlined">
                <Icon name="print" />
                Visualizar ou imprimir
              </Button>
              <EmailSenderModal
                objetoEmail={{
                  tipoEmail: 'ComprovanteTransferencia',
                  parametrosEnvio: {
                    idRequisicao,
                    codigoRequisicao,
                    numeroCertificado,
                    cpfCnpj,
                  },
                }}
                CustomSendButton={props => (
                  <Button small variant="outlined" {...props}>
                    <Icon name="sendEmail" /> Enviar por e-mail
                  </Button>
                )}
              />
            </Display>
          </S.ComprovanteSection>
        </Card.Content>
      </Card>
    </Display>
  );
};
