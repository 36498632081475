// @ts-nocheck
import React, { useEffect } from 'react';
import { Display, Text } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import * as CONSTS from 'previdencia/features/TransferenciaMultifundos/constants/tabelaResumo';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaMultifundosPjFeatureData } from 'previdencia/components/TabelaMultifundosPJ/types/ITransferenciaMultifundosPjFeatureData';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  removerMascaraValorMonetario,
  tryGetMonetaryValueOrDefault,
} from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { EnumEtapas } from 'previdencia/types/Fundo.type';
import * as S from './styles';
import { somaValorReserva } from '../../utils/TransferenciaMultifundos';
import { DataType } from 'previdencia/types/MultifundosPJ';

const TabelaResumo: React.FC = () => {
  const { featureData, setFeatureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();
  const calcularValorRestante = (index: number, tipoReserva: string) => {
    return (
      tryGetValueOrDefault(
        [tryGetValueOrDefault([featureData?.dataResumo], [])[index]?.valor],
        0,
      ) - somaValorReserva(featureData?.fundosDestinoSelecionados, tipoReserva)
    );
  };
  const dataDestino = [
    {
      resumo: 'Resumo',
      restante: 'Restante',
      valor: 'Valor',
    },
    {
      resumo: 'Regular',
      restante: tryGetMonetaryValueOrDefault(
        calcularValorRestante(1, 'regular'),
        0,
      ),
      valor: tryGetMonetaryValueOrDefault(
        somaValorReserva(featureData?.fundosDestinoSelecionados, 'regular'),
        0,
      ),
    },
    {
      resumo: 'Adicional',
      restante: tryGetMonetaryValueOrDefault(
        calcularValorRestante(2, 'adicional'),
        0,
      ),
      valor: tryGetMonetaryValueOrDefault(
        somaValorReserva(featureData?.fundosDestinoSelecionados, 'adicional'),
        0,
      ),
    },
    {
      resumo: 'Migrada',
      restante: tryGetMonetaryValueOrDefault(
        calcularValorRestante(3, 'migrada'),
        0,
      ),
      valor: tryGetMonetaryValueOrDefault(
        somaValorReserva(featureData?.fundosDestinoSelecionados, 'migrada'),
        0,
      ),
    },
  ];
  const total = dataDestino.reduce((accumulator, item) => {
    const soma = accumulator + removerMascaraValorMonetario(item.valor);
    if (Number.isNaN(soma)) {
      return 0;
    }
    return soma;
  }, 0);

  const totalRestante = dataDestino.reduce((accumulator, item) => {
    const soma = accumulator + removerMascaraValorMonetario(item.restante);
    if (Number.isNaN(soma)) {
      return 0;
    }
    return soma;
  }, 0);
  dataDestino.push({
    resumo: 'Total',
    restante: tryGetMonetaryValueOrDefault([totalRestante], 0),
    valor: tryGetMonetaryValueOrDefault([total], 0),
  });

  const colunasDestino = [
    {
      name: 'Resumo',
      selector: (row: DataType) => row.resumo,
      sortable: false,
    },
    {
      name: 'Restante',
      selector: (row: DataType) => row.restante,
      sortable: false,
      cell: ({ restante }: DataType) => (
        <Text variant="body02-sm" color="error">
          {restante}
        </Text>
      ),
    },
    {
      selector: (row: DataType) => row.valor,
      name: 'Valor',
      sortable: false,
    },
  ];
  const dataResumoFormat = featureData?.dataResumo?.map(
    ({ valor, ...rest }) => ({
      valor:
        valor === 'Valor' ? valor : tryGetMonetaryValueOrDefault([valor], 0),
      ...rest,
    }),
  );

  useEffect(() => {
    setFeatureData({
      ...featureData,
      resumoDestino: dataDestino,
    });
  }, [total]);

  return (
    <Display justify="end">
      <RenderConditional
        condition={featureData?.etapa !== EnumEtapas.selecionarFundosDestino}
      >
        <S.Container containerWidth="30%">
          <Table
            title="Resumo"
            columns={CONSTS.colunas}
            noTableHead
            noDataComponent="Não há dados para exibir"
            data={tryGetValueOrDefault([dataResumoFormat], [])}
          />
        </S.Container>
      </RenderConditional>
      <RenderConditional
        condition={featureData?.etapa === EnumEtapas.selecionarFundosDestino}
      >
        <S.Container containerWidth="50%">
          <Table
            title="Resumo"
            columns={colunasDestino}
            noTableHead
            noDataComponent="Não há dados para exibir"
            data={dataDestino}
          />
        </S.Container>
      </RenderConditional>
    </Display>
  );
};

export default TabelaResumo;
