import { Card, Text, Display, Grid, Tag } from '@cvp/design-system/react';

import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import useSegmento from 'painelInadimplencia/hooks/useSegmento';
import * as CONSTS from 'painelInadimplencia/constants/constants';
import { IHeaderPainelProps } from 'painelInadimplencia/types/PainelInadimplencia';

const HeaderPainel = ({
  tipoVisao,
  unidade,
  dadosHierarquiaAgencia,
}: IHeaderPainelProps) => {
  const { validarSegmentoRota } = useSegmento();
  return (
    <Display type="display-block">
      <Card>
        <Card.Content>
          <Display type="flex">
            <Text variant="headline-04" color="primary">
              <RenderConditional
                condition={validarSegmentoRota(CONSTS.SEGMENTO.VIDA)}
              >
                Painel de Inadimplência Vida
              </RenderConditional>

              <RenderConditional
                condition={validarSegmentoRota(CONSTS.SEGMENTO.PREV)}
              >
                Ausência de Contribuição de Previdência
              </RenderConditional>
            </Text>
          </Display>
          <Grid>
            <Grid.Item justify="space-between">
              <Text variant="body02-sm" color="primary">
                Tipo Visão : <Tag value={tipoVisao} />
              </Text>
            </Grid.Item>
            <Grid.Item justify="space-between">
              <Text variant="body02-sm" color="primary">
                Unidade : <Tag value={unidade} variant="secondary" />
                <Icon name="arrowRight" />
                {dadosHierarquiaAgencia?.nomeUnidade}
              </Text>
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default HeaderPainel;
