import { Text } from '@cvp/design-system/react';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { levelRisk } from 'previdencia/utils/riskStatus';
import { Fundo } from 'previdencia/types/Fundo.type';
import { TFundosDestinoConsolidado } from 'previdencia/types/ITransferirEntreFundos';
import ColunaSelecionarFundoTipoTransferencia from 'previdencia/features/TransferenciaEntreCertificados/components/TabelaTipoTransferencia/ColunaSelecionarFundoTipoTransferencia';
import ColunaSelecionarTipoTransferencia from 'previdencia/features/TransferenciaEntreCertificados/components/TabelaTipoTransferencia/ColunaSelecionarTipoTransferencia';
import ColunaValorTransferenciaTipoTransferencia from 'previdencia/features/TransferenciaEntreCertificados/components/TabelaTipoTransferencia/ColunaValorTransferenciaTipoTransferencia';
import ColunaSelecionarFundoDistribuicaoEntreFundos from 'previdencia/features/TransferenciaEntreCertificados/components/TabelaDistribuicaoEntreFundos/ColunaSelecionarFundoDistribuicaoEntreFundos';
import ColunaDescricaoFundoDistribuicaoEntreFundos from 'previdencia/features/TransferenciaEntreCertificados/components/TabelaDistribuicaoEntreFundos/ColunaDescricaoFundoDistribuicaoEntreFundos';
import ColunaValorTransferenciaDistribuicaoEntreFundos from 'previdencia/features/TransferenciaEntreCertificados/components/TabelaDistribuicaoEntreFundos/ColunaValorTransferenciaDistribuicaoEntreFundos';
import * as TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES from 'previdencia/features/TransferenciaEntreCertificados/types/TransferenciaEntreCertificados';
import * as S from 'previdencia/features/TransferenciaEntreCertificados/pages/styles';

export const TIPOS_TRANSFERENCIA = {
  PARCIAL: 'parcial',
  TOTAL: 'total',
};

export const MANTER_BENEFICIO = {
  SIM: true,
  NAO: false,
};

export const COLUNAS_RESUMO_PLANO_ORIGEM = [
  {
    name: 'Cobertura',
    selector: (
      row: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IObterResumoPlanoOrigemSelecionadoReturnFactory,
    ) => row.cobertura,
    wrap: true,
    minWidth: '300px',
  },
  {
    name: 'Valor da Contribuição',
    selector: (
      row: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IObterResumoPlanoOrigemSelecionadoReturnFactory,
    ) => row.valorContribuicao,
    center: true,
    wrap: true,
    minWidth: '180px',
    style: {
      backgroundColor: '#005FC8',
      color: 'white',
    },
  },
  {
    name: 'Valor do Benefício',
    selector: (
      row: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IObterResumoPlanoOrigemSelecionadoReturnFactory,
    ) => row.valorBeneficio,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: 'Status',
    selector: (
      row: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IObterResumoPlanoOrigemSelecionadoReturnFactory,
    ) => row.status,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: 'Saldo',
    selector: (
      row: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IObterResumoPlanoOrigemSelecionadoReturnFactory,
    ) => row.saldo,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
];

export const COLUNAS_TABELA_FUNDOS_TOTAL = ({
  fundos,
  quantidadeMaximaFundos,
  tipoTransferencia,
  handleFundoSelecionado,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunasTabelasFundosTotal): Partial<TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunasTabelasFundosTotalReturn>[] => [
  {
    name: '',
    selector: (row: Fundo) => row.check,
    width: '50px',
    cell: (row: Fundo) => (
      <ColunaSelecionarFundoTipoTransferencia
        row={row}
        fundos={fundos}
        quantidadeMaximaFundos={quantidadeMaximaFundos}
        tipoTransferencia={tipoTransferencia}
        handleFundoSelecionado={handleFundoSelecionado}
      />
    ),
  },
  {
    name: 'Fundos',
    selector: (row: Fundo) => row.descFundo,
    minWidth: '400px',
    wrap: true,
    cell: (row: Fundo) => <Text variant="body02-md">{row.descFundo}</Text>,
  },
  {
    name: 'Saldo Disponível',
    selector: (row: Fundo) => row.saldo,
    minWidth: '150px',
    maxWidth: '250px',
    cell: (row: Fundo) => (
      <Text variant="body02-md">{tryGetMonetaryValueOrDefault(row.saldo)}</Text>
    ),
  },
];

export const COLUNAS_TABELA_FUNDOS_PARCIAL = ({
  COLUNAS_TOTAL,
  selectedTableInputFocus,
  atribuirParametroInputFocus,
  handleValorTransferencia,
  handleTipoTransferenciaFundo,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunasTabelasFundosParcial) => [
  ...COLUNAS_TOTAL,
  {
    name: 'Tipo de Transferência',
    selector: (row: Fundo) => row.tipoTransferencia,
    minWidth: '150px',
    cell: (row: Fundo) => (
      <ColunaSelecionarTipoTransferencia
        row={row}
        handleTipoTransferenciaFundo={handleTipoTransferenciaFundo}
      />
    ),
  },
  {
    name: 'Quanto será transferido?',
    selector: (row: Fundo) => row.valorTransferencia,
    minWidth: '250px',
    cell: (row: Fundo) => (
      <ColunaValorTransferenciaTipoTransferencia
        row={row}
        selectedTableInputFocus={selectedTableInputFocus}
        handleValorTransferencia={handleValorTransferencia}
        atribuirParametroInputFocus={atribuirParametroInputFocus}
      />
    ),
  },
];

export const COLUNAS_DISTRIBUICAO_ENTRE_FUNDOS = ({
  selectedTableInputFocus,
  dadosResumoFundosSelecionados,
  handleFundoSelecionado,
  handleValorTransferencia,
  atribuirParametroInputFocus,
}: TRANSFERENCIA_ENTRE_CERTIFICADOS_TYPES.IColunasDistribuicaoEntreFundos) => [
  {
    name: '',
    selector: (row: TFundosDestinoConsolidado) => row.check,
    width: '20px',
    cell: (row: TFundosDestinoConsolidado) => (
      <ColunaSelecionarFundoDistribuicaoEntreFundos
        row={row}
        dadosResumoFundosSelecionados={dadosResumoFundosSelecionados}
        handleFundoSelecionado={handleFundoSelecionado}
      />
    ),
  },
  {
    name: 'Fundos',
    selector: (row: TFundosDestinoConsolidado) => row.desFundo,
    minWidth: '260px',
    wrap: true,
    cell: (row: TFundosDestinoConsolidado) => (
      <ColunaDescricaoFundoDistribuicaoEntreFundos row={row} />
    ),
  },
  {
    name: 'Perfil de Risco',
    selector: (row: TFundosDestinoConsolidado) => row.desPerfilFundo,
    maxWidth: '150px',
    center: true,
    cell: (row: TFundosDestinoConsolidado) => (
      <S.RiskBarFundos risk={levelRisk(row?.desPerfilFundo)} />
    ),
  },
  {
    name: 'Rentabilidade (Últimos 12 Meses)',
    selector: (row: TFundosDestinoConsolidado) => row.pctRentabUltimoAno,
    maxWidth: '180px',
    center: true,
    cell: (row: TFundosDestinoConsolidado) => (
      <Text variant="body01-sm">{row.pctRentabUltimoAno}%</Text>
    ),
  },
  {
    name: 'Saldo de Reserva (Destino)',
    selector: (row: TFundosDestinoConsolidado) => row.vlrSaldo,
    maxWidth: '180px',
    center: true,
    cell: (row: TFundosDestinoConsolidado) =>
      tryGetMonetaryValueOrDefault(row.vlrSaldo),
  },
  {
    name: 'Valor a transferir',
    selector: (row: TFundosDestinoConsolidado) => row.valorTransferencia,
    maxWidth: '180px',
    center: true,
    cell: (row: TFundosDestinoConsolidado) => (
      <ColunaValorTransferenciaDistribuicaoEntreFundos
        row={row}
        selectedTableInputFocus={selectedTableInputFocus}
        atribuirParametroInputFocus={atribuirParametroInputFocus}
        handleValorTransferencia={handleValorTransferencia}
      />
    ),
  },
];

export const ORIGEM_FLUXO = {
  TIPO_TRANSFERENCIA: 'tipoTransferencia',
  DISTRIBUICAO_ENTRE_FUNDOS: 'distribuicaoEntreFundos',
  CONTRIBUICAO_REGULAR: 'contribuicaoRegular',
};

export const COBERTURA_VALIDA = {
  SIM: 'true',
  NAO: 'false',
};

export const ID_TIPO_CERTIFICADO = {
  ORIGEM: 'certificadoOrigem',
  DESTINO: 'certificadoDestino',
};
