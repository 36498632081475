import { useEffect, useRef } from 'react';
import { DropdownContainerMenu } from './styles';

type TDropdownMenuProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
  hidden?: boolean;
  toggle?: VoidFunction;
  className?: string;
};

export default function DropdownMenu({
  children,
  toggle,
  fullWidth,
  className,
  hidden,
}: TDropdownMenuProps) {
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const element = event.target as HTMLElement;

    const clickedInsideDropdown = element.closest('.Dropdown');

    if (!clickedInsideDropdown && toggle && !hidden) {
      toggle();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [hidden]);

  return (
    <DropdownContainerMenu
      $fullWidth={fullWidth}
      className={className}
      $hidden={hidden}
      ref={menuRef}
    >
      {children}
    </DropdownContainerMenu>
  );
}
