import { usePeco } from 'main/hooks/usePeco';
import { PECOS_ADIMPLENCIA } from 'painelInadimplencia/config/endpoints';
import { IValidaReemissaoBoletoPayload } from 'painelInadimplencia/types/PainelInadimplencia';

const usePecoValidaReemissaoBoleto = () => {
  const {
    loading: isLoadingValidaReemissaoBoleto,
    fetchData: validarReemissaoBoleto,
  } = usePeco<Partial<IValidaReemissaoBoletoPayload>, unknown>({
    api: { operationPath: PECOS_ADIMPLENCIA.ValidaReemissaoBoleto },
    handleResponse: { throwToastErrorBFF: false },
  });

  return {
    isLoadingValidaReemissaoBoleto,
    validarReemissaoBoleto,
  };
};

export default usePecoValidaReemissaoBoleto;
