import { useState } from 'react';

import { usePeco } from 'main/hooks/usePeco';
import { download } from 'main/utils/download';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { PECOS_ADIMPLENCIA } from 'painelInadimplencia/config/endpoints';
import {
  ISegundaViaBoletoPayload,
  ISegundaViaBoletoResponse,
} from 'painelInadimplencia/types/PainelInadimplencia';

const usePecoSegundaViaBoleto = () => {
  const [error, setError] = useState<string>();

  const { fetchData, loading: isLoadingSegundaVia } = usePeco<
    ISegundaViaBoletoPayload,
    ISegundaViaBoletoResponse
  >({
    api: { operationPath: PECOS_ADIMPLENCIA.ObterSegundaVia },
  });

  const obterSegundaVia = async (
    numeroCobranca: string,
    Cpf: string,
  ): Promise<void> => {
    const dados = await fetchData({ Cpf, numeroCobranca });

    const errorMsg = dados?.mensagens?.[0].descricao;

    if (!dados?.sucessoGI) {
      setError(errorMsg);
    }
    if (
      checkIfAllItemsAreTrue([
        !!dados,
        !!dados?.sucessoGI,
        !!dados?.entidade?.return,
      ])
    ) {
      return download(dados?.entidade?.return);
    }

    return undefined;
  };

  return {
    isLoadingSegundaVia,
    error,
    obterSegundaVia,
  };
};

export default usePecoSegundaViaBoleto;
