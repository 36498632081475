// @ts-nocheck
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { useContext, useEffect } from 'react';
import { IDadosCertificados } from '../types/TransferenciaConsultar';
import {
  IRequestListarMelhoresTaxas,
  IResponseListarMelhoresTaxas,
} from '../types/IListarMelhoresTaxasPeco';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaAlteracaoTaxaContext } from '../types/ITransferenciaAlteracaoTaxaContext';
import { CODIGO_ERROS_NEGOCIAIS } from '../constants/tratamentoErros';
import { COD_EMPRESA } from 'previdencia/constants/constants';

export const usePecoTransferenciaConsultar = () => {
  const { cliente } = useContext(AppContext);
  usePrevidenciaContext<ITransferenciaAlteracaoTaxaContext>();
  const { loading, fetchData, response } = usePeco<
    { certificado: string; codigoEmpresa: string },
    IDadosCertificados
  >({
    api: { operationPath: PECOS.ObterDadosOrigemTransferenciaVgbl },
    autoFetch: true,
    payload: {
      certificado: cliente?.numCertificado,
      codigoEmpresa: COD_EMPRESA,
    },
    handleResponse: {
      throwToastErrorBFF: false,
      throwToastErrorGI: false,
    },
  });

  const {
    response: responseListarMelhoresTaxas,
    loading: loadingListarMelhoresTaxas,
    fetchData: fetchListarMelhoresTaxas,
  } = usePeco<IRequestListarMelhoresTaxas, IResponseListarMelhoresTaxas>({
    api: { operationPath: PECOS.ListarMelhoresTaxas },
    payload: { codigoCertificado: cliente?.numCertificado },
  });

  const calcularSaldoTotal = (dados?: IDadosCertificados) => {
    let soma = 0;
    if (dados?.fundosVgbl) {
      soma = tryGetValueOrDefault(
        [
          dados?.fundosVgbl
            .map(item => item.valorSaldoFundo)
            .reduce((prev, curr) => prev + curr),
        ],
        0,
      );
    }

    return soma;
  };

  const obterValorContribuicao = (dados?: IDadosCertificados) => {
    return dados?.coberturas?.dadosCoberturas?.find(x => !x.risco)
      ?.valorContribuicao;
  };

  const obterValorPeculio = (dados?: IDadosCertificados) => {
    return dados?.coberturas?.dadosCoberturas?.find(x =>
      x.cobertura?.toUpperCase()?.includes('PEC'),
    )?.valorContribuicao;
  };

  const obterValorPensao = (dados?: IDadosCertificados) => {
    return dados?.coberturas?.dadosCoberturas?.find(x =>
      x.cobertura?.toUpperCase()?.includes('PEN'),
    )?.valorContribuicao;
  };

  const verificarSeCertificadoTemSolicitacaoAndamento = (
    responseTransferenciaConsultar?: IHandleReponseResult<IDadosCertificados>,
  ) => {
    if (responseTransferenciaConsultar) {
      const mensagem = responseTransferenciaConsultar?.mensagens?.[0];
      if (
        checkIfAllItemsAreTrue([
          !responseTransferenciaConsultar.entidade &&
            mensagem?.codigo ===
              CODIGO_ERROS_NEGOCIAIS.ERRO_NEGOCIAL_TRANSFERENCIA_CONSULTAR,
        ])
      )
        return mensagem?.descricao;
    }
    return undefined;
  };

  useEffect(() => {
    if (response?.entidade) {
      fetchListarMelhoresTaxas();
    }
  }, [response]);

  return {
    loading,
    fetchData,
    response: response?.entidade,
    saldoTotal: calcularSaldoTotal(response?.entidade),
    valorContribuicao: obterValorContribuicao(response?.entidade),
    valorPeculio: obterValorPeculio(response?.entidade),
    valorPensao: obterValorPensao(response?.entidade),
    responseListarMelhoresTaxas,
    loadingListarMelhoresTaxas,
    mensagemError: verificarSeCertificadoTemSolicitacaoAndamento(response),
  };
};
