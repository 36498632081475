import { Card, Display, Text } from '@cvp/design-system/react';
import { IRecuperarHierarquiaAgenciaResponse } from 'main/features/HierarquiaAgencia/types/IRecuperarHierarquiaAgenciaResponse';
import { HierarquiaDPS } from 'dps/features/pendentesDps/components/HierarquiaDPS';

export const HeaderPendentesDPS = ({
  tipoHierarquia,
  codigoUnidade,
}: IRecuperarHierarquiaAgenciaResponse) => {
  return (
    <Display type="display-block">
      <Card>
        <Card.Content>
          <Text variant="headline-04" color="primary" margin={10}>
            DPS Pendente
          </Text>
          <HierarquiaDPS
            tipoVisao={tipoHierarquia}
            codigoUnidade={codigoUnidade}
          />
        </Card.Content>
      </Card>
    </Display>
  );
};
