// @ts-nocheck
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { IMenuItem } from 'main/components/Menu/Sidebar/types';

export const menuPosicaoConsolidada: IMenuItem = {
  label: 'Posição Consolidada do Dia',
  alt: 'previdencia-posicao-consolidada',
  path: '/cliente/produtos/previdencia/posicao-consolidada',
  icon: 'chart',
  roles: [
    ...PRINCIPAL_USERS,
    USER_PROFILES.ANALISTA_CONSULTA,
    USER_PROFILES.ANALISTA_MANUTENCAO,
    USER_PROFILES.ANALISTA_PJ,
  ],
  permissions: [PREV_PERMISSIONS.POSICAO_CONSOLIDADA],
};
