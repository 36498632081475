// @ts-nocheck
import { Card, Text, Display, Grid, Tag } from '@cvp/design-system/react';
import { PropsHeader } from 'painelPortabilidade/types/HeaderHierarquia';

const HeaderPainelPortabilidade = ({ tipoVisao, unidade }: PropsHeader) => {
  return (
    <Display type="display-block">
      <Card>
        <Card.Content>
          <Text variant="headline-04" color="primary" margin={10}>
            Portabilidade
          </Text>
          <Grid>
            <Grid.Item justify="space-between">
              <Text variant="body02-sm" color="primary">
                Tipo Visão : <Tag value={tipoVisao} />
              </Text>
            </Grid.Item>
            <Grid.Item justify="space-between">
              <Text variant="body02-sm" color="primary">
                Unidade : <Tag value={unidade} variant="secondary" />
              </Text>
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default HeaderPainelPortabilidade;
